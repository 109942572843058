import React , {useEffect} from 'react'
import CustomFilter from '../../../components/customFilter/CustomFilter'
import { DatePicker } from 'antd';
import {getBusinessAccounts} from '../store/promotionsSlice'
import { useSelector , useDispatch } from 'react-redux'
export default function Filters({ query, setQuery, dispatch }) {
    useEffect(() => {
        dispatch(getBusinessAccounts())
    }, [dispatch])
    const { businessAccounts } = useSelector((state) => state.promotions.promotions)
    const handleDateFromChange = (date, dateString) => {
        dispatch(setQuery({ ...query, dateFrom: dateString }));
    };
    const handleDateToChange = (date, dateString) => {
        dispatch(setQuery({ ...query, dateTo: dateString }));
    };
    const businessAccountsList = [
        { value: '', label: 'All' },
        ...businessAccounts.map(({ id, name }) => ({ value: id, label: name }))
    ]

  

    return (
        <div className='flex justify-center items-center'>
            <div className='flex flex-col font-bold mr-4 ml-4 gap-y-1'>
                <label className='text-sm font-bold'>Expiry Date From:</label>
                <DatePicker  onChange={handleDateFromChange} />
            </div>

            <div className='flex flex-col font-bold mr-4 ml-4 gap-y-1'>
                <label className='text-sm font-bold'>Expiry Date To:</label>
                <DatePicker  onChange={handleDateToChange} />
            </div>
            <CustomFilter
                title='Business Account'
                name='businessAccountId'
                list={businessAccountsList}
                query={query}
                setQuery={setQuery}
            />
        </div>
    )
}
