import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    Upload,
    Button,
    Form,
    Input,
    Select,
    DatePicker
} from 'antd';
import moment from 'moment';
import { CameraOutlined, CheckOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { getCities, getTypes, saveVehicle, updateVehicle, saveVehicleType, setVehicle } from '../store/vehicleSlice';
import toast from 'react-hot-toast';
import CameraCapture from '../../../components/CameraCapture/CameraCapture'



export default function DriverForm({ vehicle, setIsDrawerOpen }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const types = useSelector((state) => state.vehicle.vehicle.types);
    const [imageUrl, setImageUrl] = useState('');
    const [form] = Form.useForm();
    const [isFormValid, setIsFormValid] = useState(false);
    const [vehicleRegistration, setVehicleRegistration] = useState({ frontImgUrl: '', backImgUrl: '' });

    const onFieldsChange = (_, allFields) => {
        const allFilled = allFields.filter(e => e.name[0] !== '_id' && !['vehicleRegistration-backImgUrl' , "vehicleRegistration-frontImgUrl"].includes(e.name.join('-'))).every(field=> field.value);
        setIsFormValid(allFilled);
    };
    useEffect(() => {
        dispatch(getCities())
        dispatch(getTypes())
        if (vehicle) {
            setImageUrl(vehicle.imageUrl)
            setVehicleRegistration(vehicle.vehicleRegistration)
            form.setFieldsValue({ ...vehicle, makeModel: `${vehicle.make}-${vehicle.model}`, vehicleRegistration: { ...vehicle.vehicleRegistration, regExpiryDate: vehicle?.vehicleRegistration?.regExpiryDate ? moment(vehicle?.vehicleRegistration?.regExpiryDate) : null } });
        } else {
            form.setFieldsValue({})
        }
    }, [dispatch, vehicle, form]);
    //regExpiryDate
    const onSaveVehicle = (values) => {
        setIsLoading(true);
        if (!imageUrl) {
            toast.error('Please upload vehicle image')
            setIsLoading(false);
            return;
        }

        const prepareValues = {
            ...values,
            imageUrl: (imageUrl ? imageUrl : ''),
            vehicleRegistration: {
                ...values.vehicleRegistration, regExpiryDate: values?.vehicleRegistration?.regExpiryDate ? values?.vehicleRegistration?.regExpiryDate.toDate() : null,
                frontImgUrl: typeof values.vehicleRegistration.frontImgUrl === 'string' ? values.vehicleRegistration.frontImgUrl : values.vehicleRegistration.frontImgUrl.fileList[0]?.response.fileUrl,
                backImgUrl: typeof values.vehicleRegistration.backImgUrl === 'string' ? values.vehicleRegistration.backImgUrl : values.vehicleRegistration.backImgUrl.fileList[0]?.response.fileUrl,
            },
        };
        if (vehicle) {
            dispatch(updateVehicle({ vehicleId: vehicle._id, data: prepareValues }))
                .then((res) => {
                    if (res.payload.error) {
                        toast.error('Error updating vehicle')
                    } else {
                        toast.success('Vehicle updated successfully')
                        setIsDrawerOpen(false)
                    }
                })
                .then(() => dispatch(setVehicle(null)))
                .then(() => setIsLoading(false))
        } else {
            dispatch(saveVehicle(prepareValues))
                .then((res) => {
                    if (res.payload.error) {
                        if (res?.payload?.error?.response?.data?.message) {
                            toast.error(res.payload.error.response.data.message)
                        } else {

                            toast.error('Error creating vehicle')
                        }
                    } else {
                        toast.success('Vehicle created successfully')
                        setIsDrawerOpen(false)
                    }
                })
                .then(() => dispatch(setVehicle(null)))
                .then(() => setIsLoading(false))

        }


    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const uploadVehicleImage = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/admin/uploadDocument', formData);

            onSuccess(response.data);
            setImageUrl(response.data.fileUrl);
        } catch (error) {
            onError(error);
        }
    };
    const removeImg = () => {
        setImageUrl('');
    }

    const captureImage = async ({ file, name }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('admin/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setVehicleRegistration({ ...vehicleRegistration, [name]: response.data.fileUrl });
        } catch (error) {
            console.log("capture error", error);
        }
    };


    const customRequest = (name) => async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('admin/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            onSuccess(response.data);
            setVehicleRegistration({ ...vehicleRegistration, [name]: response.data.fileUrl })
            // message.success('File uploaded successfully');
        } catch (error) {
            onError(error);
            // App.error('File upload failed');
        }
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    };

    const VehicleInfoForm = () =>

        <Form
            form={form}
            colon={false}
            onFieldsChange={onFieldsChange}
            labelAlign='left'
            className='w-full flex flex-col justify-center gap-y-0  '
            name="Info"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 300,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={onSaveVehicle}
        >
            <div key={1} className=" "  >
                <div className=' flex justify-center'>
                    <Form.Item className=' w-full flex justify-center ' label="" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Upload
                            customRequest={uploadVehicleImage}
                            listType="picture-card"
                            maxCount={1}
                            fileList={imageUrl ? [{ url: imageUrl }] : []}
                            onRemove={removeImg}
                        >
                            {!imageUrl && <button
                                style={{
                                    // border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <CameraOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                </div>
                            </button>}
                        </Upload>
                    </Form.Item>
                </div>

            </div>
            <Form.Item
                label="Make/Model"
                name='vehicleTypeId'
                className='p-0 m-1'
            >
                <Select>
                    {types.map((type) => {
                        return <Select.Option key={type._id} value={type._id}>{`${type.make.en}-${type.model.en}`}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                hidden
                name="_id"
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-1'
                label="Plate Number"
                name="licensePlateNumber"
            >
                <Input
                />
            </Form.Item>
            <Form.Item
                className='p-0 m-1'
                label="Year"
                name="manufacturedate"
            >
                <Input
                />
            </Form.Item>
            <Form.Item
                className='p-0 m-1'
                label="Color"
                name="color"
            >
                <Input
                />
            </Form.Item>

            <Form.Item
                className='p-0 m-1'
                label="Country"
                name="registrationCountry"
            >
                <Input />
            </Form.Item>


            <p className='font-bold	 text-lg mb-8 pt-8	' style={{ borderTop: '1px solid #d9d9d9' }}>Vehicle Registration (Estimara)</p>

            <div className="flex flex-wrap ">
                <div key={0} className="w-1/2 flex  flex-col items-center "  >
                    <div className=' h-32'>

                        {
                            vehicleRegistration.frontImgUrl ?
                                (
                                    <Form.Item name={['vehicleRegistration', 'frontImgUrl']} initialValue={vehicleRegistration.frontImgUrl} >
                                        <div className='pt-0 mt-0 doc-link-cont' >
                                            <a className='pt-0 flex flex-col justify-center items-center' href={vehicleRegistration.frontImgUrl} target="_blank" rel="noopener noreferrer" >
                                                <CheckOutlined className='p-0 mr-2' />
                                                <p>Front Uploaded</p>
                                            </a>
                                        </div>
                                        <div className='flex justify-around mt-2'>
                                            front side
                                            <DeleteOutlined onClick={() => setVehicleRegistration({ ...vehicleRegistration, frontImgUrl: '' })} />
                                        </div>
                                    </Form.Item>

                                ) : (
                                    <div className=' flex flex-col justify-center items-center' >
                                        <Form.Item name={['vehicleRegistration', 'frontImgUrl']} initialValue={vehicleRegistration.frontImgUrl} >
                                            <Upload className='flex flex-col' customRequest={customRequest('frontImgUrl')}  >
                                                <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Front</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item>
                                            <CameraCapture
                                                name='capture front side'
                                                setFunction={captureImage}
                                                attribute='frontImgUrl'
                                            />
                                        </Form.Item>

                                    </div>
                                )}
                    </div>

                </div>
                <div key={1} className="w-1/2 flex  flex-col items-center "  >
                    <div className=' h-32'>

                        {
                            vehicleRegistration.backImgUrl ?
                                (
                                    <Form.Item name={['vehicleRegistration', 'backImgUrl']} initialValue={vehicleRegistration.backImgUrl} >
                                        <div className='pt-0 mt-0 doc-link-cont' >
                                            <a className='pt-0 flex flex-col justify-center items-center' href={vehicleRegistration.backImgUrl} target="_blank" rel="noopener noreferrer" >
                                                <CheckOutlined className='p-0 mr-2' />
                                                <p>Back Uploaded</p>
                                            </a>
                                        </div>
                                        <div className='flex justify-around mt-2'>
                                            back side
                                            <DeleteOutlined onClick={() => setVehicleRegistration({ ...vehicleRegistration, backImgUrl: '' })} />
                                        </div>
                                    </Form.Item>

                                ) : (<div className=' flex flex-col justify-center items-center' >
                                    <Form.Item name={['vehicleRegistration', 'backImgUrl']} initialValue={vehicleRegistration.backImgUrl} >
                                        <Upload className='flex flex-col' customRequest={customRequest('backImgUrl')}  >
                                            <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Back</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <CameraCapture
                                            name='capture back side'
                                            setFunction={captureImage}
                                            attribute='backImgUrl'
                                        />
                                    </Form.Item>
                                </div>
                                )}
                    </div>
                </div>

                <Form.Item className='w-full mt-8' label="Expiry Date" name={['vehicleRegistration', 'regExpiryDate']} initialValue='' >
                    <DatePicker
                        style={{ width: '100%' }}
                        disabledDate={disabledDate}
                    />
                </Form.Item>

            </div>
            <Form.Item className='flex justify-end'>
                <Button type="primary" htmlType="submit" disabled={!isFormValid || !imageUrl || isLoading || !vehicleRegistration.frontImgUrl || !vehicleRegistration.backImgUrl}>
                    {vehicle ? 'Edit Vehicle' : 'Add Vehicle'}
                </Button>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>

        </Form>


    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Vehicle Details</p>

            <div className=' flex w-full flex-col px-8 pt-8'>
                <p className='font-bold	 text-lg mb-8'>Vehicle Informations</p>
                <div className=' w-full flex '>
                    <VehicleInfoForm />
                </div>
            </div>


        </div>
    )
}
