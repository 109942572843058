import * as React from 'react';

import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
// import {  valueFormatter } from '../dataset/weather';



export default function BarcharGraph({dataset , name , yField, xField}) {

  const chartSetting = {
    yAxis: [
      {
        label: null,
      },
    ],
    series: [{ dataKey: yField , color: '#274D91', }],
    height: 250,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };
  
  return (
    <div style={{ width: '100%' }}>
  
      <BarChart
        dataset={dataset}
        borderRadius={12}

        xAxis={[
          { scaleType: 'band', dataKey: xField, tickPlacement:'end', tickLabelPlacement:'middle' },
        ]}
        {...chartSetting}
      />
    </div>
  );
}
