import React, { useState, useEffect, useRef } from 'react';
import locationTick from '../../../assets/locationTick.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { getTrips } from '../store/dashboardSlice';
import moment from 'moment/moment';

const SawaMap = () => {
  const [positionType, setPositionType] = useState('pickUp');
  const { trips } = useSelector(state => state.dashboard.dashboard);
  const googleMapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerInstance = useRef([]);
  const infoWindow = useRef(null);
  const dispatch = useDispatch();
  const [selectedInterval, setSelectedInterval] = useState('Today');

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
    switch (interval) {
      case 'Today':
        dispatch(getTrips({ startDate: new Date().toISOString().split('T')[0], endDate: new Date().toISOString().split('T')[0] }));
        break;
      case 'Next 7 Days':
        dispatch(getTrips({ startDate: new Date().toISOString().split('T')[0], endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] }));
        break;
      case 'Next 30 Days':
        dispatch(getTrips({ startDate: new Date().toISOString().split('T')[0], endDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] }));
        break;
      default:
        break;
    }
  };

  const handleChange = (value) => {
    setPositionType(value);
  };

  useEffect(() => {
    dispatch(getTrips({ startDate: new Date().toISOString().split('T')[0], endDate: new Date().toISOString().split('T')[0] }));
  }, [dispatch]);

  const markerPositions = trips.map(trip => {
    const { coordinates } = positionType === 'pickUp' ? trip.zoneFrom : trip.zoneTo;
    return { coor : {lat: coordinates.latitude, lng: coordinates.longitude} , trip}; 
  });

  useEffect(() => {
    const initializeMap = () => {
      const mapOptions = {
        zoom: 14,
        center: { lat: 25.2854, lng: 51.5310 },
      };
  
      mapInstance.current = new window.google.maps.Map(googleMapRef.current, mapOptions);
      const bounds = new window.google.maps.LatLngBounds();
  
      infoWindow.current = new window.google.maps.InfoWindow();
      let isInfoWindowClicked = false; 

      markerInstance.current = markerPositions.map((position, index) => {
        console.log("position",position.coor);
        const marker = new window.google.maps.Marker({
          position: position.coor,
          map: mapInstance.current,
          icon: {
            url: locationTick,
            scaledSize: new window.google.maps.Size(40, 50),
          },
        });
  
        marker.addListener('mouseover', () => {
          if (!isInfoWindowClicked) {
            const trip = position.trip;
            const contentString = `
              <div>
                <p style="margin-top: 5px;"><strong>Business Account:</strong> ${trip.businessAccount.email}</p>
                <p style="margin-top: 5px;"><strong>Time and Date:</strong> ${moment(trip.startAt).format('DD-MM-YYYY, h:mm a')}</p>
                <p style="margin-top: 5px;"><strong>Vehicle Type:</strong> ${trip.vehicle.vehicleType.make.en}-${trip.vehicle.vehicleType.model.en}</p>
              </div>
            `;
            infoWindow.current.setContent(contentString);
            infoWindow.current.open(mapInstance.current, marker);
          }
        });
  
        marker.addListener('mouseout', () => {
          if (!isInfoWindowClicked) {
            infoWindow.current.close();
          }
        });
  
        marker.addListener('click', () => {
          isInfoWindowClicked = true; // Set the flag to true
          const trip = position.trip;
          const contentString = `
            <div>
              <p style="margin-top: 5px;"><strong>Business Account:</strong> ${trip.businessAccount.email}</p>
              <p style="margin-top: 5px;"><strong>Time and Date:</strong> ${moment(trip.startAt).format('DD-MM-YYYY, h:mm a')}</p>
              <p style="margin-top: 5px;"><strong>Vehicle Type:</strong> ${trip.vehicle.vehicleType.make.en}-${trip.vehicle.vehicleType.model.en}</p>
            </div>
          `;
          infoWindow.current.setContent(contentString);
          infoWindow.current.open(mapInstance.current, marker);
        });
        infoWindow.current.addListener('closeclick', () => {
          isInfoWindowClicked = false;
        });
        mapInstance.current.addListener('click', () => {
          isInfoWindowClicked = false; 
          infoWindow.current.close();
        });
  
  
        bounds.extend(position.coor);
        return marker;
      });
  
      mapInstance.current.fitBounds(bounds);
    };
  
    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDeU5D4QKzPBEy-GP4S9MpeiQ-goAxYjfU';
      script.onload = initializeMap;
      document.body.appendChild(script);
    } else {
      initializeMap();
    }
  
    return () => {
      if (markerInstance.current) {
        markerInstance.current.forEach(marker => marker.setMap(null));
      }
    };
  }, [markerPositions]);

  useEffect(() => {
    if (mapInstance.current) {
      const bounds = new window.google.maps.LatLngBounds();
      markerInstance.current.forEach(marker => marker.setMap(null));
      markerInstance.current = markerPositions.map((position, index) => {
        console.log("position",position.coor);

        const marker = new window.google.maps.Marker({
          position: position.coor,
          map: mapInstance.current,
          icon: {
            url: locationTick,
            scaledSize: new window.google.maps.Size(40, 50),
          },
        });
  
        let isInfoWindowClicked = false; 
  
        marker.addListener('mouseover', () => {
          if (!isInfoWindowClicked) {
            const trip = position.trip;
            const contentString = `
              <div>
                <p style="margin-top: 5px;"><strong>Business Account:</strong> ${trip.businessAccount.email}</p>
                <p style="margin-top: 5px;"><strong>Time and Date:</strong> ${moment(trip.startAt).format('DD-MM-YYYY, h:mm a')}</p>
                <p style="margin-top: 5px;"><strong>Vehicle Type:</strong> ${trip.vehicle.vehicleType.make.en}-${trip.vehicle.vehicleType.model.en}</p>
              </div>
            `;
            infoWindow.current.setContent(contentString);
            infoWindow.current.open(mapInstance.current, marker);
          }
        });
  
        marker.addListener('mouseout', () => {
          if (!isInfoWindowClicked) {
            infoWindow.current.close();
          }
        });
  
        marker.addListener('click', () => {
          isInfoWindowClicked = true; // Set the flag to true
          const trip = position.trip;
          const contentString = `
            <div>
              <p style="margin-top: 5px;"><strong>Business Account:</strong> ${trip.businessAccount.email}</p>
              <p style="margin-top: 5px;"><strong>Time and Date:</strong> ${moment(trip.startAt).format('DD-MM-YYYY, h:mm a')}</p>
              <p style="margin-top: 5px;"><strong>Vehicle Type:</strong> ${trip.vehicle.vehicleType.make.en}-${trip.vehicle.vehicleType.model.en}</p>
            </div>
          `;
          infoWindow.current.setContent(contentString);
          infoWindow.current.open(mapInstance.current, marker);
        });
        infoWindow.current.addListener('closeclick', () => {
          isInfoWindowClicked = false;
        });
        mapInstance.current.addListener('click', () => {
          isInfoWindowClicked = false; // Reset the flag on map click
          infoWindow.current.close();
        });
  
        bounds.extend(position.coor);
        return marker;
      });
      mapInstance.current.fitBounds(bounds);
    }
  }, [markerPositions]);

  const selectOptions = (
    <Select
      defaultValue="pickUp"
      style={{ width: 120 }}
      onChange={handleChange}
      options={[
        { value: 'pickUp', label: 'pickUp' },
        { value: 'dropOff', label: 'dropOff' },
      ]}
    />
  );

  return (
    <>
      <div ref={googleMapRef} className="rounded-xl items-start justify-center h-[776.9px] bg-white" />
      <div className="absolute top-14 right-14 bg-white shadow-lg rounded-lg">
        {selectOptions}
      </div>
      <div style={{ backgroundColor: 'transparent' }} className="absolute bg-transparent map-interval">
        <button
          className={`map-interval-btn ${selectedInterval === 'Today' ? 'btn-active' : ''}`}
          onClick={() => handleIntervalChange('Today')}
        >
          Today
        </button>
        <button
          className={`map-interval-btn ${selectedInterval === 'Next 7 Days' ? 'btn-active' : ''}`}
          onClick={() => handleIntervalChange('Next 7 Days')}
        >
          Next 7 Days
        </button>
        <button
          className={`map-interval-btn ${selectedInterval === 'Next 30 Days' ? 'btn-active' : ''}`}
          onClick={() => handleIntervalChange('Next 30 Days')}
        >
          Next month
        </button>
      </div>
    </>
  );
};

export default SawaMap;