import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Tabs,
    Button,
    Form,
    Input,
    Select,
    Table
} from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import './style.css'
import { saveZone, updateZone, setZone , getAllZones} from '../store/zoneSlice';
import { toast } from 'react-hot-toast';
import MapDrawComponent from './MapDrawComponent'


export default function ZoneForm({ zone, setIsDrawerOpen }) {
    const [coordinates, setCoordinates] = React.useState([])
    const {zonesPoints} = useSelector(state => state.zone.zone)
    const [zoneData, setZoneData] = React.useState([])
    const [buttonDisabled, setButtonDisabled] = React.useState(zone ? false : true)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const memoizedMapDrawComponent = useMemo(() => <MapDrawComponent zoneData={zoneData} setZoneData={setZoneData} zonesPoints={zonesPoints} zone={zone} />, [zonesPoints , zone]);
    const [form] = Form.useForm();
    useEffect(() => {
        if (zone) {
            form.setFieldsValue(zone);
            setCoordinates(zone.coordinates)
            setZoneData(zone.polygonPoints)
        } else {
            form.setFieldsValue({})
        }
        dispatch(getAllZones())
    }, [dispatch, form, zone]);

    const onSaveZone = (values) => {
        const data = { ...values, coordinates }
        const { name, number } = data
        if (!(name && name !== "")) {
            toast.error('Name is required')
        }
        if (!(number && number !== "")) {
            toast.error('Number is required')
        }

        setIsLoading(true)
        if (zone) {
            dispatch(updateZone({ data: { ...values, coordinates, polygonPoints: zoneData }, zoneId: zone._id })).then((data) => {
                if (!data.payload?.zone || data?.payload?.error || data?.error) {
                    if (data?.payload?.error?.message) {
                        toast.error(data.payload.error.message);
                        setIsLoading(false)
                        return
                    }
                    toast.error('Creating Zone failed');
                    setIsLoading(false)
                    return;
                }
                toast.success('Zone Updated Successfully');
                setIsLoading(false)
                setIsDrawerOpen(false);
                dispatch(setZone(null))
            })
            return
        }
        dispatch(saveZone({ ...values, coordinates, polygonPoints: zoneData, rank: 13 })).then((data) => {
            if (!data.payload?.zone || data?.payload?.error || data?.error) {
                if (data?.payload?.data?.message) {
                    toast.error(data.payload.data.message);
                    return
                }
                toast.error('Creating Zone failed');
                return;
            }
            toast.success('Zone Created Successfully');
            setIsDrawerOpen(false);
        }).finally(() => {
            setIsLoading(false)

        })


    };


    function onChangeFields(changedFields, allFields) {
        const values = allFields.map(e => e.value)
        const number = allFields.find(e => e.name[0] === 'number').value

        if (values.some(e => e == undefined || e == '')) {
            setButtonDisabled(true)
        } else {

            if (+number) {
                setButtonDisabled(false)
            } else {
                setButtonDisabled(true)
            }
        }
    }

    const PointsTable = () => {
        const dataWithKeys = coordinates.map((coord, index) => ({
            ...coord,
            key: index, // If no unique key, use the index (not ideal for dynamic lists)
        }));
        const columns = [
            {
                title: 'Name En',
                dataIndex: 'nameEn',
                key: 'nameEn',
                render: (text, point) => <div key={point.name.en} className="flex gap-x-2">{point.name.en}</div>,
            },
            {
                title: 'Name Ar',
                dataIndex: 'nameAr',
                key: 'nameAr',
                render: (text, point) => <div key={point.name.ar} className="flex gap-x-2">{point.name.ar}</div>,
            },
            {
                title: 'longitude',
                dataIndex: 'longitude',
                key: 'longitude',
            },
            {
                title: 'latitude',
                dataIndex: 'latitude',
                key: 'latitude',
            },
            {
                title: "Delete",
                dataIndex: "edit",
                sorter: false,
                align: "center",
                width: 10,
                key: "edit",
                render: (_, record) => {
                    return (
                        <>
                            <DeleteOutlined
                                key={record._id}
                                onClick={() => {
                                    setCoordinates(dataWithKeys.filter((item) => item !== record))
                                }}
                                style={{ fontSize: "18px", marginRight: "8px" }}
                            />
                        </>
                    );
                },
            },
        ];

        return <div className='table-container flex '>
            <Table className='w-4/6 points-table' dataSource={dataWithKeys} columns={columns} />
        </div>
    }
    return (
        <div className='flex flex-col drawer-content '>
            <p className='form-subHeader'>Zone Details</p>

            <div>
                {memoizedMapDrawComponent}
                <Form
                    form={form}
                    labelAlign='left'
                    className={`w-full flex flex-col justify-center gap-y-0 px-8 pt-8 `}
                    name="zoneForm"
                    colon={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 300,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    autoComplete="off"
                    onFinish={onSaveZone}
                    onFieldsChange={(changedFields, allFields) => onChangeFields(changedFields, allFields)}
                >
                    <Form.Item
                        label="Name"
                        name='name'
                        className='p-0 m-2'
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        className='p-0 m-2'
                        label="Number"
                        name="number"
                        rules={[

                            {
                                pattern: /^[0-9]+$/,
                                message: 'Only numbers are allowed!',
                            },
                        ]}
                    >
                        <Input
                        />
                    </Form.Item>

                <Form.Item className='flex justify-end'>
                    <Button type="primary" htmlType="submit"
                        disabled={buttonDisabled || zoneData.length < 3}

                    >
                        {zone ? 'Edit Zone' : 'Save Zone'}

                    </Button>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                </Form.Item>
                </Form>
            </div>




        </div>
    )
}
