import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocAlerts, setQuery } from './store/alertsSlice'
import CustomButton from '../../components/CustomButton/CustomButton'
import DocCard from './components/DocCard'
import Loader from '../../components/loader';

import "./style.css";

function ManageAlerts() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const query = useSelector((state) => state.alerts.alerts.query);
  const docs = useSelector((state) => state.alerts.alerts.docs);
  const fetchData = async () => {
    await dispatch(getDocAlerts(query));
  };
  useEffect(() => {
    setIsLoading(true);
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [dispatch, query]);

  const types = [
    { value: "vehicles", label: "Vehicles" },
    { value: "drivers", label: "Drivers" },
    { value: "businesses", label: "Business Accounts" }
  ]
  const status = [
    { value: "", label: "All" },
    { value: "hasExpired", label: "Expired" },
    { value: "expiringSoon", label: "Expiring Soon" }
  ]

  return (
    <div className="bg-layout h-full flex flex-col alerts-main" >
      <div className="flex w-full justify-between items-center">
        <div className="flex justify-between items-center filter-container">
          {types.map((type) => {
            return (
              <CustomButton
                btnType="extra"
                // icon="filter"
                btnText={type.label}
                className={`!h-9 ml-4 max-w-24 ${type.value === query.type ? 'active-btn' : ''}`}
                handleSubmit={e => {
                  dispatch(setQuery({ ...query, type: type.value }))

                }}
              />
            )

          })}
        </div>
        <div className="flex justify-between items-center filter-container">
          {status.map((type) => {
            return (
              <CustomButton
                btnType="extra"
                // icon="filter"
                btnText={type.label}
                className={`!h-9 ml-4 max-w-24 ${type.value === query.status ? 'active-btn' : ''}`}
                handleSubmit={e => {
                  dispatch(setQuery({ ...query, status: type.value }))

                }}
              />
            )

          })}
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-wrap justify-start items-start gap-6">
          {docs
            .filter(doc => !doc.document?.documentName?.includes("Back"))
            .map((doc) => {
              return (
                <DocCard doc={doc} type={query.type} />
              )
            })}

        </div>

      )}
    </div>
  );
}
export default ManageAlerts;