import React, { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import CustomButton from '../CustomButton/CustomButton';

function CameraCapture({ name, setFunction, attribute }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    startCamera();
  };

  const closeModal = () => {
    stopCamera(); // Stop camera first
    setIsModalVisible(false);
    setImageData(null); // Reset image when modal is closed
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const takePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL('image/png');
      const base64Data = dataUrl.split(',')[1];
      const contentType = dataUrl.split(',')[0].match(/:(.*?);/)[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const imageFile = new Blob([byteArray], { type: contentType });
      stopCamera(); 
      setImageData(dataUrl);
      setImageFile(imageFile);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop(); // Stop each track
      });
      videoRef.current.srcObject = null; // Clear the video source object
    }
  };

  return (
    <div>
      <CustomButton
        btnType="extra"
        btnText={name}
        className='!h-9 ml-4 max-w-24 self-end'
        handleSubmit={showModal}
      />
      <Modal
        title="Camera Capture"
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        closable={false}
        width={500}
      >
        {!imageData ? (
          <>
            <video ref={videoRef} autoPlay style={{ width: '100%' }}></video>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <div className='flex w-full justify-around' style={{ marginTop: '16px' }}>
              <Button onClick={takePicture} style={{ marginRight: '8px' }}>Take Picture</Button>
              <Button onClick={closeModal}>Close</Button>
            </div>
          </>
        ) : (
          <>
            <img src={imageData} alt="Captured" style={{ width: '100%' }} />
            <div className='flex w-full justify-around' style={{ marginTop: '16px' }}>
              <Button onClick={() => {
                setImageData(null);
                startCamera();
              }} style={{ marginRight: '8px' }}>Retake</Button>
              <Button onClick={() => {
                closeModal();
                setFunction({ file: imageFile, name: attribute });
              }} style={{ marginRight: '8px' }}>Upload</Button>
              <Button onClick={closeModal}>Close</Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default CameraCapture;
