import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    Button,
    Form,
    Input,
    Select,
    Upload,
} from 'antd';
import {isEmail} from '../../../utils/functions'
import { CameraOutlined } from '@ant-design/icons';
import { createAdmin ,updateAdmin} from '../store/adminUserSlice';
import { toast } from 'react-hot-toast';





const { Option } = Select;


export default function AdminUserForm({ adminUser, setIsDrawerOpen, setAdminUser }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState('')
    const [isLoading, setIsLoading] = React.useState(false);
    const query = useSelector((state) => state.adminUser.adminUsers.query);
    useEffect(() => {
        if (adminUser) {
            form.setFieldsValue(adminUser);
            setImageUrl(adminUser.imageUrl)
        }
    }, [dispatch, form, adminUser]);


    const onSaveAdmin = (values) => {
        setIsLoading(true);
        if (!values.email && !values.password) {
            toast.error('Please enter email and password');
            setIsLoading(false);
            return;
        }
     
        if (!adminUser && (!values.password || values.password.length < 6)) {
            toast.error('Password must be at least 6 characters');
            setIsLoading(false);
            return
        }
        if (!values.role) {
            toast.error('Please select a role');
            setIsLoading(false);
            return;
        }
        if(adminUser){
            dispatch(updateAdmin({data:{ ...values, imageUrl }, query}))
            .then((data) => {
                if (!data.payload) {
                    toast.error('An error occurred');
                    setIsLoading(false);
                    return;
    
                }
                if (data.payload?.error) {
                    toast.error(data.payload.message);
                    setIsLoading(false);
                    return;
                }
                toast.success('Admin User created successfully');
                form.resetFields();
                setIsLoading(false);
                dispatch(setAdminUser(null));
                setIsDrawerOpen(false);
            })
              

        }else{

            dispatch(createAdmin({ ...values, imageUrl })).then((data) => {
                if (!data.payload) {
                    toast.error('An error occurred');
                    setIsLoading(false);
                    return;
    
                }
                if (data.payload?.error) {
                    toast.error(data.payload.message);
                    setIsLoading(false);
                    return;
                }
                dispatch(setAdminUser(null));
                toast.success('Admin User created successfully');
                form.resetFields();
                setIsLoading(false);
                setIsDrawerOpen(false);
            });
        }
    };
    const uploadProfilePic = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/admin/uploadDocument', formData);
            onSuccess(response.data);
            setImageUrl(response.data.fileUrl);
        } catch (error) {
            onError(error);
        }
    };
    const removeImg = () => {
        setImageUrl('');
    }
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const AdminUserInfoForm = () =>

        <Form
            form={form}
            labelAlign='left'
            className='w-full flex flex-col justify-center gap-y-0  form-body'
            name="Info"
            colon={false}
            labelCol={{
                span: 10,
            }}
            wrapperCol={{
                span: 300,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={onSaveAdmin}
        >
            <Form.Item
                hidden
                name="_id"
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Name"
                name="name"
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please fill name'));
                    },
                },
                ]}
            >
                <Input
                />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Email"
                name="email"
                id='adminEmail'
                rules={[{
                    validator: (_, value) => {
                        if (isEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('must be a valied email'));
                    },
                },
                ]}
            >
                <Input autoComplete="off" />
            </Form.Item>
        <Form.Item
                className='p-0 m-2'
                label="Password"
                name="password"
                id='adminPassword'
                hidden={adminUser !== null}
                 rules={adminUser ? [] : [{
                    validator: (_, value) => {
                        if (value.length <= 8  || !/[A-Z]/.test(value)) {
                            return Promise.reject(new Error('must be at least 8 characters and an uppercase letter'));
                        }
                        return Promise.resolve();
                    },
                },
                ]}
            >
                <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Role"
                name="role"
            >
                <Select placeholder="Role" >
                    <Option value="superUser">Super User</Option>
                    {/* <Option value="admin">Admin</Option>
                    <Option value="accountManager">Account Manager</Option>
                    <Option value="operationManager">Operation Manager</Option> */}
                    <Option value="coordinator">Coordinator</Option>
                </Select>

            </Form.Item>



            <Form.Item className='flex justify-end'>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                >
                    {adminUser ? "Save" : "Add new Admin"}
                </Button>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>


    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Admin Information</p>
            <div className=' flex flex-col gap-x-8  px-8 pt-8'>
                <div className='flex gap-x-32'>

                    <Form.Item label="" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Upload
                            customRequest={uploadProfilePic}
                            listType="picture-circle"
                            maxCount={1}
                            fileList={imageUrl ? [{ url: imageUrl }] : []}
                            onRemove={removeImg}
                        >
                            {!imageUrl && <button
                                style={{
                                    // border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <CameraOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                </div>
                            </button>}
                        </Upload>
                    </Form.Item>
                    <p className='w-3/4 text-center instruction-p'>Please provide all the necessary details to add a new Admin account . Complete information ensures accurate record-keeping and smooth processing.</p>
                </div>
                <AdminUserInfoForm />
            </div>

        </div>

    )

}
