import React from 'react'
import CustomFilter from '../../../components/customFilter/CustomFilter'

export default function Filters({ query, setQuery }) {

    const statusList = [

        { value: '', label: 'All Statuses' },
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' },
    ]


    return (
        <div className='flex'>
            <CustomFilter
                title='Activation'
                name='isActive'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
   

        </div>
    )
}
