import React from 'react'
import CustomFilter from '../../../components/customFilter/CustomFilter'

export default function Filters({ query, setQuery }) {

    const statusList = [

        { value: '', label: 'All Statuses' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
    ]


    return (
        <div className='flex'>
            <CustomFilter
                title='Activation'
                name='status'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
   

        </div>
    )
}
