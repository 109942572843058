import React, {  useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import VehiclesManagementTable from "./table/VehiclesManagementTable";
import { Drawer } from "antd";
import { getVehicles, setQuery } from './store/vehicleSlice'
import VehicleForm from './components/VehicleForm'
import VehicleTypeForm from './components/VehicleTypeForm'
import { setVehicle ,getTypes } from './store/vehicleSlice'
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'

function ManageVehicles() {
  const dispatch = useDispatch();
  const location = useLocation();

  const vehicle = useSelector((state) => state.vehicle.vehicle.vehicle);
  const vehicles = useSelector((state) => state.vehicle.vehicle.vehicles);
  const query = useSelector((state) => state.vehicle.vehicle.query);
  const totalResults = useSelector((state) => state.vehicle.vehicle.totalResults);
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTypeDrawerOpen, setIsTypeDrawerOpen] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getVehicles(query));
    };
    fetchData();
  }, [dispatch, query]);
  const IS_SUPERVISOR = true;

  useEffect(() => {
    dispatch(getTypes())
  },[dispatch])

  const [drawerData, setDrawerData] = useState(undefined);

  const searchParams = new URLSearchParams(location.search);
  const fromOpen = searchParams.get('fromOpen');

  useEffect(() => {
    if (fromOpen === 'true') {
      setIsDrawerOpen(true);
    }
  }, [fromOpen]);

  const defaultSort = {
    orderColumn: "name",
    orderBy: "ascend",
  }




  const setTextRef = useRef(null);
 


  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        setQuery={setQuery}
        leftComponent={<p className="font-bold text-lg mr-8">Vehicles</p>}
        filterComponent={
          <div className='flex'>
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const { year, type, status, ...rest } = query
                dispatch(setQuery(rest))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
            />
          <CustomButton
            btnType="primary"
            icon="Plus"
            btnText="Add a New Vehicle Type"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => setIsTypeDrawerOpen(true)}
            />
          </div>
          }
        searchField='licensePlateNumber'
        btnProps={{
          btnText: `Vehicle`,
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        searchProps={{
          searchKey: "Vehicle",
          setTextRef: setTextRef,
          query,
          onTextChange: (e) => {
            dispatch(setQuery(e ? { ...query, search: e, limit: 10, page: 1 } : { ...query, limit: 10, page: 1 }))
          },
        }}
      />
      <AdminLayoutContentWrapper >
      {isFilterOpen &&
          <Filters
            query={query}
            setQuery={setQuery}

          />
        }
        <VehiclesManagementTable
          data={{ content: vehicles, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={drawerData?.id} 
          handleToggleVehicleStatus={(e) => console.log("toggle status", e)}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setVehicle={setVehicle}

        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={vehicle ? 'Edit Vehicle' :'Add New Vehicle'}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setVehicle(null))
          setIsDrawerOpen(false)
        }}
        width='33%'
        destroyOnClose
      >
        <VehicleForm
          vehicle={vehicle}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Drawer>
      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={'Add New Type'}
        placement="right"
        closable={false}
        open={isTypeDrawerOpen}
        onClose={() => {
          setIsTypeDrawerOpen(false)
        }}
        width='33%'
        destroyOnClose
      >
        <VehicleTypeForm
          setIsTypeDrawerOpen={setIsTypeDrawerOpen}
        />
      </Drawer>

    </div>
  );
}
export default ManageVehicles;