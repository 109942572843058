import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import CorporateAccountsManagementTable from "./table/CorporateAccountsManagementTable";
import { Drawer } from "antd";
import { getCorporateAccounts, setQuery, setCorporateAccount } from './store/CorporateAccountSlice'
import { Select, Space } from "antd";
import CorporateAccountFrom from './components/CorporateAccountFrom'
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'

function ManageCorporateAccounts() {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const corporateAccount = useSelector((state) => state.corporateAccount.corporateAccount.corporateAccount);
  const query = useSelector((state) => state.corporateAccount.corporateAccount.query);
  const corporateAccounts = useSelector((state) => state.corporateAccount.corporateAccount.corporateAccounts);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const totalResults = useSelector((state) => state.corporateAccount.corporateAccount.totalResults);

  const fetchData = async () => {
    await dispatch(getCorporateAccounts(query));
  };
  useEffect(() => {
    fetchData();
  }, [dispatch, query]);
  // const userSession = useAppSelector((state) => state.auth);
  const IS_SUPERVISOR = true;






  const defaultSort = {
    orderColumn: "createdAt",
    orderBy: "descend",
  }



  const setTextRef = useRef(null);

  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        leftComponent={<p className="font-bold text-lg mr-8">Corporate Accounts</p>}
        setQuery={setQuery}
        searchField='firstName'
        btnProps={{
          btnText: `Corporate Account`,
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        filterComponent={
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const {  isActive, ...rest } = query
                dispatch(setQuery(rest))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
            />
          }
        searchProps={{
          searchKey: "Business Account",
          setTextRef: setTextRef,
          onTextChange: (e) => dispatch(setQuery(e ? { ...query, search: e, limit: 10, page: 1 } : { ...query, limit: 10, page: 1 })),
        }}
      />

      <AdminLayoutContentWrapper>
      {isFilterOpen &&
          <Filters
            query={query}
            setQuery={setQuery}

          />
        }
        <CorporateAccountsManagementTable
          data={{ content: corporateAccounts, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={corporateAccount?._id} // Passing the drawerData prop
          // handleToggleVehicleStatus={handleToggleVehicleStatus}
          handleToggleVehicleStatus={(e) => console.log("toggle status", e)}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setCorporateAccount={setCorporateAccount}
        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={`${corporateAccount ? 'Edit' : 'Add New'} Corporate account`}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setCorporateAccount(null))
          setIsDrawerOpen(false)
        }}
        width='39%'
        destroyOnClose
      >
        <CorporateAccountFrom
          corporateAccount={corporateAccount}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Drawer>

    </div>
  );
}
export default ManageCorporateAccounts;