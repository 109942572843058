import React, { useState, useCallback, useRef } from "react";
import { GoogleMap, LoadScript, DrawingManager, Polygon } from "@react-google-maps/api";

const libraries = ['drawing', 'places'];

const MapDrawComponent = ({ zoneData, setZoneData, zonesPoints, zone }) => {
  const [polygonPath, setPolygonPath] = useState([]);
  const [drawingManager, setDrawingManager] = useState(null); // Save the drawing manager instance
  const [mapCenter, setMapCenter] = useState({
    lat: 25.276987,
    lng: 51.520008, // Doha coordinates
  }); // Manage the center state

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };

  const mapRef = useRef(null); // Create a reference to store the map instance

  const handlePolygonComplete = useCallback((polygon) => {
    const path = polygon.getPath().getArray().map((latLng) => ({
      lat: latLng.lat(),
      lng: latLng.lng(),
    }));
    setPolygonPath(path);
    setZoneData(path);
    polygon.setMap(null); // Clear the polygon from the map after capturing the path
  }, []);

  const handleLoadDrawingManager = (drawingManager) => {
    setDrawingManager(drawingManager); // Save the drawing manager instance for future control
  };

  const handleLoadMap = (map) => {
    mapRef.current = map; // Store the map instance
  };


  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyDeU5D4QKzPBEy-GP4S9MpeiQ-goAxYjfU" libraries={libraries}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter} // Use state for center to prevent reset
          zoom={10}
          onLoad={handleLoadMap} // Load the map instance
          onDragEnd={() => {
            if (mapRef.current) {
              setMapCenter(mapRef.current.getCenter().toJSON()); // Update center on drag
            }
          }}
        >
          {/* Drawing Manager for drawing polygons */}
          <DrawingManager
            onLoad={handleLoadDrawingManager}
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingModes: ["polygon"], // Enable polygon drawing
              },
              polygonOptions: {
                fillColor: "#2196F3",
                fillOpacity: 0.4,
                strokeColor: "#2196F3",
                strokeOpacity: 1,
                strokeWeight: 2,
              },
            }}
          />

          {/* Render the drawn polygon */}
          {polygonPath.length > 0 && (
            <Polygon
              path={polygonPath}
              options={{
                fillColor: "#2196F3",
                fillOpacity: 0.4,
                strokeColor: "#2196F3",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          )}

          {/* Render all initial zones */}
          {zonesPoints.map((zonePoints, index) => (
            <Polygon
              key={index}
              path={zonePoints.points || []}
              options={{
                fillColor: zonePoints.id === zone?.id ? "#FFD700" : "#FF6347",
                fillOpacity: 0.4,
                strokeColor: zonePoints.id === zone?.id  ? "#FFD700" : "#FF6347",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapDrawComponent;


