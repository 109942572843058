import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { useSelector } from 'react-redux';

export default function Charts() {
    // const dashboardData = useSelector(({ trips }) => trips.dashboardData);
    const dashboardData = useSelector(state => state.businessAccount.businessAccount.dashboardData)

    const [dayData, setDayData] = useState([]);

    const daysOfWeek = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

    const { tripsByMonth } = dashboardData;
    const tripsByMonthResult = tripsByMonth.reduce((acc, item) => {
        acc[item._id.month] = item.totalSum;
        return acc;
    }, {});


    let total = 0
    const monthData = Array.from({ length: 12 }, (_, index) => {
        const value = tripsByMonthResult[`${index + 1}`] || 0
        total += value
        return value
    }

    );
    const avg = total / 12

    const shortMonthsOfYear = [];
    const date = new Date();
  
    for (let i = 0; i < 12; i++) {
      date.setMonth(i); // Set the month from 0 to 11
      shortMonthsOfYear.push(date.toLocaleString('default', { month: 'short' })); // Get the full month name
    }

    // const shortMonthsOfYear = [
    //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    // ];

    useEffect(() => {
        let groupedByHour = {};
        let listOfToday = JSON.parse(JSON.stringify(dashboardData.tripsToday))
        listOfToday.tripsToday?.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
        listOfToday.forEach(item => {
            const dateStr = item.startAt;
            const date = new Date(dateStr);
            const hour = date.getHours();
            const hourRange = `${String(hour).padStart(2, '0')}:00`;
            if (!groupedByHour[hourRange]) {
                groupedByHour[hourRange] = [];
            }
            groupedByHour[hourRange].push(dateStr);
        });
        const dayData = daysOfWeek.map(day => {
            return groupedByHour[day] ? groupedByHour[day].length : 0;
        })
        setDayData(dayData);
    }, [dashboardData]);

    const renderCalendar = () => {
        return <div className='flex justify-between px-5'>
            <div />
            <svg width="27" height="27" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.9521 2.78076V5.44743M8.95215 2.78076V5.44743" stroke="#274D91" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.9465 17.4473H16.9585M16.9465 22.7806H16.9585M22.2738 17.4473H22.2858M11.6191 17.4473H11.6311M11.6191 22.7806H11.6311" stroke="#274D91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.61914 10.7808H28.2858" stroke="#274D91" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.28516 16.4383C4.28516 10.6286 4.28516 7.72372 5.95465 5.91887C7.62414 4.11401 10.3111 4.11401 15.6852 4.11401H18.2185C23.5925 4.11401 26.2796 4.11401 27.949 5.91887C29.6185 7.72372 29.6185 10.6286 29.6185 16.4383V17.1231C29.6185 22.9328 29.6185 25.8376 27.949 27.6425C26.2796 29.4473 23.5925 29.4473 18.2185 29.4473H15.6852C10.3111 29.4473 7.62414 29.4473 5.95465 27.6425C4.28516 25.8376 4.28516 22.9328 4.28516 17.1231V16.4383Z" stroke="#274D91" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.95215 10.7808H28.9521" stroke="#274D91" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    }
    return (
        <Stack direction="row">
            <div className='w-full flex flex-col md:flex-row mt-3 '>
                <div className='py-4 bg-white rounded-xl w-full md:w-1/2 mr-2 shadow border'>
                    {/* {renderCalendar()} */}
                    <Box sx={{ flexGrow: 1 }}>
                        <LineChart
                            width={550}
                            height={300}
                            series={[
                                {
                                    data: dayData, label: 'Trips of the Day',
                                    area: true
                                },
                            ]}
                            colors={['#274D91']}
                            xAxis={[{ scaleType: 'point', data: daysOfWeek }]}
                        />
                    </Box>
                    <div className='flex justify-between px-5'>
                        <div />
                        <span className='text-lg font-bold text-[#274D91]'>{dayData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)} Rides</span>
                    </div>
                </div>
                <div className='py-4 bg-white rounded-xl w-full md:w-1/2 shadow border'>
                    {/* {renderCalendar()} */}
                    <Box sx={{ flexGrow: 1 }}>
                        <BarChart
                            width={550}
                            height={300}
                            xAxis={[{ scaleType: 'band', data: shortMonthsOfYear }]}
                            series={[{ data: monthData, label: 'Total Cost (QAR)' }]}
                            colors={['#274D91']}
                        />
                    </Box>
                    <div className='flex justify-between px-5'>
                        <div />
                        <span className='text-lg font-bold text-[#274D91]'>Monthly Average {avg.toFixed(2)} QAR</span>
                    </div>
                </div>
            </div>
        </Stack>
    );
}
