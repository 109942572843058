import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';

export const getDocAlerts = createAsyncThunk(
  'alertsApp/getDocAlerts',
  async (query, { dispatch }) => {
    const response = await axios.get('/admin/documentsAlerts'
      , {
        params: query
      }
    );
    await dispatch(setDocs(response.data.docs));
    return response.data;
  }
);



const alertsSlice = createSlice({
  name: 'alertsApp',
  initialState: {
    docs: [],
    query: {
      // page: 1,
      // limit: 10,
      // orderBy: 'createdAt',
      // orderDirection: 'descend',
      status: '',
      type: 'vehicles',
    },
  },
  reducers: {
    setDocs: {
      reducer: (state, action) => {

        state.docs = action.payload ? action.payload : null;
      },
      prepare: docs => ({ payload: docs || null }),
    },

    setQuery: {
      reducer: (state, action) => {
        state.query = action.payload ? action.payload : {
          type: 'vehicles',
        };
      }
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setQuery,
  setDocs,
} = alertsSlice.actions;

export default alertsSlice.reducer;

