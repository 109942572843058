import { isEmpty } from "../utils/functions";
import React from "react";
import CustomButton from "../components/CustomButton/CustomButton";
import SearchComponent from "./SearchComponent";

const FilterListSection = ({
  btnProps = undefined,
  searchProps,
  children,
  filterComponent,
  leftComponent = undefined,
}) => {
  return (
    <section
      className={`px-6 bg-white py-4 mb-2 shadow-main-content rounded-layout h-[4.7rem] flex justify-between items-center`}
    >
      <div className=" whitespace-nowrap min-w-max ">
      {leftComponent}
      </div>
      {children ?? (
        <>
          {searchProps?.searchKey && (
            <SearchComponent
              search={searchProps.searchKey }
              setTextRef={searchProps.setTextRef}
              onTextChange={searchProps.onTextChange}
              placeHolder={searchProps.placeHolder}
              query={searchProps.query}
              className={searchProps.className}
            />
          )}
          {!isEmpty(filterComponent) && (
            <div className="filter-component mr-4">{filterComponent}</div>
          )}
          {!isEmpty(btnProps?.onClickHandler) && (
            <CustomButton
              btnType="primary"
              icon={btnProps?.icon ? btnProps?.icon : "Plus"}
              btnText={`${!btnProps?.hideNewText ? 'Add New' : ``} ${btnProps?.btnText}`}
              className={` !h-9 ${btnProps?.btnClass}`}
              handleSubmit={btnProps?.onClickHandler}
            />
          )}
        </>
      )}
    </section>
  );
};

export default FilterListSection;
