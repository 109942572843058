import bubbleMask from "../../assets/img/Bubble_mask.png";
import loginBackground from "../../assets/img/login_background.png";
import sawaBackground from "../../assets/sawaBackground.png";
import logo from "../../assets/logo.svg";
import "./styles.css";
const PublicLayoutContentWrapper = ({
    title,
    headerImage,
    children,
    className = ``,
    description,
    ImgAlt
}) => {
    return (
        <div className="flex justify-between items-center lgMax:flex-col    public-layoutC-Wrapper">

            <div className="w-[65%] hidden md:block logo-container">
                <img src={logo} alt={ImgAlt} className="w-full h-full" />
            </div> 
            <div className={`${className} md:w-[25%] login-title text-center md:text-start self-center min-h-[320px] login-fields `}>
                <h2 className="font-bold ">{title}</h2>
                <p className="  text-[#636363] mb-6">
                    {description} 
                </p>
                {children}
            </div>
          

         
        </div>
    );
};

export default PublicLayoutContentWrapper;
