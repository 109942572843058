import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getPromotions = createAsyncThunk(
  'promotionsApp/getPromotions',
  async (query, { dispatch }) => {
    const response = await axios.get('promotion/', {
      params: {...query, page: query.page -1},
    });
    dispatch(setPromotions(response.data.promotions));
    dispatch(setTotalResults(response.data.total));
    return response.data.results;
  }
);
export const createPromotion = createAsyncThunk(
  'promotionsApp/createPromotion',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('promotion/', data);
      dispatch(getPromotions( {
        page: 1,
        limit: 10,
        search: '',
        orderBy: 'createdAt',
        orderDirection: 'descending',
        isDeleted: false,
  
      }));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updatePromotion = createAsyncThunk(
  'promotionsApp/updatePromotion',
  async ({data,query}, { dispatch }) => {
    try {
      const response = await axios.patch(`promotion/${data._id}`, data);
      dispatch(getPromotions( query));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deletePromotion = createAsyncThunk(
  'promotionsApp/deletePromotion',
  async ({data,query}, { dispatch }) => {
    try {
      console.log('data', data);  
      const response = await axios.delete(`promotion/${data._id}`, data);
      dispatch(getPromotions( query));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const setAdminStatus = createAsyncThunk(
  'promotionsApp/setAdminStatus',
  async ({data, query}, { dispatch }) => {
    return null
      // const response = await axios.put('/admin/driver', data
      // );  
      // await dispatch(getPromotions(query));      
      // return response.data;
  }

);
export const getBusinessAccounts = createAsyncThunk(
  'promotionsApp/getBusinessAccounts',
  async (_, { dispatch }) => {
    const response = await axios.get('/business'
      , {
        params: { page: 0 , limit : 100000}

      }

    );
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data
  }

);

export const getTypes = createAsyncThunk(
  'promotionsApp/getTypes',
  async (query, { dispatch }) => {
    try {
      const response = await axios.get('/vehicle/type' );
      await dispatch(setTypes(response.data.types));
      return response.data;
    } catch (error) {
      console.log('error', error);
    }
  }
);


const promotionsSlice = createSlice({
  name: 'promotionsApp',
  initialState: {
    totalResults: 0,
    promotions: [],
    businessAccounts:[],
    promotion: null,
    vehicleTypes: [],
    query: {
      page: 1,
      limit: 10,
      search: '',
      orderBy: 'createdAt',
      orderDirection: 'descending',
      isDeleted: false,

    },
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setPromotions: {
      reducer: (state, action) => {
        state.promotions = action.payload ? action.payload : [];
      },
      prepare: promotions => ({ payload: promotions || [] }),
    },
    setTypes:{
      reducer: (state, action) => {
        state.vehicleTypes = action.payload ? action.payload : null;
      },
      prepare: vehicleTypes => ({ payload: vehicleTypes || null }),
    },
    setPromotion: {
      reducer: (state, action) => {
        state.promotion = action.payload ? action.payload : null;
      },
      prepare: promotion => ({ payload: promotion || null }),
    },
    setBusinessAccounts: {
      reducer: (state, action) => {
        state.businessAccounts = action.payload ? action.payload : [];
      },
      prepare: businessAccounts => ({ payload: businessAccounts || [] }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
  },

});

export const {
  setTotalResults,
  setPromotions,
  setQuery,
  setPromotion,
  setBusinessAccounts,
  setTypes,
} = promotionsSlice.actions;

export default promotionsSlice.reducer;

