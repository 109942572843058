import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import {
    Button,
    Form,
    Input,
} from 'antd';
import { saveVehicleType } from '../store/vehicleSlice';




export default function DriverForm({ vehicle, setIsTypeDrawerOpen }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm();

    const [isFormValid, setIsFormValid] = useState(false);

    const onSaveType = (values) => {
        setIsLoading(true)
        const prepareData = {
            make: {
                en: values['make.en'],
                ar: values['make.ar']
            },
            model: {
                en: values['model.en'],
                ar: values['model.ar']
            }
        }
        dispatch(saveVehicleType(prepareData))
        .then(e=>setIsLoading(false))
        .then( d=> setIsTypeDrawerOpen(false))
    }

    const onFieldsChange = (_, allFields) => {
        const allFilled = allFields.filter(e=> e.name[0] !== '_id').every(field => field.value);
        setIsFormValid(allFilled);
    };
    const VehicleTypeForm = () =>

        <Form
            form={form}
            onFieldsChange={onFieldsChange}
            labelAlign='left'
            className='w-full flex flex-col justify-center gap-y-0  '
            name="Info"
            colon={false}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 300,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={onSaveType}
        >
            <Form.Item
                label="Make English"
                name='make.en'
                className='p-0 m-2'
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Make Arabic"
                name='make.ar'
                className='p-0 m-2'
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Model English"
                name='model.en'
                className='p-0 m-2'
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Model Arabic"
                name='model.ar'
                className='p-0 m-2'
            >
                <Input />
            </Form.Item>
            <Form.Item
                hidden
                name="_id"
            >
                <Input />
            </Form.Item>

            <Form.Item className='flex justify-end'>
                <Button type="primary" htmlType="submit" disabled={isLoading || !isFormValid}>
                    Add Vehicle Type
                </Button>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>


    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Vehicle Details</p>

            <div className=' flex w-full flex-col px-8 pt-8'>
            <div className=' w-full flex '>
              <VehicleTypeForm />
            </div>
            </div>


        </div>
    )
}
