import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    Button,
    Form,
    Input,
    Select,
    Upload,
    Switch,
} from 'antd';
import { DatePicker } from 'antd';
import { isEmail } from '../../../utils/functions'
import { CameraOutlined } from '@ant-design/icons';
import { createPromotion, updatePromotion, getBusinessAccounts, getTypes } from '../store/promotionsSlice';
import { toast } from 'react-hot-toast';
import moment from 'moment';





const { Option } = Select;


export default function PromotionForm({ promotion, setIsDrawerOpen, setPromotion }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isExpireDisabled, setIsExpireDisabled] = React.useState(false);
    const query = useSelector((state) => state.promotions.promotions.query);
    const { vehicleTypes } = useSelector((state) => state.promotions.promotions);
    const { businessAccounts } = useSelector((state) => state.promotions.promotions);
    useEffect(() => {
        (async function fetchData() {
            await dispatch(getBusinessAccounts());
            await dispatch(getTypes());

        })();
        if (promotion) {
            form.setFieldsValue({ ...promotion, expirationDate: moment(promotion.expirationDate) });
            promotion.isAutoReactivation && setIsExpireDisabled(true);
        }
    }, [dispatch, form, promotion]);

    const onSavePromotion = (values) => {
        setIsLoading(true);
        if (
            values.promoCode === '' || values.promoCode === undefined
            || values.usageLimit === '' || values.usageLimit === undefined
            || values.businessAccountId === '' || values.businessAccountId === undefined
            || values.discountPercentage === '' || values.discountPercentage === undefined
            || values.expirationDate === '' || values.expirationDate === undefined
            || values.status === '' || values.status === undefined
        ) {
            toast.error('please fill all fields');
            setIsLoading(false);
            return;
        }
        if (promotion) {
            dispatch(updatePromotion({ data: { ...values }, query }))
                .then((data) => {
                    if (!data.payload) {
                        toast.error('An error occurred');
                        setIsLoading(false);
                        return;

                    }
                    if (data.payload?.error) {
                        toast.error(data.payload.message);
                        setIsLoading(false);
                        return;
                    }
                    toast.success('Promo Code created successfully');
                    form.resetFields();
                    setIsLoading(false);
                    dispatch(setPromotion(null));
                    setIsDrawerOpen(false);
                })


        } else {

            dispatch(createPromotion({ ...values, expirationDate: new Date(values.expirationDate) })).then((data) => {
                if (data.error) {
                    toast.error("some thing went wrong");
                    setIsLoading(false);
                    return;
                }
                if (!data.payload) {
                    toast.error('An error occurred');
                    setIsLoading(false);
                    return;

                }
                if (data.payload?.error) {
                    toast.error(data.payload.message);
                    setIsLoading(false);
                    return;
                }
                dispatch(setPromotion(null));
                toast.success('Promo Code created successfully');
                form.resetFields();
                setIsLoading(false);
                setIsDrawerOpen(false);
            });
        }
    };
    function handleFormChange(changedValues, allValues) {
        if (changedValues.isAutoReactivation === true) {
            form.setFieldsValue({ expirationDate: moment().endOf('month') });
            setIsExpireDisabled(true);
        } else if (changedValues.isAutoReactivation === false) {
            setIsExpireDisabled(false);
        }
    }
    const PromotionForm = () =>

        <Form
            form={form}
            labelAlign='left'
            className='w-full flex flex-col justify-center gap-y-0  form-body'
            name="Info"
            colon={false}
            initialValues={{ status: 'active', discountPercentage: 100, }}
            labelCol={{
                span: 10,
            }}
            wrapperCol={{
                span: 300,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={onSavePromotion}
            onValuesChange={(changedValues, allValues) => { handleFormChange(changedValues, allValues) }}
        >
            <Form.Item
                hidden
                name="_id"
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Promo Code"
                name="promoCode"
                id='promoCode'
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please fill promo code'));
                    },
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Usage Limit"
                name="usageLimit"
                id='usageLimit'
                rules={[{
                    validator: (_, value) => {
                        if (/^[1-9]\d*$/.test(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please enter a positive number'));
                    },
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Business Account"
                name="businessAccountId"
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please select business account'));
                    },
                },
                ]}
            >
                <Select placeholder="Business Account" >
                    {(businessAccounts || []).map(account => {
                        return <Option key={account._id} value={account._id}>{account.name}</Option>
                    })}
                </Select>

            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Discount Percentage"
                name="discountPercentage"
                id='discountPercentage'
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please fill discount percentage'));
                    },
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Limit by price amount"
                name="priceLimit"
                id='priceLimit'
                rules={[{
                    validator: (_, value) => {
                        if (value === '' || /^[1-9]\d*$/.test(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please enter a positive number or keep empty'));
                    },
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                label="Applicable car types"
                name="applicableCarTypes"
                id='applicableCarTypes'
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please fill promo code'));
                    },
                },
                ]}
            >
                <Select placeholder="Type" mode="multiple">
                    {
                        vehicleTypes.map(type => {
                            return <Option key={type._id} value={type._id}>{`${type.make.en}-${type.model.en}`}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                className="p-0 m-2"
                label="Expiration Date"
                name="expirationDate"
                id="expirationDate"
                rules={[
                    {
                        validator: (_, value) => {
                            if (value !== '') {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please fill expiration date'));
                        },
                    },
                ]}
            >
                <DatePicker disabled={isExpireDisabled} className="w-full" />
            </Form.Item>

            <Form.Item
                className='p-0 m-2'
                label="Status"
                name="status"
                rules={[{
                    validator: (_, value) => {
                        if (value !== '') {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('please select status'));
                    },
                },
                ]}
            >
                <Select placeholder="Status" >
                    <Option value='active'>Active</Option>
                    <Option value='inactive'>Inactive</Option>
                </Select>

            </Form.Item>
            <Form.Item
                className='p-0 m-2'
                labelCol={{ span: 21 }}
                wrapperCol={{ span: 24 }}
                label={
                    <span style={{ display: 'block', marginBottom: '10px', whiteSpace: 'pre-wrap' }}>
                        Auto-Reactivation (automatic reactivation of the promo code each month.)
                    </span>
                }
                name="isAutoReactivation"
            >
                <Switch style={{ float: 'right' }} />
            </Form.Item>
            <Form.Item className='flex justify-end'>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                >
                    Save
                </Button>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>


    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Promo Code Information</p>
            <div className=' flex flex-col gap-x-8  px-8 pt-8'>
                <PromotionForm />
            </div>

        </div>

    )

}
