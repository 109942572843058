import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';


const calculateDates = () => {
  const currentDate = new Date();

  // Set start date to the first day of the current month
  const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
  startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC

  // Set end date to the last day of the current month
  const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

  return {
      startDefaultDate: startDefaultDate.toISOString().split('T')[0],
      endDefaultDate: endDefaultDate.toISOString().split('T')[0]
  };
};
const defaultDates = calculateDates();



export const getCorporateAccounts = createAsyncThunk(
  'corporateAccountApp/getCorporateAccounts',
  async (query, { dispatch }) => {
    const response = await axios.get('/corporate'
      , {
        params: { ...query, page: query.page - 1 }

      }

    );
    await dispatch(setTotalResults(response.data.total));
    await dispatch(setCorporateAccounts(response.data.accounts));
    return response.data;
  }
);

export const getCorporateAccount = createAsyncThunk(
  'corporateAccountApp/getCorporateAccount',
  async ({ accountId }, { dispatch }) => {
    const response = await axios.get('/corporate/', { accountId });
    await dispatch(setCorporateAccounts(response.data.accounts));
    return response.data;
  }
);




export const getTypes = createAsyncThunk(
  'corporateAccountApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type');
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);
export const getPriceList = createAsyncThunk(
  'corporateAccountApp/getTypes',
  async ({ businessID, vehicleTypeId , corporateId }, { dispatch }) => {
    const response = await axios.get(`/corporate/pricing/${corporateId}/${businessID}/${vehicleTypeId}`);
    await dispatch(setPriceslist(response.data.pricing));
    return response.data;
  }
);
export const updatePriceList = createAsyncThunk(
  'corporateAccountApp/updatePriceList',
  async ({ corporateId, data }, { dispatch }) => {
    const response = await axios.put(`/corporate/pricing/${corporateId}`, data);
    //await dispatch(setPriceslist(response.data.pricing));
    return response.data;
  }
);
export const updateCorporateAccount = createAsyncThunk(
  'corporateAccountApp/updateCorporateAccount',
  async ({ data, query }, { dispatch }) => {
    const response = await axios.patch(`/corporate/${data._id}`, data);
    await dispatch(getCorporateAccounts(query))
    return response.data;
  }
);
export const getZones = createAsyncThunk(
  'corporateAccountApp/getTypes',
  async ( _ , { dispatch }) => {
    try {
      const response = await axios.get(`/zone/`);
      await dispatch(setZoneslist(response.data.zones));
      return response.data;

    } catch (error) {
      console.log('error', error);
      return error;
    }
  }
);
export const createCorporateAccount = createAsyncThunk(
  'corporateAccountApp/createCorporateAccount',
  async (data, { dispatch }) => {
    const response = await axios.post(`/corporate`,
      data
    );
    await dispatch(getCorporateAccounts({
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
    }));
    return response.data;
  }
);
export const exportReport = createAsyncThunk(
  'corporateAccountApp/exportReport',
  async (query, { dispatch }) => {
    try {
      const response = await axios.post(`/business/admin/report`,
        query
      );
      return response.data;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  }
);

export const getDashData = createAsyncThunk(
  'dashboardApp/getDashData',
  async ({ accountId }, { dispatch }) => {
    const response = await axios.get('/admin/businessDashboard/' + accountId);
    await dispatch(setDashData(response.data.result));
    return response.data;
  }
);

export const getBusinessAccounts = createAsyncThunk(
  'businessAccountApp/getBusinessAccounts',
  async (_, { dispatch }) => {
    const queryAll = {page : 0, limit : 10000000000  }
    const response = await axios.get('/business'
      , {
        params: {  ...queryAll }

      }

    );
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data;
  }
);

const corporateAccountSlice = createSlice({
  name: 'corporateAccountApp',
  initialState: {
    totalResults: 0,
    corporateAccounts: [],
    businessAccounts: [], 
    corporateAccount: null,
    corporateAccountPricePage: null,
    query: {
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
    },
    vehicles: [],
    drivers: [],
    pricesList: [],
    zonesList: [],
    pricesPopup: {
      data: {},
      isModalOpen: false
    },
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setTripsOfTheDay: {
      reducer: (state, action) => {

        state.tripsOfTheDay = action.payload ? action.payload : [];
      },
      prepare: tripsOfTheDay => ({ payload: tripsOfTheDay || [] }),
    },
    setCorporateAccount: {
      reducer: (state, action) => {

        state.corporateAccount = action.payload ? action.payload : null;
      },
      prepare: corporateAccount => ({ payload: corporateAccount || null }),
    },
    setCorporateAccountPricePage: {
      reducer: (state, action) => {

        state.corporateAccountPricePage = action.payload ? action.payload : null;
      },
      prepare: corporateAccountPricePage => ({ payload: corporateAccountPricePage || null }),
    },
    setBusinessAccounts: {
      reducer: (state, action) => {

        state.businessAccounts = action.payload ? action.payload : null;
      },
      prepare: businessAccounts => ({ payload: businessAccounts || null }),
    },
    setDashData: {
      reducer: (state, action) => {

        state.dashboardData = action.payload ? action.payload : null;
      },
      prepare: dashboardData => ({ payload: dashboardData || null }),
    },
    setQuery: {
      reducer: (state, action) => {
        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        };
      }
    },
    setDashBoardQuery: {
      reducer: (state, action) => {
        state.dashboardQuery = action.payload ? action.payload : {
          page: 0,
          limit: 10000000,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        };
      },
      prepare: dashboardQuery => ({
        payload: dashboardQuery || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        }
      }),
    },

    setCorporateAccounts: {
      reducer: (state, action) => {
        state.corporateAccounts = action.payload ? action.payload : 0;
      },
      prepare: corporateAccounts => ({ payload: corporateAccounts || [] }),
    },
    setVehicles: {
      reducer: (state, action) => {
        state.vehicles = action.payload ? action.payload : 0;
      },
      prepare: vehicles => ({ payload: vehicles || [] }),
    },
    setDrivers: {
      reducer: (state, action) => {
        state.drivers = action.payload ? action.payload : 0;
      },
      prepare: drivers => ({ payload: drivers || [] }),
    },
    setTypes: {
      reducer: (state, action) => {
        state.types = action.payload ? action.payload : 0;
      },
      prepare: types => ({ payload: types || [] }),
    },
    setPricesPopup: {
      reducer: (state, action) => {
        state.pricesPopup = action.payload ? action.payload : { data: {}, isOpen: false };
      },
      prepare: pricesPopup => ({ payload: pricesPopup || [] }),
    },
    setPriceslist: {
      reducer: (state, action) => {
        state.pricesList = action.payload ? action.payload : [];
      },
      prepare: pricesList => ({ payload: pricesList || [] }),
    },
    setZoneslist: {
      reducer: (state, action) => {
        state.zonesList = action.payload ? action.payload : [];
      },
      prepare: zonesList => ({ payload: zonesList || [] }),
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setTotalResults,
  setCorporateAccounts,
  setQuery,
  setCorporateAccount,
  setVehicles,
  setDrivers,
  setTypes,
  setPricesPopup,
  setPriceslist,
  setZoneslist,
  setDashData,
  setTripsOfTheDay,
  setDashBoardQuery,
  setBusinessAccounts,
  setCorporateAccountPricePage,
} = corporateAccountSlice.actions;

export default corporateAccountSlice.reducer;

