import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';
import jwtService from '../../services/jwtService';
import { setUserData } from './userSlice';

export const submitLogin =
  ({ email, password }) =>
  async dispatch => {
    return jwtService
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(setUserData(user));
        dispatch(loginSuccess());
        return user;
      })
      .catch(error => {
        return dispatch(loginError(error.response.data.message));
      });
  };

  export const sendOtp = createAsyncThunk(
    'auth/sendOtp',
    async ({ resever, provider }, { dispatch }) => {
      try{
        const response = await axios.post('/admin/auth/sendOtp', { resever, provider });
        return response.data;

      }catch(error){
        console.log(error)
        return {error}
      }

    }
  );
  
  export const submitOtp = createAsyncThunk(
    'auth/submitOtp',
    async ({ otp, otpToken }, { dispatch }) => {
      try {
        const response = await axios.post(
          '/admin/auth/checkOtp',
          { otp }, // Send otp in the body
          {
            headers: {
              Authorization: `Bearer ${otpToken}` // Send otpToken in the headers
            }
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
        return { error };
      }
    }
  );
  export const resetAdminPassword = createAsyncThunk(
    'auth/resetAdminPassword',
    async ({password , resetPasswordToken}, { dispatch }) => {
      try {
        const response = await axios.post(
          '/admin/auth/setPassword',
          { password }, // Send otp in the body
          {
            headers: {
              Authorization: `Bearer ${resetPasswordToken}` // Send otpToken in the headers
            }
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
        return { error };
      }
    }
  );
  
  export const resetAdminFirstPassword = createAsyncThunk(
    'auth/resetAdminFirstPassword',
    async ({oldPassword , password}, { dispatch }) => {
      try {
        const response = await axios.post(
          '/admin/auth/resetFirstPassword',
          { oldPassword , password }, 
        );
        return response.data;
      } catch (error) {
        console.log(error);
        return { error };
      }
    }
  );
  

const initialState = {
  success: false,
  errors: [],
  otpToken: null,
  resetPasswordToken: null,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload != null ? (Array.isArray(action.payload) ? action.payload : [action.payload]) : [];
    },
    setOtpToken: (state, action) => {
      state.otpToken = action.payload;
    },
    setResetPasswordToken: (state, action) => {
      state.resetPasswordToken = action.payload;
    },
  },
});

export const { loginSuccess, loginError , setOtpToken , setResetPasswordToken} = loginSlice.actions;

export default loginSlice.reducer;
