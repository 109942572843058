import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';


const calculateDates = () => {
  const currentDate = new Date();

  // Set start date to the first day of the current month
  const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
  startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC

  // Set end date to the last day of the current month
  const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

  return {
      startDefaultDate: startDefaultDate.toISOString().split('T')[0],
      endDefaultDate: endDefaultDate.toISOString().split('T')[0]
  };
};
const defaultDates = calculateDates();



export const getBusinessAccounts = createAsyncThunk(
  'businessAccountApp/getBusinessAccounts',
  async (query, { dispatch }) => {
    const response = await axios.get('/business'
      , {
        params: { ...query, page: query.page - 1 }

      }

    );
    await dispatch(setTotalResults(response.data.total));
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data;
  }
);

export const getBusinessAccount = createAsyncThunk(
  'businessAccountApp/getBusinessAccount',
  async ({ accountId }, { dispatch }) => {
    const response = await axios.get('/business/', { accountId });
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data;
  }
);


export const getVehicles = createAsyncThunk(
  'businessAccountApp/getVehicles',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle'
      , {
        params: query
      }

    );
    await dispatch(setVehicles(response.data.vehicles));
    return response.data;
  }
);

export const getTripsOfTheDay = createAsyncThunk(
  'businessAccountApp/getVehicles',
  async (query, { dispatch }) => {
    const response = await axios.get('/trip/adminList'
      , {
        params: query
      }

    );
    await dispatch(setTripsOfTheDay(response.data.trips));
    return response.data;
  }
);
export const getTypes = createAsyncThunk(
  'businessAccountApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type');
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);
export const getPriceList = createAsyncThunk(
  'businessAccountApp/getTypes',
  async ({ businessID, vehicleTypeId }, { dispatch }) => {
    const response = await axios.get(`/business/pricing/${businessID}/${vehicleTypeId}`);
    await dispatch(setPriceslist(response.data.pricing));
    return response.data;
  }
);
export const updatePriceList = createAsyncThunk(
  'businessAccountApp/updatePriceList',
  async ({ businessID, data }, { dispatch }) => {
    const response = await axios.put(`/business/pricing/${businessID}`, data);
    //await dispatch(setPriceslist(response.data.pricing));
    return response.data;
  }
);
export const updateBussinessAccount = createAsyncThunk(
  'businessAccountApp/getTypes',
  async ({ data, query }, { dispatch }) => {
    const response = await axios.put(`/business/${data._id}`, data);
    await dispatch(getBusinessAccounts(query))
    return response.data;
  }
);
export const getZones = createAsyncThunk(
  'businessAccountApp/getTypes',
  async ({ }, { dispatch }) => {
    try {
      const response = await axios.get(`/zone/`);
      await dispatch(setZoneslist(response.data.zones));
      return response.data;

    } catch (error) {
      console.log('error', error);
      return error;
    }
  }
);
export const createBusinessAccount = createAsyncThunk(
  'businessAccountApp/createBusinessAccount',
  async (data, { dispatch }) => {
    const response = await axios.post(`/business`,
      data
    );
    await dispatch(getBusinessAccounts({
      page: 0,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
    }));
    return response.data;
  }
);
export const exportReport = createAsyncThunk(
  'businessAccountApp/exportReport',
  async (query, { dispatch }) => {
    try {
      const response = await axios.post(`/business/admin/report`,
        query
      );
      return response.data;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  }
);

export const getDashData = createAsyncThunk(
  'dashboardApp/getDashData',
  async ({ accountId }, { dispatch }) => {
    const response = await axios.get('/admin/businessDashboard/' + accountId);
    await dispatch(setDashData(response.data.result));
    return response.data;
  }
);


const businessAccountSlice = createSlice({
  name: 'businessAccountApp',
  initialState: {
    totalResults: 0,
    businessAccounts: [],
    businessAccount: null,
    query: {
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
    },
    vehicles: [],
    drivers: [],
    pricesList: [],
    zonesList: [],
    tripsOfTheDay: [],
    dashboardData: null,
    pricesPopup: {
      data: {},
      isModalOpen: false
    },
    dashboardQuery: {
      page: 1,
      limit: 10000000,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
      startDate: defaultDates.startDefaultDate,
      endDate: defaultDates.endDefaultDate,
    },
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setTripsOfTheDay: {
      reducer: (state, action) => {

        state.tripsOfTheDay = action.payload ? action.payload : [];
      },
      prepare: tripsOfTheDay => ({ payload: tripsOfTheDay || [] }),
    },
    setBusinessAccount: {
      reducer: (state, action) => {

        state.businessAccount = action.payload ? action.payload : null;
      },
      prepare: businessAccount => ({ payload: businessAccount || null }),
    },
    setDashData: {
      reducer: (state, action) => {

        state.dashboardData = action.payload ? action.payload : null;
      },
      prepare: dashboardData => ({ payload: dashboardData || null }),
    },
    setQuery: {
      reducer: (state, action) => {
        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        };
      }
    },
    setDashBoardQuery: {
      reducer: (state, action) => {
        state.dashboardQuery = action.payload ? action.payload : {
          page: 0,
          limit: 10000000,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        };
      },
      prepare: dashboardQuery => ({
        payload: dashboardQuery || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        }
      }),
    },

    setBusinessAccounts: {
      reducer: (state, action) => {
        state.businessAccounts = action.payload ? action.payload : 0;
      },
      prepare: businessAccounts => ({ payload: businessAccounts || [] }),
    },
    setVehicles: {
      reducer: (state, action) => {
        state.vehicles = action.payload ? action.payload : 0;
      },
      prepare: vehicles => ({ payload: vehicles || [] }),
    },
    setDrivers: {
      reducer: (state, action) => {
        state.drivers = action.payload ? action.payload : 0;
      },
      prepare: drivers => ({ payload: drivers || [] }),
    },
    setTypes: {
      reducer: (state, action) => {
        state.types = action.payload ? action.payload : 0;
      },
      prepare: types => ({ payload: types || [] }),
    },
    setPricesPopup: {
      reducer: (state, action) => {
        state.pricesPopup = action.payload ? action.payload : { data: {}, isOpen: false };
      },
      prepare: pricesPopup => ({ payload: pricesPopup || [] }),
    },
    setPriceslist: {
      reducer: (state, action) => {
        state.pricesList = action.payload ? action.payload : [];
      },
      prepare: pricesList => ({ payload: pricesList || [] }),
    },
    setZoneslist: {
      reducer: (state, action) => {
        state.zonesList = action.payload ? action.payload : [];
      },
      prepare: zonesList => ({ payload: zonesList || [] }),
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setTotalResults,
  setBusinessAccounts,
  setQuery,
  setBusinessAccount,
  setVehicles,
  setDrivers,
  setTypes,
  setPricesPopup,
  setPriceslist,
  setZoneslist,
  setDashData,
  setTripsOfTheDay,
  setDashBoardQuery
} = businessAccountSlice.actions;

export default businessAccountSlice.reducer;

