import React from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { DatePicker } from 'antd';
import CustomFilter from '../../../components/customFilter/CustomFilter'


export default function Filters({ query, setQuery }) {
    const dispatch = useDispatch()
    const {businessAccounts} = useSelector((state) => state.dashboard.dashboard)    
    const {drivers} = useSelector((state) => state.dashboard.dashboard)    
    const {vehicles} = useSelector((state) => state.dashboard.dashboard)    
    const {zones} = useSelector((state) => state.dashboard.dashboard)    
    const {types} = useSelector((state) => state.dashboard.dashboard)    
    const points= zones.map(({coordinates}) => coordinates).flat()
    const statusList = [
        { value: '', label: 'All' },
        { value: 'pending', label: 'Pending' },
        { value: 'confirmed', label: 'Confirmed' },
        { value: 'enRoute', label: 'En Route' },
        { value: 'arrived', label: 'Arrived' },
        { value: 'ongoing', label: 'Ongoing' },
        { value: 'completed', label: 'Completed' },
        { value: 'canceled', label: 'Canceled' },
        { value: 'declined', label: 'Declined' },
        { value: 'no_drivers_available', label: 'No Drivers Available' },
        { value: 'failed', label: 'Failed' },
    ]
    const businessAccountsList = [
        { value: '', label: 'All' },
       ...businessAccounts.map(({ id, name }) => ({ value: id, label: name }))
    ]
    const driversList = [
        { value: '', label: 'All' },
       ...drivers.map(({ id, firstName , lastName }) => ({ value: id, label: firstName + ' ' + lastName })) 
    ]
    const verhiclesList = [
        { value: '', label: 'All' },
       ...vehicles.map(({ id, licensePlateNumber  }) => ({ value: id, label: licensePlateNumber })) 
    ]
    const pointsList = [
        { value: '', label: 'All' },
       ...points.map(({ id, name  }) => ({ value: id, label: name.en })) 
    ]
    const typesList = [
        { value: '', label: 'All' },
       ...types.map(({ id, make, model  }) => ({ value: id, label: `${make.en}/${model.en}` })) 
    ]
    const handleStartDateChange = (date, dateString) => {
        dispatch(setQuery({ ...query, startDate: dateString }));
    };
    const handleEndDateChange = (date, dateString) => {
        dispatch(setQuery({ ...query, endDate: dateString }));
    };

 
    return (
        <div className='flex w-full justify-start items-center flex-wrap -ml-8'>
            <CustomFilter
                title='Status'
                name='status'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Business Account'
                name='businessAccountId'
                list={businessAccountsList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Driver'
                name='driverId'
                list={driversList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Assigned Vehicle'
                name='vehicleId'
                list={verhiclesList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Make /  Model'
                name='vehicleTypeId'
                list={typesList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Pickup location'
                name='pointFromId'
                list={pointsList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Dropoff location'
                name='pointToId'
                list={pointsList}
                query={query}
                setQuery={setQuery}
            />
         

        </div>
    )
}
