import {
  map,
  users,
  location,
  vehicles,
  briefcase,
  dashboard,
  promotion,
  corporate,
  alerts,
} from '../assets/img/index';
import adminUser from '../assets/img/filter-horizontal.svg'
const LOGIN_ROUTE = "/login";
const RESET_PASSWORD_ROUTE = "/reset-password";
const FORGOT_PASSWORD_ROUTE = "/forgot-password";

const MANAGE_USERS_ROUTE = "/manage-users";
const MANAGE_GEOFENCE = "/manage-geofencing";
const MANAGE_SETTINGS = "/settings";
const DRIVERS = "/manage-drivers";
const MANAGE_VEHICLES = "/manage-vehicles";
const MANAGE_TRIPS = "/manage-trips";
const MANAGE_BUSINESS_ACCOUNTS = "/manage-business-accounts";
const MANAGE_CORPORATE_ACCOUNTS = "/manage-corporate-accounts";
const MANAGE_ZONES = "/manage-zones";
const DASHBOARD = "/dashboard";
const MANAGE_PROMOTIONS = "/manage-promotions";
const MANAGE_ALERTS = "/manage-alerts";

const MANAGE_NOTIFICATIONS = "/manage-notifications";
const ADMIN_USERS = "/admin-users";

const SUPER_USER = "superUser";
const ADMIN = "admin";
const ACCOUNT_MANAGER = "accountManager";
const OPERATION_MANAGER = "operationManager";
const COORDINATOR = "coordinator";
const SUPER_ADMIN = "superAdmin";



export const routeMenuMapper = [
  {
    route: DASHBOARD,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: ADMIN_USERS,
    permission: [SUPER_ADMIN],
  },
  {
    route: LOGIN_ROUTE,
  },
  {
    route: FORGOT_PASSWORD_ROUTE,
  },
  {
    route: RESET_PASSWORD_ROUTE,
  },
  {
    route: MANAGE_USERS_ROUTE,
    permission: [SUPER_ADMIN],
  },
  {
    route: MANAGE_GEOFENCE,
    permission: [SUPER_ADMIN],
  },
  {
    route: MANAGE_NOTIFICATIONS,
    permission: [SUPER_ADMIN ],
  },
  {
    route: MANAGE_SETTINGS,
    permission: [ SUPER_ADMIN],
  },
  {
    route: DRIVERS,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: MANAGE_VEHICLES,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: MANAGE_TRIPS,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: MANAGE_BUSINESS_ACCOUNTS,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: MANAGE_CORPORATE_ACCOUNTS,
    permission: [COORDINATOR, SUPER_ADMIN],
  },
  {
    route: MANAGE_ZONES,
    permission: [ SUPER_ADMIN],
  },
  {
    route: MANAGE_PROMOTIONS,
    permission: [ SUPER_ADMIN ],
  },
  {
    route: MANAGE_ALERTS,
    permission: [ SUPER_ADMIN ],
  },

];

const userMenus = {

  [DASHBOARD]: {
    title: ["Dashboard"],
    icon: dashboard,
  },
  [ADMIN_USERS]: {
    title: ["Admin"],
    icon: dashboard,
    svg: adminUser,
  },
  [DRIVERS]: {
    title: ["Drivers"],
    icon: users,
  },
  [MANAGE_VEHICLES]: {
    title: ["Vehicles"],
    icon: vehicles,
  },
  [MANAGE_TRIPS]: {
    title: ["Trips"],
    icon: map,
  },
  [MANAGE_BUSINESS_ACCOUNTS]: {
    title: ["Business Accounts"],
    icon: briefcase,
  },
  [MANAGE_CORPORATE_ACCOUNTS]: {
    title: ["Corporate Accounts"],
    icon: corporate,
  },
  [MANAGE_ZONES]: {
    title: ["Zones"],
    icon: location,
  },
  [MANAGE_PROMOTIONS]: {
    title: ["Promo Code"],
    icon: promotion,
  },
  [MANAGE_ALERTS]: {
    title: ["Alerts"],
    icon: alerts,
  },
};

export const getMenusByUserType = (role) => routeMenuMapper
  .filter(({ permission }) => permission?.includes(role))
  .map(({ route }) => ({
    route: `${route}`,
    ...userMenus?.[route],
  }));

