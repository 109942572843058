import React, {  useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import ZonesManagementTable from "./table/ZonesManagementTable";
import { Drawer } from "antd";
import { getZones, setQuery , setZone } from './store/zoneSlice'
import ZoneForm from './components/ZoneForm'


function ManageZones() {
  const dispatch = useDispatch();
  const zone = useSelector((state) => state.zone.zone.zone);
  const zones = useSelector((state) => state.zone.zone.zones);
  const query = useSelector((state) => state.zone.zone.query);
  const totalResults = useSelector((state) => state.zone.zone.totalResults);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getZones(query));
    };
    fetchData();
  }, [dispatch, query]);
  // const userSession = useAppSelector((state) => state.auth);
  const IS_SUPERVISOR = true;



  const [drawerData, setDrawerData] = useState(undefined);

  const defaultSort = {
    orderColumn: "name",
    orderBy: "ascend",
  }




  const setTextRef = useRef(null);
 


  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        setQuery={setQuery}
        leftComponent={<p className="font-bold text-lg mr-8">Zones</p>}
        searchField='name'
        btnProps={{
          btnText: `Zone`,
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        searchProps={{
          searchKey: "Zone",
          className: "mr-8",
          setTextRef: setTextRef,
          onTextChange:(e)=>dispatch(setQuery(e ? {...query,search:e ,  limit:10, page:1} : {...query, limit:10, page:1})),
        }}
      />
      <AdminLayoutContentWrapper>
        <ZonesManagementTable
          data={{ content: zones, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={zone?.id} // Passing the drawerData prop
          // handleTogglezonestatus={handleTogglezonestatus}
          handleTogglezonestatus={(e) => console.log("toggle status", e)}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setZone={setZone}

        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={zone ? 'Edit Zone' :'Add New Zone'}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setZone(null))
          setIsDrawerOpen(false)
        }}
        width='650px'
        destroyOnClose
      >
        <ZoneForm
          zone={zone}
          setIsDrawerOpen={setIsDrawerOpen}
          zonesPoints={zones.filter(zone=> zone.polygonPoints && zone.polygonPoints.length > 0 ).map(e=> { return {points:e.polygonPoints , id:e.id}})}
        />
      </Drawer>

    </div>
  );
}
export default ManageZones;