import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "./functions";

export const UseNavigateStateParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const DEFAULT_PAGE_SIZE = 10; 
  const generatePathByStateParams = (
    custom) => {
    const url = new URLSearchParams();
    url.set(
      "sortDirection",
      location.state?.orderBy === "ascend" ? "Asc" : "Desc",
    );
    url.set("sortColumn", "email");
    url.set("pageNum", location.state?.currentPage ?? 1);
    url.set("pageSize", DEFAULT_PAGE_SIZE.toString());
    if (!isEmpty(custom?.key)) {
      url.set(custom.key, custom.value);
    }
    return `?${url.toString()}`;
  };

  const generateObjectByStateParams = useCallback(
    (custom) => {
      const payload = {};

      payload.sortDirection =
        location.state?.orderBy === "ascend" ? "Asc" : "Desc";
      payload.sortColumn = location?.state?.orderColumn;
      payload.pageNum = location.state?.currentPage ?? 1;
      payload.pageSize = DEFAULT_PAGE_SIZE.toString();
      payload.searchText = location.state?.searchText ?? "";
      if (!isEmpty(custom?.key)) {
        payload[custom.key] = custom.value;
      }

      return payload;
    },
    [location?.state],
  );

  const handleNavigateStateParams = (stateParams) => {
    navigate(location.pathname, {
      state: isEmpty(stateParams)
        ? undefined
        : {
            ...location.state,
            ...stateParams,
          },
    });
  };

  return {
    generatePathByStateParams,
    handleNavigateStateParams,
    generateObjectByStateParams,
  };
};
