import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from "../../utils/functions";
// import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useDispatch, useSelector } from "react-redux";

import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import { UseNavigateStateParams } from "../../utils/useNavigateServerParams";
import { useLocation } from "react-router-dom";
import DriversManagementTable from "./table/DriversManagementTable";
import { Drawer } from "antd";
import { getDrivers, setQuery } from './store/driverSlice'
import DriverForm from './components/DriverForm'
import { setDriver } from './store/driverSlice'
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'


function ManageDrivers() {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driver.driver.driver);
  const query = useSelector((state) => state.driver.driver.query);
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const totalResults = useSelector((state) => state.driver.driver.totalResults);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getDrivers(query));
    };
    fetchData();
  }, [dispatch, query]);
  const IS_SUPERVISOR = true;

  const drivers = useSelector((state) => state.driver.driver.drivers);


  const [drawerData, setDrawerData] = useState(undefined);



  const location = useLocation();
  const defaultSort = {
    orderColumn: "name",
    orderBy: "ascend",
  }

  const { handleNavigateStateParams } =
    UseNavigateStateParams();


  const setTextRef = useRef(null);
  // const dispatch = useAppDispatch();
  const handleToggleDrawer = useCallback(
    (data = undefined) => {
      if (!isEmpty(data) && typeof data !== "string" && "vehicle" in data) {
        setDrawerData(data);
      } else {
        setDrawerData(undefined);

        if (data === "form") {
          // dispatch(
          //   listingInputSearchActions.resetListingInputSearchSlice("vehicles"),
          // );
          if (setTextRef.current?.setText) {
            setTextRef.current?.setText("");
          }
          if (setTextRef.current?.setText) {
            setTextRef.current?.setText("");
          }
          handleNavigateStateParams({ currentPage: 1, searchText: "" });
        }
      }
    },
    [setDrawerData, location?.state],
  );

  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        setQuery={setQuery}
        leftComponent={<p className="font-bold text-lg mr-8">Drivers</p>}
        searchField='firstName'
        btnProps={{
          btnText: `Driver`,
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        filterComponent={
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const { availability, status, ...rest } = query
                dispatch(setQuery(rest))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
            />
          }
          searchProps={{
          searchKey: "Drivers",
          setTextRef: setTextRef,
          query,
          onTextChange: (e) => {
            const {search, ...rest} = query
            dispatch(setQuery(e ? { ...query, search: e, limit: 10, page: 1 } : { ...rest, limit: 10, page: 1 }))
          },
        }}
        />
      <AdminLayoutContentWrapper >
        {isFilterOpen &&
          <Filters
            query={query}
            setQuery={setQuery}

          />
        }
        <DriversManagementTable
          data={{ content: drivers, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={drawerData?.id} // Passing the drawerData prop
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setDriver={setDriver}

        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={driver ? 'Driver Approval' : 'Add New Driver'}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setDriver(null))
          setIsDrawerOpen(false)
        }}
        width='33%'
        destroyOnClose
      >
        <DriverForm
          driver={driver}
          setIsDrawerOpen={setIsDrawerOpen}
          query={query}
        />
      </Drawer>
    </div>
  );
}
export default ManageDrivers;