import React, { useEffect, useState } from "react";
import loginImage from "../../assets/img/login_header.png"
import { useNavigate   } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp , setOtpToken} from "../../auth/store/loginSlice"
import { validateEmail, isEmpty } from '../../helper'
import MetaTag from "../../components/metaTag";
import { Box, TextField, Button } from '@mui/material';
import PublicLayoutContentWrapper from "../../layout/publicLayout/PublicLayout";
import toast from "react-hot-toast";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const {otpToken} = useSelector(({ auth }) => auth.login);
    const user = useSelector(({ auth }) => auth.user.data);
    useEffect(() => {
        if (user.id) {
            navigate("/");
        }
    }, [dispatch, navigate, user])

   

    const handleForgotPassword = () => {

        if (email.length === 0) {
            toast.error('Please enter email and password');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        dispatch(sendOtp({ resever : email, provider: 'email' })).then((res) => {
            if(!res || res.payload.error){
                toast.error('Something went wrong, Please try again later');
                return 
            }
            if(res.payload.token){
                dispatch(setOtpToken(res.payload.token));
                toast.success('OTP sent to your email address');
                navigate("/verify-otp", { state: { email } });
            }
            
        });
    }
    return (
        <div className="	   ">
            <MetaTag />
            <PublicLayoutContentWrapper title="Forgot Password" description="Please enter your registered email address to get new password" className="flex flex-col items-center" headerImage={loginImage} >
                <div className="flex flex-col   w-full  mx-auto text-start">
                    <Box
                        component="form"
                        className="flex flex-col gap-y-4 justify-center w-full  mx-auto  "
                        // sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            className="w-full"
                            name="email"
                            id="email"
                            label="Email"
                            variant="outlined"
                            required
                            placeholder="Enter Email"
                            onChange={(e) => setEmail( e.target.value)}
                            color="secondary"
                        />
                        <Button
                            variant="contained"
                            className="mb-0"
                            sx={{ backgroundColor: '#24508d' }}
                            onClick={handleForgotPassword}
                        >
                            Get New Password
                        </Button>
                    </Box>

                </div>
            </PublicLayoutContentWrapper>

        </div>
    );
};

export default ForgotPassword;
