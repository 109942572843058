import React from 'react'
import { Select, Space } from "antd";
import { useDispatch } from 'react-redux';


export default function CustomFilter({title , list, query , setQuery ,name}) {
    const dispatch = useDispatch()

    const handleChange = (value , name) => {
          dispatch(setQuery({ ...query, [name]: value, page: 1 }));
        }
  return (
    <>
     <Space wrap className='custom-filter '>
        <p className='ml-4 font-bold'>{title}</p>
      <Select
        className="ml-4"
        defaultValue=""
        style={{ width: 120 }}
        onChange={e=>handleChange(e,name)}
        placeholder={`Select a ${name}`}
        options={list}
      />
    </Space>

    </>
  )
}
