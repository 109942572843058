import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import BusinessAccountsManagementTable from "./table/BusinessAccountsManagementTable";
import { Drawer } from "antd";
import { getBusinessAccounts, setQuery, setBusinessAccount } from './store/businessAccountSlice'
import { Select, Space } from "antd";
import BusinessAccountFrom from './components/BusinessAccountFrom'
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'

function ManageBusinessAccounts() {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const businessAccount = useSelector((state) => state.businessAccount.businessAccount.businessAccount);
  const query = useSelector((state) => state.businessAccount.businessAccount.query);
  const businessAccounts = useSelector((state) => state.businessAccount.businessAccount.businessAccounts);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const totalResults = useSelector((state) => state.businessAccount.businessAccount.totalResults);

  const fetchData = async () => {
    await dispatch(getBusinessAccounts(query));
  };
  useEffect(() => {
    fetchData();
  }, [dispatch, query]);
  // const userSession = useAppSelector((state) => state.auth);
  const IS_SUPERVISOR = true;






  const defaultSort = {
    orderColumn: "createdAt",
    orderBy: "descend",
  }



  const setTextRef = useRef(null);
  // const dispatch = useAppDispatch();

  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        leftComponent={<p className="font-bold text-lg mr-8">Business Accounts</p>}
        setQuery={setQuery}
        searchField='firstName'
        btnProps={{
          btnText: `Business Account`,
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        filterComponent={
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const {  status, ...rest } = query
                dispatch(setQuery(rest))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
            />
          }
        searchProps={{
          searchKey: "Business Account",
          setTextRef: setTextRef,
          onTextChange: (e) => dispatch(setQuery(e ? { ...query, search: e, limit: 10, page: 1 } : { ...query, limit: 10, page: 1 })),
        }}
      />

      <AdminLayoutContentWrapper>
      {isFilterOpen &&
          <Filters
            query={query}
            setQuery={setQuery}

          />
        }
        <BusinessAccountsManagementTable
          data={{ content: businessAccounts, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={businessAccount?._id} // Passing the drawerData prop
          // handleToggleVehicleStatus={handleToggleVehicleStatus}
          handleToggleVehicleStatus={(e) => console.log("toggle status", e)}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setBusinessAccount={setBusinessAccount}
        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={`${businessAccount ? 'Edit' : 'Add'} business account`}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setBusinessAccount(null))
          setIsDrawerOpen(false)
        }}
        width='39%'
        destroyOnClose
      >
        <BusinessAccountFrom
          businessAccount={businessAccount}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Drawer>

    </div>
  );
}
export default ManageBusinessAccounts;