import React, { useEffect, useState } from "react";
import loginImage from "../../assets/img/login_header.png"
import { useNavigate, useLocation } from "react-router-dom"; // Added useLocation here
import { useDispatch, useSelector } from 'react-redux';
import { resetAdminFirstPassword, setResetOtpToken } from "../../auth/store/loginSlice"
import { validateEmail, isEmpty } from '../../helper'
import MetaTag from "../../components/metaTag";
import { Box, TextField, Button } from '@mui/material';
import PublicLayoutContentWrapper from "../../layout/publicLayout/PublicLayout";
import toast from "react-hot-toast";
import { setUserData } from '../../auth/store/userSlice';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const ResetAdminPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);

    const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowPasswordCheck = () => setShowPasswordCheck(!showPasswordCheck);


    const user = useSelector(({ auth }) => auth.user.data);
    useEffect(() => {
        if (user.id && user.hasChangedPassword) {
            navigate("/");
            return;
        }
    }, [dispatch, navigate, user])



    const handleChangePassword = () => {
        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return;
        }

        // Validate password contains at least one uppercase letter
        if (!/[A-Z]/.test(password)) {
            toast.error('Password must contain at least one uppercase letter');
            return;
        }

        // Validate password matches passwordCheck
        if (password !== passwordCheck) {
            toast.error('Passwords do not match');
            return;
        }

        dispatch(resetAdminFirstPassword({ oldPassword, password })).then((res) => {
            if (res?.payload?.error) {
                if (res?.payload?.error?.response?.data?.message) {
                    toast.error(res?.payload?.error.response.data.message);
                } else {
                    toast.error('Something went wrong, Please try again later');
                }
            }
            if (!res?.payload?.error) {
                toast.success('Password Changed Successfully');
                localStorage.removeItem("jwt_access_token")
                dispatch(setUserData({
                    name: '',
                    email: '',
                    id: '',
                    role: '',
                    imageUrl: '',
                    hasChangedPassword: false,
                }));
                navigate("/signin")
            }


        });
    }


    return (
        <div className="">
            <MetaTag />
            <PublicLayoutContentWrapper title="Change Password" description="Please enter recived Password " className="flex flex-col items-center" headerImage={loginImage} >
                <div className="flex flex-col   w-full  mx-auto text-start">
                    <Box
                        component="form"
                        className="flex flex-col gap-y-4 justify-center w-full  mx-auto  "
                        // sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            className="w-full"
                            name="oldPassword"
                            id="oldPassword"
                            label="Current Password"
                            variant="outlined"
                            required
                            placeholder="Enter Old Password"
                            onChange={(e) => setOldPassword(e.target.value)}
                            color="secondary"
                            type={showOldPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowOldPassword}
                                            edge="end"
                                        >
                                            {showOldPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        <TextField
                            className="w-full"
                            name="password"
                            id="password"
                            label="New Password"
                            variant="outlined"
                            required
                            placeholder="New Password"
                            onChange={(e) => setPassword(e.target.value)}
                            color="secondary"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        <TextField
                            className="w-full"
                            name="paswordCheck"
                            id="passwordCheck"
                            label="Confirm New Password"
                            variant="outlined"
                            required
                            placeholder="Confirm New Password"
                            onChange={(e) => setPasswordCheck(e.target.value)}
                            color="secondary"
                            type={showPasswordCheck ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordCheck}
                                            edge="end"
                                        >
                                            {showPasswordCheck ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        <Button
                            variant="contained"
                            className="mb-0"
                            sx={{ backgroundColor: '#24508d' }}
                            onClick={handleChangePassword}
                        >
                            Change Password
                        </Button>
                    </Box>
                    <div className="flex justify-between mt-0 font-semibold text-black w-full mx-auto ">
                        <div
                            className="mb-0"
                            style={{ cursor: 'pointer', color: 'black' }}
                            onClick={() => {
                                localStorage.removeItem("jwt_access_token")
                                dispatch(setUserData({
                                    name: '',
                                    email: '',
                                    id: '',
                                    role: '',
                                    imageUrl: '',
                                    hasChangedPassword: false,
                                }));
                                navigate("/signin")
                            }
                            }
                        >
                            Login
                        </div>
                    </div>

                </div>
            </PublicLayoutContentWrapper>

        </div>
    );
};

export default ResetAdminPassword;
