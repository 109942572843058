import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import axios from 'axios';  
import toast from 'react-hot-toast';
import moment from 'moment';
export default function NotificationsComponent({ open, setIsNotificationOpen }) {
  const [notifications , setNotifications] = useState([]);
  useEffect(()=>{
    async function getNotifications(){
     const response =await axios.get('/admin/notifications') 
     return response.data
    }
    if(open){
      getNotifications().then((data)=>{
        if(data.notifications){
          if(data.notifications.length === 0){
            toast('No notifications found') 
            return
          }
          setNotifications(data.notifications)
        }else{
          toast.error('Something went wrong')
        }
      })
    }
  },[open])
  return (
    <>
      <Drawer
        title="Notifications"
        placement="right"
        onClose={()=>setIsNotificationOpen(false)}
        open={open}
        closable={false}
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
      >
        <div className='p-8 '>
          {notifications.map((notification)=>{
            return (
              <div key={notification._id} className='border-b-2 border-gray-200 mb-4 pb-4'>
                <div>
          <p><span className='font-bold'>Business Account: </span>{notification?.data?.businessAccount}</p>
          <p><span className='font-bold'>Start At: </span>{moment(notification?.data?.startAt).format('DD-MM-YYYY, h:mm a')}</p>
          <p><span className='font-bold'>From: </span>{notification?.data?.fromPoint}</p>
          <p><span className='font-bold'>To: </span>{notification?.data?.toPoint}</p>
        </div>
              </div>
            )
          })}
        </div>
      </Drawer>
      <br />
    </>
  );
};