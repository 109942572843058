import { Header } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import React from "react";
import "./styles.css";
import { Avatar, Dropdown, Menu, Space } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import { ROUTE_NAME_LOOKUP } from "../../../utils/constants";
import {
  Button,
} from 'antd';
import {
  BellOutlined,
} from '@ant-design/icons';
import NotificationsComponent from '../notificationsComponent/NotificationsComponent';  



const CustomHeader = ({setIsNewNotification, isNewNotification}) => {
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);  
  const location = useLocation();
  const user = useSelector((state) => state.auth.user.data);
  const currentPathName = `/${location?.pathname.split('/')[1]}`;
  const handleLogout = () => {
    localStorage.removeItem("jwt_access_token");
    window.location.href = "/signin";
  };

  const handleClick = () => {
    // Define your onClick action here
    setIsNewNotification(false);
    setIsNotificationOpen(!isNotificationOpen); 
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="header-container">
      <div className=" text-base flex w-full items-center">
        <p>{ROUTE_NAME_LOOKUP[currentPathName]}</p>
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <div className="ml-auto profile-main-container" style={{ float: 'right', paddingRight: '10px', cursor: 'pointer' }}>
            <Space>
              <Avatar
                size={40}
                src={user.imageUrl ? user.imageUrl : "https://static.thenounproject.com/png/5100711-200.png"}
                icon={<UserOutlined />}
                shape="circle"
              />
              <p>{user.name}</p>
            </Space>
          </div>
        </Dropdown>
        <div className=" h-12 relative">
        <Button className={`!w-12 h-full ml-2 rounded-full` } icon={<BellOutlined />} onClick={handleClick} />
         {isNewNotification &&  <div className="bg-red-600 absolute  bottom-6 left-8 w-2 h-2 rounded-full"></div>}
        </div>
      </div>
      <div className=" flex gap-x-2">
      </div>
      <NotificationsComponent open={isNotificationOpen} setIsNotificationOpen={setIsNotificationOpen}/>
    </Header>
  );
};

export default CustomHeader;
