import React from 'react'
import moment from 'moment/moment'


import './DocCard.css'
export default function DocCard({doc , type}) {
    let cardData = {
        imgUrl:'',
        topLine:'',
        docType:'',
        date:'',
    }
    const typeLabels = {
        qatarWorkResidentFront: 'Work Resident Permit',
        drivingLicenseFront: 'Driving License',
    }
    console.log("doc  ==> ",doc)
    switch (type) {
        case 'vehicles':
            cardData = {
                imgUrl:doc.imageUrl,
                topLine:`${doc?.vehicleType?.make?.en || ''} ${doc?.vehicleType?.model?.en || ''} - ${doc.licensePlateNumber}`,
                docType:'Estimara',
                date:doc?.vehicleRegistration?.regExpiryDate,
                imgClass: 'vehicle-pic'
            }
            break;
            case 'drivers':
                cardData = {
                    imgUrl:doc?.document?.documentUrl || '',
                    topLine:`${doc?.firstName || ''} ${doc?.lastName || ''}`,
                    docType: typeLabels[doc?.document?.documentName] || '',
                    date:doc?.document?.expiryDate || '',
                    imgClass: 'driver-pic'
                }
                break;
                case 'businesses':
                    cardData = {
                        imgUrl:doc?.accoutLogoUrl,
                        topLine:doc?.name,
                        docType:doc?.business?.type || '',
                        date:doc?.business?.expireDate,
                        imgClass: 'vehicle-pic'
            }
            break;
        default:
            break;
    }
    return (
        <div className='card-container flex gap-x-12 justify-center items-center'>
            <div>
            <img src={cardData.imgUrl} alt="Document" className={` ${cardData.imgClass  || '' }`} />
            </div>
            <div className='flex flex-col gap-1.5'>
                <span className='top-line'>{cardData.topLine}</span>
                <span className='doc-type'>{cardData.docType}</span>
                <span className='card-date'>{moment(cardData.date).format('DD-MM-yyyy')}</span>
            </div>
        </div>
    )
}
