// import ImageName from "@/components/listings/image-name";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import { MoreOutlined , DeleteOutlined} from "@ant-design/icons";
import { Tooltip } from "antd";
import CustomPopconfirm from "../../../components/customPopconfirm/CustomPopconfirm";
import { updatePromotion , deletePromotion } from "../store/promotionsSlice"
import moment from "moment";


const PromotionsManagementTable = ({
  data,
  defaultSort,
  activeRowId,
  setPromotion,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };
  
  const columns = [
    {
      title: 'Promo Code',
      dataIndex: 'promoCode',
      sorter: false,
      width: 250,
      key: 'promoCode	',
      render: (text, promotion) => RenderColumnComponent(true)(promotion.promoCode),
    },
    {
      title: 'Business Name',
      dataIndex: 'promoCode',
      sorter: false,
      width: 300,
      key: 'promoCode	',
      render: (text, promotion) =>
      <div className="flex w-full items-center justify-start gap-x-4">
      <img src={promotion.businessAccountDetails.accoutLogoUrl} alt="logo" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
      <p>
        {`${promotion.businessAccountDetails.name}`}
      </p>
      </div>
      ,
    },
    {
      sorter: false,
      title: 'Discount %',
      dataIndex: 'discountPercentage',
      width: 125,
      key: 'discountPercentage',
      render: (text, promotion) => RenderColumnComponent(true)(`${promotion.discountPercentage}%`),
    },
    {
      sorter: false,
      title: 'Limit',
      dataIndex: 'limit',
      width: 100,
      key: 'limit',
      render: (text, promotion) => RenderColumnComponent(true)(promotion.usageLimit),
    },
    {
      sorter: false,
      title: 'Balance',
      dataIndex: 'balance',
      width: 100,
      key: 'balance',
      render: (text, promotion) => RenderColumnComponent(true)(promotion.usageLimit - promotion.timesUsed),
    },
    {
      sorter: false,
      title: 'Exp Date',
      dataIndex: 'expDate',
      width: 150,
      key: 'expDate',
      render: (text, promotion) => RenderColumnComponent(true)(moment(promotion.expirationDate).format('MM/DD/YYYY')),
    },
    {
      title: "Status", //"Active/Inactive",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, promotion) => {
        return (
          <CustomPopconfirm
            title={
              `Confirm ${promotion?.status === 'active' ? 'Inactive' : 'Activate'} this Promotion?`}
            onConfirm={() => dispatch(updatePromotion({ data: { ...promotion, status: promotion.status === 'active' ? 'inactive' : 'active' }, query }))}
            isChecked={promotion?.status === 'active'}
            autoState={true}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      align: "center",
      sorter: false,
      render: (_, record) => (
        <Tooltip className="flex  justify-center">
         <CustomPopconfirm
            title={
              `Are you sure you want to delete this promo code?`}
            onConfirm={() => dispatch(deletePromotion({ data: record, query }))}
            isChecked={record?.status === 'active'}
            autoState={true}
          >
             <DeleteOutlined style={{ fontSize: "18px", marginRight: "8px"}} />
           </CustomPopconfirm>
          <div
            className=" cursor-pointer underline text-primary text-primary-dark"
            onClick={() => {
              dispatch(setPromotion(record))
              setIsDrawerOpen(true)
            }}
          >
            <MoreOutlined style={{ fontSize: "18px", marginRight: "8px" }} />
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <CustomTable
      data={data?.content}
      elementsName='Admin Users'
      columns={
        columns?.map((column) => ({
          ...column,
          ...((column).dataIndex !== "status" &&
            (column).sorter !== false && {
            ...columnSortProps((column).dataIndex),
          }),
        }))
      }
      scrollPosition={IS_SUPERVISOR ? 27 : 21}
      scroll={true}
      pagination={true}
      totalRecords={data?.totalRecords}
      activeRowId={activeRowId}
      query={query}
      setQuery={setQuery}
    />
  );
};

export default PromotionsManagementTable;
