export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export function getImageUrl(id) {
  return `https://lh3.googleusercontent.com/d/${id}=l100`
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "string" && !value.trim().length) ||
  (typeof value === "object" && !Object.keys(value).length);