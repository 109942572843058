import React ,{useState } from "react";
import { useSelector } from "react-redux";
import DayTripsPop from "./DayTripsPop";

const getCurrentMonthDatesUTC = (date) => {
  const currentDate = new Date(date.startDate);
  const year = currentDate.getUTCFullYear();
  const month = currentDate.getUTCMonth();

  // Get the number of days in the current month
  const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

  // Generate a list of dates for the current month in UTC format
  const dates = Array.from({ length: daysInMonth }, (_, i) => {
    const date = new Date(Date.UTC(year, month, i + 1));
    return date.toISOString().split('T')[0];
  });

  return dates;
};

export default function CalendarComponent({ date, query }) {
  const tripsCount = useSelector(state => state.dashboard.dashboard.tripsCount)
  const monthDates = getCurrentMonthDatesUTC(date)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tripsDate , setTripsDate] = useState(null) 

  return (
    <div className="calendar-component">
      {
        monthDates.map((number, index) => {

          return (
            <div
              className='day-card'
              key={index}
              onClick={() => {
                setIsModalOpen(true)
                setTripsDate(number)
              }}
              style={{ cursor: 'pointer' }} // Optional: to indicate that the div is clickable
            >
              <p className='day-number'>{+number.split('-')[2]}</p>
              <p className='trip-text'>
                {tripsCount.find(e => e.date === number) ? `${tripsCount.find(e => e.date === number).count} Trips` : ''}
              </p>
            </div>
          )
        })
      }
<DayTripsPop query={query} isModalOpen={isModalOpen} tripsDate={tripsDate}  setIsModalOpen={setIsModalOpen} setTripsDate={setTripsDate}/>
    </div>
  )
}

