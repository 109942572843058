import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Input, Table } from 'antd';
import { setPricesPopup, getPriceList, updatePriceList, getZones, setPriceslist } from '../../store/businessAccountSlice';
import Loader from '../../../../components/loader';
import { defaultTripPrice } from '../../../../utils/constants';
import './style.css';

const PricePop = ({ accountId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { pricesPopup } = useSelector((state) => state.businessAccount.businessAccount);
  const { pricesList } = useSelector((state) => state.businessAccount.businessAccount);
  const { zonesList } = useSelector((state) => state.businessAccount.businessAccount);
  const { isModalOpen, data } = pricesPopup;
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (isModalOpen) {
      dispatch(getZones({}));
      dispatch(getPriceList({ businessID: accountId, vehicleTypeId: pricesPopup.data.id }));
    }
    setIsLoading(false);
  }, [data, dispatch, isModalOpen, accountId, pricesPopup.data.id]);

  const generateFormValues = (data) => {
    if (!data || data.length === 0) {
      return zonesList.reduce((acc, zoneFrom) => {
        const zoneFromId = zoneFrom.id;
        zonesList.forEach((zoneTo) => {
          const zoneToId = zoneTo.id;
          acc[`${zoneFromId}-${zoneToId}`] = defaultTripPrice;
        });
        return acc;
      }, {});
    }
    return data[0].zonePricing.reduce((acc, curr) => {
      acc[`${curr.from}-${curr.to}`] = curr.price;
      return acc;
    }, {});
  };

  const prepareDataSource = (data) => {
    const defaultPrices = zonesList.flatMap((zoneFrom) =>
      zonesList.map((zoneTo) => ({
        from: zoneFrom.id,
        to: zoneTo.id,
        price: defaultTripPrice,
        currency: 'QAR',
        id: `${zoneFrom.id}-${zoneTo.id}`,
      }))
    );

    let pricesData = null;
    if (data.length > 0) {
      pricesData = data[0].zonePricing.reduce((acc, curr) => {
        acc.push({ ...curr, id: `${curr.from}-${curr.to}` });
        return acc;
      }, []);
    } else {
      pricesData = defaultPrices;
    }

    const tableData = zonesList.map((zoneFrom) => ({
      key: zoneFrom.id,
      id: zoneFrom.id,
      name: zoneFrom.name,
      ...Object.fromEntries(
        zonesList.map((zoneTo) => [
          zoneTo.id,
          pricesData.find((price) => price.from === zoneFrom.id && price.to === zoneTo.id)?.price,
        ])
      ),
    }));
    return JSON.parse(JSON.stringify(tableData));
  };

  useEffect(() => {
    if (isModalOpen) {
      const pricesData = prepareDataSource(pricesList);
      const dataList = generateFormValues(pricesList);
      form.setFieldsValue(dataList);
      setDataSource(pricesData);
    }
  }, [isModalOpen, zonesList, pricesList]);

  const handleOk = (values) => {
    const formattedZonePricing = Object.entries(values).map(([key, price]) => {
      const [from, to] = key.split('-');
      return {
        from,
        to,
        price: parseFloat(price) || 0,
        currency: 'QAR',
      };
    });

    dispatch(
      updatePriceList({
        businessID: accountId,
        data: { vehicleTypeId: pricesPopup.data.id, zonePricing: formattedZonePricing },
      })
    ).then(() => {
      dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
      dispatch(setPriceslist([]));
      form.resetFields();
    });
  };

  const handleCancel = () => {
    dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
    form.resetFields();
    dispatch(setPriceslist([]));
  };

  const renderColumns = () => {
    return zonesList.map((zoneTo) => ({
      title: zoneTo.name,
      dataIndex: zoneTo.id,
      key: zoneTo.id,
      render: (value, record) => {
        const fieldName = `${record.id}-${zoneTo.id}`;
        return (
          <Form.Item
            name={fieldName}
            initialValue={value || defaultTripPrice}
            style={{ marginBottom: 0 }}
          >
            <Input
              addonAfter="QAR"
              variant="borderless"
              style={{
                textAlign: 'center',
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minWidth: '100px',
              }}
            />
          </Form.Item>
        );
      },
    }));
  };

  return (
    <Modal
      title={`${data?.make?.en} ${data?.model?.en}`}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="price-modal"
      width="90vw!important"
    >
      {isLoading || !zonesList ? (
        <Loader />
      ) : (
        <>
          <Form form={form} onFinish={handleOk}>
            <Table
              columns={[
                { title: 'From/To', dataIndex: 'name', key: 'name', className: 'bg-[#f4f7fc]' },
                ...renderColumns(),
              ]}
              dataSource={dataSource}
              pagination={false}
              bordered
            />
            <div className="flex justify-end mt-4">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default PricePop;