import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import React, { useRef, useState } from 'react';
import AdminUsers from "./pages/manageAdminUsers/AdminUsers";
import Home from "./pages/Home/Home";
import ManageDrivers from './pages/manageDrivers/ManageDrivers'
import ManageVehicles from "./pages/manageVehicles/ManageVehicles";
import SignIn from "./pages/Authentication/SignIn";
import OTP from "./pages/Authentication/OTP";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ResetAdminPassword from "./pages/Authentication/ResetAdminPassword";
import ForgorPassword from "./pages/Authentication/ForgotPassword";
import ManageTrips from './pages/manageTrips/ManageTrips'
import Dashboard from './pages/dashboard/Dashboard'
import ManageBusinessAccounts from './pages/manageBusinessAccounts/ManageBusinessAccounts'
import CorporateBusinessAccounts from './pages/manageCorporateAccounts/ManageCorporateAccounts'
import CorporateAccountPriceList from './pages/manageCorporateAccounts/subPages/CorporateAccountPriceList'
import BusinessAccountPriceList from './pages/manageBusinessAccounts/subPages/BusinessAccountPriceList'
import BusinessDashboard from './pages/manageBusinessAccounts/subPages/BusinessDashboard'
import Zone from './pages/manageZones/ManageZones'
import Promotions from "./pages/managePromotions/Promotions";
import Alerts from "./pages/manageAlerts/ManageAlerts";
// import store from './store';
import { Auth } from './auth';
// import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';
import AdminLayout from "./layout/adminLayout/AdminLayout";
import { ConfigProvider } from 'antd';
import { Toaster, toast } from 'react-hot-toast';
import { useToasterStore } from 'react-hot-toast';
import NotificationsComponent from './components/notificationComponent/NotificaitonComponent'  

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCw0ImWK1BKtSJOGgc0S7vPfj6WTWAhj24',
  authDomain: 'sawa-dev-43954.firebaseapp.com',
  projectId: 'sawa-dev-43954',
  storageBucket: 'sawa-dev-43954.firebasestorage.app',
  messagingSenderId: '657902914505',
  appId: '1:657902914505:web:002df6ffac6d1aa6aff618',
  measurementId: 'G-BJSK76J35K',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    getToken(messaging, {
      vapidKey:
        'BLPgTWhRj9Lh6YwTPkf52DJhuW-mH8TZjXGq9aPKjfVRr-6DNVlzJR25fdD-vBTjogE35EluLA_Gw1qSfe5qKNY',
    })
      .then((currentToken) => {
        localStorage.setItem("firebaseToken", currentToken)
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }
});



function App() {
  const [isNewNotification, setIsNewNotification] = useState(false);  
  const helmetContext = {};
  const { toasts } = useToasterStore();
  if (toasts.length > 2) {
    toast.remove(toasts[0].id);
  }
  const notificationRef = useRef();
  const [notificationData, setNotificationData] = useState({ data: null, isActive: false });

  onMessage(messaging, (payload) => {
    setNotificationData({ data: payload, isActive: true });
    if (notificationRef.current) {
      setIsNewNotification(true);
      notificationRef.current.openNotification();
    }
  });

  return (
    <>
      <Toaster limit={1} />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemActiveColor: '#7e22cd',
            }
          },
          Switch: {
            colorPrimary: '#7e22cd'/* here is your component tokens */
          },
          // token: {
          //   colorPrimary: '24508d',
          // },
        }}
      >


        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      <NotificationsComponent ref={notificationRef} notificationData={notificationData} />
      <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <Auth>

              <Routes>
                <Route element={<AdminLayout setIsNewNotification={setIsNewNotification} isNewNotification={isNewNotification}/>}>
                  <Route path="/" element={<Home />} />
                  <Route path="/admin-users" element={<AdminUsers />} />
                  <Route path="/manage-drivers" element={<ManageDrivers />} />
                  <Route path="/manage-vehicles" element={<ManageVehicles />} />
                  <Route path="/manage-trips" element={<ManageTrips />} />
                  <Route path="/manage-business-accounts/:accountId" element={<BusinessAccountPriceList />} />
                  <Route path="/manage-business-accounts/dashboard/:accountId" element={<BusinessDashboard />} />
                  <Route path="/manage-business-accounts" element={<ManageBusinessAccounts />} />
                  <Route path="/manage-corporate-accounts/:accountId" element={<CorporateAccountPriceList />} />
                  <Route path="/manage-corporate-accounts" element={<CorporateBusinessAccounts />} />
                  <Route path="/manage-zones" element={<Zone />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/manage-promotions" element={<Promotions />} />
                  <Route path="/manage-alerts" element={<Alerts />} />

                </Route>


                <Route path="/signIn" element={<SignIn />} />
                <Route path="/forgotpassword" element={<ForgorPassword />} />
                <Route path="/verify-otp" element={<OTP />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-admin-password" element={<ResetAdminPassword />} />

                {/* <Route key="def" path="*" element={<Navigate to="/" />} /> */}
              </Routes>
            </Auth>
            {/* <Footer /> */}
          </BrowserRouter>
        </HelmetProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
