import React from 'react'
import { useSelector } from 'react-redux'
import CustomFilter from '../../../components/customFilter/CustomFilter'

export default function Filters({ query, setQuery }) {
    const types = useSelector((state) => state.vehicle.vehicle.types);
    const generateYearOptions = (startYear) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = startYear; year <= currentYear + 1; year++) {
            years.unshift({ value: year.toString(), label: year.toString() });
        }
        return years;
    };
    const generateTypeOptions = (types) => {
   
       return types.map(type => {
            return { value: type.id, label:`${type.make.en}-${type.model.en}` }
       })
    };

    const statusList = [
        { value: '', label: 'All' },
        { value: true, label: 'Available' },
        { value: false, label: 'Booked' },
    ]
    
    const yearList = [
        { value: '', label: 'All' },
        ...generateYearOptions(2010)
    ]
    const typeList = [
        { value: '', label: 'All' },
        ...generateTypeOptions(types)
    ]

    return (
        <div className='flex'>
            <CustomFilter
                title='Status'
                name='isAvailable'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Year'
                name='manufacturedate'
                list={yearList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Type'
                name='vehicleTypeId'
                list={typeList}
                query={query}
                setQuery={setQuery}
            />

        </div>
    )
}
