import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';

const calculateDates = () => {
  const currentDate = new Date();

  // Set start date to the first day of the current month
  const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
  startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC

  // Set end date to the last day of the current month
  const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

  return {
      startDefaultDate: startDefaultDate.toISOString().split('T')[0],
      endDefaultDate: endDefaultDate.toISOString().split('T')[0]
  };
};
const defaultDates = calculateDates();


export const getZones = createAsyncThunk(
  'dashboardApp/getZones',
  async (query, { dispatch }) => {
    const response = await axios.get('/zone'
      , {
      params:query
      }

    );
    await dispatch(setTotalResults(response.data.totle));
    await dispatch(setZones(response.data.zones));
    return response.data;
  }
);
export const saveZone = createAsyncThunk(
  'dashboardApp/saveZone',
  async (data, { dispatch }) => {
    const response = await axios.post('/zone', data
 
    );
    await dispatch(getZones());
    return response.data;
  }
);
export const getTypes = createAsyncThunk(
  'dashboardApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type' );
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);
export const getDashData = createAsyncThunk(
  'dashboardApp/getDashData',
  async ({query}, { dispatch }) => {
    const response = await axios.get('/admin/dashboard',
      {
        params: {startAt : query.startDate, endAt: query.endDate},
      }
     );
    await dispatch(setDashData(response.data.result));
    await dispatch(setPieChartData({
      canceledTrips: response.data.result.canceledTrips,
      completedTrips: response.data.result.completedTrips,  
    }));
    return response.data;
  }
);

export const updateZone = createAsyncThunk(
  'dashboardApp/updateZone',
  async ({zoneId, data}, { dispatch }) => {
    const response = await axios.put(`/zone/${zoneId}`, data
    );
    await dispatch(getZones());
    return response.data;
  }
);
export const deleteVehicle = createAsyncThunk(
  'dashboardApp/deleteVehicle',
  async ({vehicleId}, { dispatch }) => {
    const response = await axios.delete(`/vehicle/${vehicleId}`);
    await dispatch(getZones());
    return response.data;
  }
);
export const approveDriver = createAsyncThunk(
  'dashboardApp/approveDriver',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/approveDriver', data
      //, {
      // params: {
      //   page: page,
      //   limit,
      //   userId,
      // },
      // }
    );
    await dispatch(getZones());
    return response.data;
  }
);
export const getTrips = createAsyncThunk(
  'dashboardApp/getTrips',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/calendar/dayView', data
    );
    dispatch(setTrips(response.data.trips));
    return response.data;
  }
);
export const getTripCount = createAsyncThunk(
  'dashboardApp/getTrips',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/calendar/count', data
    );
    dispatch(setTripsCount(response.data.tripsCount));
    return response.data;
  }
);

export const getBusinessAccounts = createAsyncThunk(
  'dashboardApp/getBusinessAccounts',
  async (query, { dispatch }) => {
    const response = await axios.get('/business'
      , {
        params: {
          limit: 100000,
          page: 0,
          orderBy: 'name',
          orderDirection: 'descend',
        }
      }

    );
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data;
  }
);

export const checkAvailability = createAsyncThunk(
  'dashboardApp/checkAvailability',
  async ({startDate,endDate,driverId ,vehicleId}, { dispatch }) => {
    try {
      const response = await axios.post('/admin/checkAvailability',
        {startDate,endDate,driverId, vehicleId}
);
      return response.data;
      
    } catch (error) {
        console.log("error",error);
        return error
    }
  }
);


export const getDrivers = createAsyncThunk(
  'dashboardApp/getDrivers',
  async (query, { dispatch }) => {
    const response = await axios.get('/admin/drivers'
      , {
        params: query,
      }

    );
    await dispatch(setDrivers(response.data.drivers));
    return response.data;
  }
);

export const getVehicles = createAsyncThunk(
  'dashboardApp/getVehicles',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle'
      , {
        params: query
      }

    );
    await dispatch(setVehicles(response.data.vehicles));
    return response.data;
  }
);
export const getPieChartData = createAsyncThunk(
  'dashboardApp/getVehicles',
  async (query, { dispatch }) => {
    try {
      const response = await axios.get('/trip/pieChartData'
        , {
          params: query
        }
  
      );
      await dispatch(setPieChartData(response.data.trips));
      return response.data.result;
    } catch (error) {
      await dispatch(setPieChartData(null));
      return error
    }
  }
);
export const getCities = createAsyncThunk(
  'dashboardApp/getCities',
  async (data, { dispatch }) => {
    const response = await axios.get('/public/cities/'
    );
    await dispatch(setCities(response.data));
    return response.data;
  }
);


export const getTripsDetails = createAsyncThunk(
  'dashboardApp/getTripsDetails',
  async (query, { dispatch }) => {
    const response = await axios.get('/trip/adminList'
      , {
        params: {
          ...query,
          page: query.page,
        }
      }

    );
    await dispatch(setTotalDayTripsResults(response.data.total));
    await dispatch(setTripsDetailsTrips(response.data.trips));
    return response.data;
  }
);
const dashboardSlice = createSlice({
  name: 'dashboardApp',
  initialState: {
    totalResults: 0,
    tripsDetailsTrips:[],
    totalDayTripsResults: 0,
    zone: null,
    data: [],
    trips:[],
    tripsCount:[],
    types : [], 
    zones: [],
    cities: [],
    pieChartData: {
      data: null,
      query: {
        startDate: defaultDates.startDefaultDate,
        endDate: defaultDates.endDefaultDate,
      }
    },  
    dashData:null,
    query: {
      page: 0,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'desc',
      startDate: defaultDates.startDefaultDate,
      endDate: defaultDates.endDefaultDate,
    }
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setTotalDayTripsResults: {
      reducer: (state, action) => {

        state.totalDayTripsResults = action.payload ? action.payload : 0;
      },
      prepare: totalDayTripsResults => ({ payload: totalDayTripsResults || '' }),
    },
    setDrivers: {
      reducer: (state, action) => {
        state.drivers = action.payload ? action.payload : 0;
      },
      prepare: drivers => ({ payload: drivers || [] }),
    },
    setVehicles: {
      reducer: (state, action) => {
        state.vehicles = action.payload ? action.payload : 0;
      },
      prepare: vehicles => ({ payload: vehicles || [] }),
    },
    setDashData: {
      reducer: (state, action) => {
        state.dashData = action.payload ? action.payload : 0;
      },
      prepare: dashData => ({ payload: dashData || [] }),
    },
    setBusinessAccounts: {
      reducer: (state, action) => {
        state.businessAccounts = action.payload ? action.payload : 0;
      },
      prepare: businessAccounts => ({ payload: businessAccounts || [] }),
    },
    setZone: {
      reducer: (state, action) => {

        state.zone = action.payload ? action.payload : null;
      },
      prepare: zone => ({ payload: zone || null }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 0,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'desc'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 0,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
    setCities: {
      reducer: (state, action) => {

        state.cities = action.payload ? action.payload : 0;
      },
      prepare: cities => ({ payload: cities || [] }),
    },
    setTrips: {
      reducer: (state, action) => {

        state.trips = action.payload ? action.payload : 0;
      },
      prepare: trips => ({ payload: trips || [] }),
    },
    setTypes: {
      reducer: (state, action) => {

        state.types = action.payload ? action.payload : 0;
      },
      prepare: types => ({ payload: types || [] }),
    },
    setTripsDetailsTrips: {
      reducer: (state, action) => {

        state.tripsDetailsTrips = action.payload ? action.payload : 0;
      },
      prepare: tripsDetailsTrips => ({ payload: tripsDetailsTrips || [] }),
    },
    setZones: {
      reducer: (state, action) => {
        state.zones = action.payload ? action.payload : 0;
      },
      prepare: zones => ({ payload: zones || [] }),
    },
    setTripsCount: {
      reducer: (state, action) => {
        state.tripsCount = action.payload ? action.payload : 0;
      },
      prepare: tripsCount => ({ payload: tripsCount || [] }),
    },
    setPieChartData: {
      reducer: (state, action) => {
        state.pieChartData = action.payload ? {
          ...state.pieChartData, data: action.payload
        } : 
        {
          ...state.pieChartData, data:null
        } ;
      },
      prepare: pieChartData => ({ payload: pieChartData || null }),
    },
    setPieChartQuery: {
      reducer: (state, action) => {
        state.pieChartData = action.payload ? {
          ...state.pieChartData, query: action.payload
        } : 
        {
          ...state.pieChartData,
          query: {
            startDate: defaultDates.startDefaultDate,
            endDate: defaultDates.endDefaultDate,
          }
        } ;
      },
      prepare: pieChartData => ({ payload: pieChartData || [] }),
    },
  },

 
});

export const {
  setTotalResults,
  setZones,
  setCities,
  setQuery,
  setZone,
  setTypes,
  setTrips,
  setTripsCount,
  setDrivers,
  setVehicles,
  setBusinessAccounts,
  setTripsDetailsTrips,
  setTotalDayTripsResults,
  setDashData,
  setPieChartData,
  setPieChartQuery,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

