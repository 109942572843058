import { Menu } from "antd";
import { useMemo, useState } from "react";
import {useLocation, NavLink } from "react-router-dom";
import { sawaLogo } from "../../../assets/img/index";
import "./styles.css";
import Sider from "antd/es/layout/Sider";
import { getMenusByUserType } from '../../../utils/menuMap';
import { isEmpty } from '../../../utils/functions'
import { useSelector } from "react-redux";

const CustomSideBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {role} = useSelector(state => state.user)
  const location = useLocation();

  const menuListByUserType = useMemo(
    () => getMenusByUserType(role).filter((menu) => !isEmpty(menu?.title)),
    [role],
  );


  return (
    <Sider
      className="side-bar-container"
      // collapsible
      width={'fit-content'}
      onBreakpoint={(broken) => {
      }}
      onCollapse={(collapsed, type) => {
        setIsMenuOpen(!collapsed)
      }}
    >
      <div className="w-full flex  company-logo">
        <img src={sawaLogo} className="m-auto " alt="" />
      </div>
      <Menu
         selectedKeys={[location.pathname]}
        mode="inline"
        // inlineCollapsed={false}
        className="menu-container "
      >
        {menuListByUserType.map(
          ({ route, title, icon , svg }, index) => (
            <Menu.Item key={route} className="" label='' >
              <NavLink to={route} onClick={(value) => () => console.log("click ")}>
                <div className="menu-item-wrapper ">
                    <img src={svg || icon} className="menu-item-svg-icon" alt="" />
                  <p className="menu-title-text">
                    {title}
                  </p>
                </div>
              </NavLink>
            </Menu.Item>

          ),
        )}
      </Menu>
    </Sider>
  );
};

export default CustomSideBar;
