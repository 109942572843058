import React from "react";


const AdminLayoutContentWrapper = ({
  title,
  rightLayout = undefined,
  children,
  className = ``,
}) => {
  return (
    <section
      className={`overflow-hidden pb-4 h-full bg-white shadow-main-content rounded-layout ${className}`}
    >
      <div className={`flex justify-between items-center ${title && "mb-4"}`}>
        <div className="ml-8 text-primary-dark  text-base font-semibold ">
          {title}
        </div>
        {rightLayout}
      </div>
      <div className="content-wrapper">{children}</div>
    </section>
  );
};

export default AdminLayoutContentWrapper;
