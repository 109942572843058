import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Upload,
  Select,
  DatePicker,
} from 'antd';
import { isEmail } from '../../../utils/functions';
import { getCities, saveDriver, approveDriver, updateDriver } from '../store/driverSlice';
import axios from 'axios';
import { CameraOutlined, UploadOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import CameraCapture from '../../../components/CameraCapture/CameraCapture'


export default function DriverForm({ driver, setIsDrawerOpen, query }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = React.useState('');
  const [documents, setDocuments] = React.useState({
    'profilePicture': {
      documentType: 'profilePicture',
      documentName: 'profilePicture',
      documentUrl: '',
      expiryDate: null,
      status: 'inReview'
    },
    'qatarWorkResidentFront': {
      documentType: 'qatarWorkResidentFront',
      documentName: 'qatarWorkResidentFront',
      documentUrl: '',
      expiryDate: null,
      status: 'inReview'
    },
    'qatarWorkResidentBack': {
      documentType: 'qatarWorkResidentBack',
      documentName: 'qatarWorkResidentBack',
      documentUrl: '',
      expiryDate: null,
      status: 'inReview'
    },
    'drivingLicenseFront': {
      documentType: 'drivingLicenseFront',
      documentName: 'drivingLicenseFront',
      documentUrl: '',
      expiryDate: null,
      status: 'inReview'
    },
    'drivingLicenseBack': {
      documentType: 'drivingLicenseBack',
      documentName: 'drivingLicenseBack',
      documentUrl: '',
      expiryDate: null,
      status: 'inReview'
    },
  });
  const [form] = Form.useForm();
  const [docs] = Form.useForm();

  useEffect(() => {
    dispatch(getCities())
    if (driver) {
      form.setFieldsValue({ ...driver, mobileNumber: driver.mobileNumber.replace('+974', '') });
      setImageUrl(driver.profileImageUrl || '');
      setDocuments(driver.documents.reduce((acc, doc) => ({ ...acc, [doc.documentType]: doc }), {}));
      let dates = driver.documents.reduce((acc, doc) => {
        return { ...acc, [`expiryDate_${doc.documentType}`]:doc.expiryDate ?  moment(doc.expiryDate)  : '' }
      }, {})
      dates['status_0'] = driver.documents.find(e => e.documentType === 'qatarWorkResidentFront')?.status || ''
      dates['status_3'] = driver.documents.find(e => e.documentType === 'drivingLicenseBack')?.status || ''
      docs.setFieldsValue(dates);
    } else {
      form.setFieldsValue({})
      docs.setFieldsValue({})
    }
  }, [dispatch, docs, driver, form]);

  function driverValidation(Obj, passwordRequired = true) {
    if (!Obj.firstName || !Obj.lastName || !Obj.email || !Obj.mobileNumber || (passwordRequired && !Obj.password)) {
      toast.error('please fill required fields');
      setIsLoading(false);
      return false;
    }

    if (!isEmail(Obj.email)) {
      toast.error('please enter a valid email');
      setIsLoading(false);
      return false;
    }
    if (!/^\+974[0-9]+$/.test(Obj.mobileNumber)) {
      toast.error('please enter a valid mobile number');
      setIsLoading(false);
      return false;
    }
    if (passwordRequired && (Obj.password.length <= 8 || !/[A-Z]/.test(Obj.password))) {
      toast.error('password must be at least 8 characters and an uppercase letter');
      setIsLoading(false);
      return false;
    }
   
    if(Obj.documents.length > 0){
      const reqDocs = Obj.documents.filter(e=> e.documentType !== 'profilePicture')
      if (reqDocs && reqDocs.length < 4) {
        toast.error('upload all required documents');
        setIsLoading(false);
      }
      if(reqDocs.some(e=> e.expiryDate === null || e.expiryDate === undefined)){
        toast.error('Must fill all expiry dates');
        setIsLoading(false);
      }
      const statusList = reqDocs.map(e=> e.status)  
      if(!statusList.every(e=> e === 'approved')){
        toast.error('please approve all documents');
        setIsLoading(false);
        return false;

      }
    }
    return true;

  }
  const cities = useSelector((state) => state.driver.driver.cities);

  const onSaveDriver = (values) => {
    setIsLoading(true);
    let driverObj = { ...form.getFieldsValue(), profileImageUrl: imageUrl, documents: Object.values(documents), status: 'approved', mobileNumber: `+974${form.getFieldsValue().mobileNumber}` }
    if (!driverValidation(driverObj)) return;
    dispatch(saveDriver({ ...form.getFieldsValue(), mobileNumber: `+974${form.getFieldsValue().mobileNumber}`, profileImageUrl: imageUrl, documents: Object.values(documents), status: 'approved' })).then(e => {
      if (!e.payload.error) {
        toast.success('Driver created successfully');
        setIsDrawerOpen(false);
      } else {
        toast.error('An error occurred');
      }
    }).then(e => setIsLoading(false));
  };
  const onApproveAction = () => {
    setIsLoading(true);
    let driverObj = { ...form.getFieldsValue(), profileImageUrl: imageUrl, documents: Object.values(documents), status: 'approved', mobileNumber: `+974${form.getFieldsValue().mobileNumber}` }

    if (!driverValidation(driverObj, false)) return;

    if (['inReview', 'rejected'].includes(driver.status)) {
      const docs = Object.values(documents).filter(e => ["qatarWorkResidentFront", 'drivingLicenseBack'].includes(e.documentType))
      if (!docs.every(doc => doc.status === 'approved')) {
        toast.error('please approve all documents');
        setIsLoading(false);
        return;
      }
      dispatch(updateDriver({
        data: {
          ...form.getFieldsValue(), mobileNumber: `+974${form.getFieldsValue().mobileNumber}`, status: 'approved', id: driver._id, documents: Object.values(documents).map(doc => {
            return { ...doc, status: 'approved' }
          })
        }, query
      }))
        .then(e => {
          if (!e.payload.error) {
            toast.success('Driver updated successfully');
            setIsDrawerOpen(false);
          } else {
            toast.error('An error occurred')
          }
        }
        )
        .then(e => setIsLoading(false));
    } else {
      dispatch(updateDriver({ data: { ...form.getFieldsValue(), mobileNumber: `+974${form.getFieldsValue().mobileNumber}`, id: driver._id, documents: Object.values(documents) }, query }))
        .then(e => {
          if (!e.payload.error) {
            toast.success('Driver updated successfully');
            setIsDrawerOpen(false);
          } else {
            toast.error('An error occurred');
          }
        })
        .then(e => setIsLoading(false));
    }
  }
  const onDisapprove = () => {
    dispatch(updateDriver({ data: { ...form.getFieldsValue(), mobileNumber: `+974${form.getFieldsValue().mobileNumber}`, id: driver._id, documents: Object.values(documents), status: 'rejected' }, query }))
      .then(e => {
        if (!e.payload.error) {
          toast.success('Driver updated successfully');
          setIsDrawerOpen(false);
        } else {
          toast.error('An error occurred');
        }
      })
      .then(e => setIsLoading(false));
    setIsDrawerOpen(false);

  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //form.setFieldsValue();
 const captureImage = async ({ file, name }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('admin/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("response", response.data);

            setDocuments({ ...documents, [name]: { ...documents[name], documentUrl: response.data.fileUrl } })
          } catch (error) {
            console.log("capture error", error);
        }
    };
  const customRequest = (name) => async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('admin/uploadDocument', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onSuccess();
      setDocuments({ ...documents, [name]: { ...documents[name], documentUrl: response.data.fileUrl } })
      // message.success('File uploaded successfully');
    } catch (error) {
      onError(error);
      // App.error('File upload failed');
    }
  };

  const DriverInfoForm = () =>

    <Form
      form={form}
      labelAlign='left'
      className='w-full flex flex-col justify-center gap-y-0 '
      name="Info"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 300,
      }}
      style={{
        maxWidth: 600,
      }}
      autoComplete="off"
      colon={false}
    // onFinish={onSaveAdmin}

    >
      <Form.Item
        hidden
        name="_id"
      >
        <Input />
      </Form.Item>
      <Form.Item
        className='p-0 m-2'
        label="First Name"
        name="firstName"
        rules={[{
          validator: (_, value) => {
            if (value !== '') {
              return Promise.resolve();
            }
            return Promise.reject(new Error('please fill first name'));
          },
        },
        ]}
      >
        <Input
        />
      </Form.Item>
      <Form.Item
        className='p-0 m-2'
        label="Last Name"
        name="lastName"
        rules={[{
          validator: (_, value) => {
            if (value !== '') {
              return Promise.resolve();
            }
            return Promise.reject(new Error('please fill last name'));
          },
        },
        ]}
      >
        <Input
        />
      </Form.Item>

      <Form.Item
        className='p-0 m-2'
        label="Email"
        name="email"
        rules={[{
          validator: (_, value) => {
            if (isEmail(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('must be a valied email'));
          },
        },
        ]}
      >
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
        className='p-0 m-2'
        label="Password"
        name="password"
        hidden={driver ? true : false}
        rules={[{
          validator: (_, value) => {
            if (value.length <= 8 || !/[A-Z]/.test(value)) {
              return Promise.reject(new Error('must be at least 8 characters and an uppercase letter'));
            }
            return Promise.resolve();
          },
        },
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        className='p-0 m-2'
        label="Phone Number"
        name="mobileNumber"
        rules={[{
          validator: (_, value) => {
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(new Error('must be at valid phone  Numbers only'));
            }
            return Promise.resolve();
          },
        },
        ]}
      >
        <Input addonBefore={
          <span className="text-sm font-medium">
            +974
          </span>
        } />
      </Form.Item>
      <Form.Item
        label="City"
        name='cityId'
        className='p-0 m-2'
      >
        <Select>
          {cities.map((city) => {
            return <Select.Option key={city.name} value={city.id}>{city.name}</Select.Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
      </Form.Item>
    </Form>


  const uploadProfilePic = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/admin/uploadDocument', formData);
      onSuccess(response.data);
      setImageUrl(response.data.fileUrl);
    } catch (error) {
      onError(error);
    }
  };
  const removeImg = () => {
    setDocuments({ ...documents, profilePicture: { ...documents.profilePicture, documentUrl: '' } });
  }
  const getName = (url) => {
    const regex = /[^/]+(?=\.[^.]+$)/;
    const result = url.match(regex);
    return result[0]?.length > 10 ? (result[0]?.substring(0, 10) + '...') : result[0];
  }
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  function removeImage(name) {

    setDocuments({
      ...documents,
      [name]: {
        ...documents[name],
        documentUrl: ''
      }
    });
  }
  return (
    <div className='flex flex-col'>
      <p className='form-subHeader'>Driver Basic Information</p>
      <div className=' flex w-full  px-8 pt-8 '>
        <div className=' flex w-full flex-col'>
          <div className='flex  gap-x-4'>

            <Form.Item label="" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                customRequest={customRequest('profilePicture')}
                listType="picture-circle"
                maxCount={1}
                fileList={documents['profilePicture']?.documentUrl ? [{ url: documents['profilePicture']?.documentUrl }] : []}
                onRemove={() => removeImg('profilePicture')}
              >
                {!documents['profilePicture']?.documentUrl && <button
                  style={{
                    // border: 0,
                    background: 'none',
                  }}
                  type="button"
                >
                  <CameraOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                  </div>
                </button>}
              </Upload>
            </Form.Item>
            <p className='w-3/4 text-center'>Please provide all the necessary details to add a new driver. Complete information ensures accurate record-keeping and smooth processing.</p>
          </div>
          <DriverInfoForm />
        </div>
      </div>
      <p className='form-subHeader'>Driver Information</p>
      <div className=' px-8 pt-8'>

        <Form
          form={docs}
          colon={false}
          labelAlign='left'
          className='w-full flex flex-col justify-center gap-y-0 '
          name="Info2"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 300,
          }}
          style={{
            maxWidth: 600,
          }}
          autoComplete="off"
        >
          <p className='font-bold	 text-lg mb-8	'>Work Permit</p>
          <div className="flex flex-wrap ">
            <div key={0} className="w-1/2 flex  flex-col items-center "  >
              <div className=' h-32'>

                {
                  documents['qatarWorkResidentFront']?.documentUrl ?
                    (
                      <Form.Item name={'qatarWorkResidentFront'} initialValue={documents['qatarWorkResidentFront']?.documentUrl} >
                        <div className='pt-0 mt-0 doc-link-cont' >
                          <a className='pt-0 flex flex-col justify-center items-center' href={documents['qatarWorkResidentFront']?.documentUrl} target="_blank" rel="noopener noreferrer" >
                            <CheckOutlined className='p-0 mr-2' />
                            <p>Front Uploaded</p>
                          </a>
                        </div>
                        <div className='flex justify-around mt-2'>
                          {getName(documents['qatarWorkResidentFront']?.documentUrl)}
                          <DeleteOutlined onClick={() => removeImage("qatarWorkResidentFront")} />
                        </div>
                      </Form.Item>

                    ) : (
                      <>
                      <Form.Item name={'qatarWorkResidentFront'} initialValue={documents['qatarWorkResidentFront']?.documentUrl} >
                        <Upload className='flex flex-col' customRequest={customRequest('qatarWorkResidentFront')}  >
                          <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Front</Button>
                        </Upload>
                        </Form.Item>
                        <CameraCapture
                          name='capture front side'
                          setFunction={captureImage}
                          attribute='qatarWorkResidentFront'
                        />
                      </>
                    )}
              </div>

            </div>
            <div key={1} className="w-1/2 flex  flex-col items-center  "  >
              <div className=' h-32'>

                {
                  documents['qatarWorkResidentBack']?.documentUrl ?
                    (
                      <Form.Item name={'qatarWorkResidentBack'} initialValue={documents['qatarWorkResidentBack']?.documentUrl} >
                        <div className='pt-0 mt-0 doc-link-cont' >
                          <a className='pt-0 flex flex-col justify-center items-center' href={documents['qatarWorkResidentBack']?.documentUrl} target="_blank" rel="noopener noreferrer" >
                            <CheckOutlined className='p-0 mr-2' />
                            <p>back Uploaded</p>
                          </a>
                        </div>
                        <div className='flex justify-around'>
                          {getName(documents['qatarWorkResidentBack']?.documentUrl)}
                          <DeleteOutlined onClick={() => removeImage("qatarWorkResidentBack")} />
                        </div>
                      </Form.Item>

                    ) : (
                      <>
                      <Form.Item name={'qatarWorkResidentBack'} initialValue={documents['qatarWorkResidentBack']?.documentUrl} >
                        <Upload className='flex flex-col' customRequest={customRequest('qatarWorkResidentBack')}  >
                          <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Back</Button>
                        </Upload>
                      </Form.Item>
                       <CameraCapture
                       name='capture back side'
                       setFunction={captureImage}
                       attribute='qatarWorkResidentBack'
                       />
                       </>
                    )}
              </div>
            </div>
          </div>
          {/* <div className='flex flex-col justify-between w-full gap-x-2 px-2 bg-red-300' > */}
          {/* {moment(documents['qatarWorkResidentFront']?.expiryDate)}   */}
          <Form.Item className='mt-8' label="Expiry Date" name={`expiryDate_${'qatarWorkResidentFront'}`} initialValue='' value={documents['qatarWorkResidentFront']?.expiryDate || ''}>
            <DatePicker
              disabledDate={disabledDate}

              //  value={documents['qatarWorkResidentFront']?.expiryDate ? moment(documents['qatarWorkResidentFront']?.expiryDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)') : ''}
              onChange={(date, dateString) => setDocuments(
                {
                  ...documents, 'qatarWorkResidentFront': { ...documents['qatarWorkResidentFront'], expiryDate: date ? date.toDate() : null },
                  'qatarWorkResidentBack': { ...documents['qatarWorkResidentBack'], expiryDate: date ? date.toDate() : null }
                }
              )}
              style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item className='' label="Status" name={`status_${0}`} initialValue={documents['qatarWorkResidentFront']?.status}>
            <Select
              onChange={(value) => setDocuments({ ...documents, 'qatarWorkResidentFront': { ...documents['qatarWorkResidentFront'], status: value }, 'qatarWorkResidentBack': { ...documents['qatarWorkResidentBack'], status: value } })}
            >
              <Select.Option value="inReview">In Review</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="expired">Expired</Select.Option>
            </Select>
          </Form.Item>
          {/* </div> */}
          <p className='font-bold	 text-lg mb-8 pt-8	' style={{ borderTop: '1px solid #d9d9d9' }}>Driving License</p>
          <div className="flex flex-wrap ">
            <div key={2} className="w-1/2 flex  flex-col items-center "  >
              <div className=' h-32'>

                {
                  documents['drivingLicenseFront']?.documentUrl ?
                    (
                      <Form.Item name={'drivingLicenseFront'} initialValue={documents['drivingLicenseFront']?.documentUrl} >
                        <div className='pt-0 mt-0 doc-link-cont' >
                          <a className='pt-0 flex flex-col justify-center items-center' href={documents['drivingLicenseFront']?.documentUrl} target="_blank" rel="noopener noreferrer" >
                            <CheckOutlined className='p-0 mr-2' />
                            <p>front Uploaded</p>
                          </a>
                        </div>
                        <div className='flex justify-around'>
                          {getName(documents['drivingLicenseFront']?.documentUrl)}
                          <DeleteOutlined onClick={() => removeImage("drivingLicenseFront")} />
                        </div>
                      </Form.Item>

                    ) : (
                      <>
                      <Form.Item name={'drivingLicenseFront'} initialValue={documents['drivingLicenseFront']?.documentUrl} >
                        <Upload className='flex flex-col' customRequest={customRequest('drivingLicenseFront')}  >
                          <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Back</Button>
                        </Upload>
                      </Form.Item>
                       <CameraCapture
                       name='capture front side'
                       setFunction={captureImage}
                       attribute='drivingLicenseFront'
                       />
                       </>
                    )}
              </div>

            </div>
            <div key={3} className="w-1/2 flex  flex-col items-center "  >
              <div className=' h-32'>

                {
                  documents['drivingLicenseBack']?.documentUrl ?
                    (
                      <Form.Item name={'drivingLicenseBack'} initialValue={documents['drivingLicenseBack']?.documentUrl} >
                        <div className='pt-0 mt-0 doc-link-cont' >
                          <a className='pt-0 flex flex-col justify-center items-center' href={documents['drivingLicenseBack']?.documentUrl} target="_blank" rel="noopener noreferrer" >
                            <CheckOutlined className='p-0 mr-2' />
                            <p>back Uploaded</p>
                          </a>
                        </div>
                        <div className='flex justify-around'>
                          {getName(documents['drivingLicenseBack']?.documentUrl)}
                          <DeleteOutlined onClick={() => removeImage("drivingLicenseBack")} />
                        </div>
                      </Form.Item>

                    ) : (
                      <>
                      <Form.Item name={'drivingLicenseBack'} initialValue={documents['drivingLicenseBack']?.documentUrl} >
                        <Upload className='flex flex-col' customRequest={customRequest('drivingLicenseBack')}  >
                          <Button className="ant-upload-doc flex flex-col" icon={<UploadOutlined />}>Upload Back</Button>
                        </Upload>
                      </Form.Item>
                      <CameraCapture
                      name='capture front side'
                      setFunction={captureImage}
                      attribute='drivingLicenseBack'
                      />
                      </>
                      
                    )}
              </div>
            </div>
            <div className='flex flex-col w-full gap-x-2 px-2 ' >
              {/* {moment(documents['drivingLicenseBack']?.expiryDate)}   */}
              <Form.Item className=' mt-8' label="Expiry Date" name={`expiryDate_${'drivingLicenseBack'}`} initialValue='' value={documents['drivingLicenseBack']?.expiryDate}>
                <DatePicker
                  disabledDate={disabledDate}

                  onChange={(date, dateString) => setDocuments({
                    ...documents, 'drivingLicenseBack': { ...documents['drivingLicenseBack'], expiryDate: date ? date.toDate() : null },
                    'drivingLicenseFront': { ...documents['drivingLicenseFront'], expiryDate: date ? date.toDate() : null }
                  })}
                  style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item className='' label="Status" name={`status_${3}`} initialValue={documents['drivingLicenseBack']?.status}>
                <Select
                  value={documents['drivingLicenseBack']?.status}
                  onChange={(value) => setDocuments({ ...documents, 'drivingLicenseBack': { ...documents['drivingLicenseBack'], status: value }, 'drivingLicenseFront': { ...documents['drivingLicenseFront'], status: value } })}
                >
                  <Select.Option value="inReview">In Review</Select.Option>
                  <Select.Option value="rejected">Rejected</Select.Option>
                  <Select.Option value="approved">Approved</Select.Option>
                  <Select.Option value="expired">Expired</Select.Option>
                </Select>
              </Form.Item>
            </div>





          </div>
          {driver ? (

            <div className='flex gap-x-2 justify-center'>
              <Form.Item>
                {driver.status !== 'approved' && <Button disabled={isLoading} onClick={onDisapprove} type="primary" htmlType="submit">
                  Reject
                </Button>}
              </Form.Item>
              <Form.Item >
                <Button disabled={isLoading} onClick={onApproveAction} type="primary" htmlType="submit">
                  {['inReview', 'rejected'].includes(driver.status) ? 'Approve' : 'Update'}
                </Button>
              </Form.Item>
            </div>

          ) : (
            <>
              <Form.Item name='create' className='flex justify-end '>
                <Button disabled={isLoading} onClick={onSaveDriver} type="primary" htmlType="submit">
                  Save And Approve
                </Button>
              </Form.Item>
            </>
          )
          }
        </Form>
      </div>
    </div>
  )
}
