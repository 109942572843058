import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import { MoreOutlined } from "@ant-design/icons";
import {deleteVehicle} from '../store/zoneSlice'

import { useDispatch } from "react-redux";

const ZonesManagementTable = ({
  data,
  handleEditVehicle,
  defaultSort,
  activeRowId,
  handleToggleVehicleStatus,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
  setZone
}) => {
  const dispatch = useDispatch();

  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };

  const handleDeleteVehicle = (vehicle) => {
    dispatch(deleteVehicle({vehicleId: vehicle._id}));
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: false,
      key: 'name',
      render: (text, zone) => <div className="flex gap-x-2">{RenderColumnComponent(true)(zone.name)}</div>,
    },
    {
      title: 'Number',
      dataIndex: 'number',
      sorter: false,
      key: 'number',
      render: (text, zone) => <div className="flex gap-x-2">{RenderColumnComponent(true)(zone.number || 'N/A')}</div>,
    },
 
    {
      title: "View/Edit",
      dataIndex: "edit",
      sorter: false,
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <MoreOutlined
              onClick={() => {
                setIsDrawerOpen(true)
                dispatch(setZone(record))
              }}
              style={{ fontSize: "18px", marginRight: "8px" }}
            />
          </>
        );
      },
    },


  ];

  return (
    <CustomTable
      data={data?.content}
      elementsName='Vehicles'
      query={query}
      setQuery={setQuery}
      columns={
        columns?.map((column) => ({
          ...column,
          ...((column).dataIndex !== "status" &&
            (column).sorter !== false && {
            ...columnSortProps((column).dataIndex),
          }),
        }))
      }
      scrollPosition={IS_SUPERVISOR ? 27 : 21}
      scroll={true}
      pagination={true}
      totalRecords={data?.totalRecords}
      activeRowId={activeRowId}
    />
  );
};

export default ZonesManagementTable;
