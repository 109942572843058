import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getAdminUsers = createAsyncThunk(
  'adminUserApp/getAdminUsers',
  async (query, { dispatch }) => {
    const response = await axios.get('admin/getAdminUsers', {
      params: {...query, page: query.page - 1},
    });
    dispatch(setAdminUsers(response.data.adminUsers));
    dispatch(setTotalResults(response.data.totalResults));
    return response.data.results;
  }
);
export const createAdmin = createAsyncThunk(
  'adminUserApp/createAdmin',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('admin/createAdmin', data);
      dispatch(getAdminUsers( {
        page: 1,
        limit: 10,
        search: '',
        orderBy: 'createdAt',
        orderDirection: 'ascending',
  
      }));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateAdmin = createAsyncThunk(
  'adminUserApp/createAdmin',
  async ({data,query}, { dispatch }) => {
    try {
      const response = await axios.patch(`admin/updateAdmin/${data._id}`, data);
      dispatch(getAdminUsers( query));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const setAdminStatus = createAsyncThunk(
  'adminUserApp/setAdminStatus',
  async ({data, query}, { dispatch }) => {
    return null
      // const response = await axios.put('/admin/driver', data
      // );  
      // await dispatch(getAdminUsers(query));      
      // return response.data;
  }

);


const adminUserSlice = createSlice({
  name: 'adminUserApp',
  initialState: {
    totalResults: 0,
    adminUsers: [],
    adminUser: null,
    query: {
      page: 1,
      limit: 10,
      search: '',
      orderBy: 'createdAt',
      orderDirection: 'ascending',

    },
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setAdminUsers: {
      reducer: (state, action) => {
        state.adminUsers = action.payload ? action.payload : [];
      },
      prepare: adminUsers => ({ payload: adminUsers || [] }),
    },
    setAdminUser: {
      reducer: (state, action) => {
        state.adminUser = action.payload ? action.payload : null;
      },
      prepare: adminUser => ({ payload: adminUser || null }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
  },

});

export const {
  setTotalResults,
  setAdminUsers,
  setQuery,
  setAdminUser,
} = adminUserSlice.actions;

export default adminUserSlice.reducer;

