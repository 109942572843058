import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import CustomPopconfirm from "../../../components/customPopconfirm/CustomPopconfirm";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import StatusComponent from "../../../components/StatusComponent/StatusComponent";
import StatusDropdown from "../../../components/statusDropdown/StatusDropdown";
import { deleteVehicle, updateVehicle } from '../store/vehicleSlice'

import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const VehiclesManagementTable = ({
  data,
  handleEditVehicle,
  defaultSort,
  activeRowId,
  handleToggleVehicleStatus,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
  setVehicle
}) => {
  const dispatch = useDispatch();

  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };
  const updateVehicleSeconderyStatus = (vehicle, data) => {
    dispatch(updateVehicle({ vehicleId: vehicle._id, data, query })).then(e => 
    {
      if(e.error){
        toast.error(e.error.message)
      }else {
        toast.success('secondery status updated successfully')
      } 
    }
    );
  };
  const handleDeleteVehicle = (vehicle) => {
    dispatch(deleteVehicle({ vehicleId: vehicle._id }));
  };
  const seconderyStatuses = [
    { value: 'available', label: 'Available' , color: '#2FB64A'},
    { value: 'outOfService', label: 'Out of Service'  , color: '#C63333'},
    { value: 'needMaintenance', label: 'Maintenance Required' , color: '#F6A244'},
    { value: 'sold', label: 'Sold' , color: '#808080'}, 
    { value: 'nonRevenueTransfer', label: 'No-Revenue Transfer' , color: '#1E70AF'}, 
  ];
  const columns = [
    {
      title: 'Plate Number',
      dataIndex: 'plateNumber',
      key: 'plateNumber',
      sorter: false,
      render: (text, vehicle) => <div className="flex gap-x-2">{RenderColumnComponent(true)(vehicle.licensePlateNumber)}</div>,
    },
    {
      title: 'Make/Model',
      dataIndex: 'make',
      key: 'make',
      sorter: false,
      render: (text, vehicle) => RenderColumnComponent(true)(vehicle.typeDetails?.make?.en + ' / ' + vehicle.typeDetails?.model?.en || 'N/A'),
    },

    {
      title: 'Year',
      sorter: false,
      dataIndex: 'manufacturedate',
      key: 'manufacturedate',
      render: (text, vehicle) => RenderColumnComponent(true)(vehicle.manufacturedate),
    },
    {
      title: 'Status',
      sorter: false,
      dataIndex: 'status',
      key: 'status',
      render: (text, vehicle) => RenderColumnComponent(true)(<StatusComponent status={vehicle.isAvailable  ? 'available' : 'booked'} />),
    },
    {
      title: 'Secondary Status',
      sorter: false,
      dataIndex: 'serviceStatus',
      key: 'serviceStatus',
            width: 200,

      render: (text, vehicle) => RenderColumnComponent(true)(
        <StatusDropdown
        activeStatus={vehicle.serviceStatus}
        onChange={updateVehicleSeconderyStatus}
        statusesList={seconderyStatuses}
        query={query}
        data={vehicle}
        statusName={'serviceStatus'}
        />
      ),
      },
      {
        title: 'Current Driver',
        sorter: false,
        dataIndex: 'currentDriver',
        key: 'currentDriver',
        render: (text, vehicle) => RenderColumnComponent(true)(vehicle.driverName  ? `${vehicle.driverName.firstName || 'N/A'} ${vehicle.driverName.lastName || 'N/A'}` : 'N/A'),
      },
    // {
    //   title: 'Secondary Status',
    //   sorter: false,
    //   dataIndex: 'serviceStatus',
    //   key: 'serviceStatus',
    //   width: 200,
    //   render: (text, vehicle) => RenderColumnComponent(true)(vehicle.serviceStatus),
    // },
    // {
    //   title: 'Active',
    //   sorter: false,
    //   dataIndex: 'isActive',
    //   key: 'isActive',
    //   render: (text, vehicle) => RenderColumnComponent(true)(vehicle.isActive  ? 'Active' : 'Inactive'),
    // },
    {
      title: 'Color',
      sorter: false,
      dataIndex: 'color',
      key: 'color',
      render: (text, vehicle) => RenderColumnComponent(true)(vehicle.color),
    },
    {
      title: "View/Edit",
      dataIndex: "edit",
      sorter: false,
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <MoreOutlined
              onClick={() => {
                setIsDrawerOpen(true)
                dispatch(setVehicle(record))
              }}
              style={{ fontSize: "18px", marginRight: "8px" }}
            />
          </>
        );
      },
    },

  ];

  return (
    <CustomTable
      data={data?.content}
      elementsName='Vehicles'
      query={query}
      setQuery={setQuery}
      columns={
        columns?.map((column) => ({
          ...column,
          ...((column).dataIndex !== "status" &&
            (column).sorter !== false && {
            ...columnSortProps((column).dataIndex),
          }),
        }))
      }
      scrollPosition={IS_SUPERVISOR ? 27 : 21}
      scroll={true}
      pagination={true}
      totalRecords={data?.totalRecords}
      activeRowId={activeRowId}
    />
  );
};

export default VehiclesManagementTable;
