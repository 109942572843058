import React , {useState} from 'react'
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

export default function MonthSelector({ setMonthQuery, handler }) {
    const dispatch = useDispatch();
    const [calDate, setCalDate] = useState(currentMonth(new Date()));
  
    function currentMonth(date) {
      const startDefaultDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
      startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC
      return startDefaultDate.toISOString().split('T')[0];
    }
  
    const handleChange = async (date) => {
      const startAt = new Date(date);
      const endAt = new Date(startAt);
      endAt.setMonth(startAt.getMonth() + 1, 0);
  
      const formatDate = (date) => date.toISOString().split('T')[0];
      const monthQuery = {
        startAt: formatDate(startAt),
        endAt: formatDate(endAt),
      };
      await dispatch(handler(monthQuery));
      setMonthQuery(monthQuery);
    };
  
    const increaseMonthByOne = (date) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
      };
    
      const decreaseMonthByOne = (date) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - 1);
        return newDate;
      };
    const handleDecreaseMonth = async () => {
      const newDate = decreaseMonthByOne(new Date(calDate));
      const newMonth = currentMonth(newDate);
      setCalDate(newMonth);
      await handleChange(newMonth);
    };
  
    const handleAddMonth = async () => {
      const newDate = increaseMonthByOne(new Date(calDate));
      const newMonth = currentMonth(newDate);
      setCalDate(newMonth);
      await handleChange(newMonth);
    };
  
    const getMonthName = (monthNumber) => {
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
      return monthNames[monthNumber];
    };
  
  
    return (
      <div className="flex justify-center items-center">
        <Button
          style={{ border: 'none', background: 'none', boxShadow: 'none' }}
          type="default"
          icon={<LeftOutlined />}
          onClick={handleDecreaseMonth}
        />
        <p>
          {getMonthName(calDate.split('-')[1] - 1)}
        </p>
        <Button
          type="default"
          icon={<RightOutlined />}
          style={{ border: 'none', background: 'none', boxShadow: 'none' }}
          onClick={handleAddMonth}
        />
      </div>
    );
  }
  