import React, { useCallback, useEffect, useRef } from 'react';
import markerImag from '../../../assets/sawaMarker.webp';
import { useDispatch } from 'react-redux';
import { getTripLocation } from "../store/tripSlice";

const LiveMap = ({ trip }) => {
    const { currentLocation } = trip;
    const googleMapRef = useRef(null);
    const mapInstance = useRef(null);
    const timeoutRef = useRef(null);
    const markerInstance = useRef(null);
    const dispatch = useDispatch();
    
    // Initialize the position once for the first render
    const initialPosition = { lat: currentLocation?.latitude || trip.zoneFrom.coordinates.latitude, lng: currentLocation?.longitude || trip.zoneFrom.coordinates.longitude };
    console.log('currentLocation', currentLocation);
    console.log('initialPosition', initialPosition);

    // Function to update marker position
    const changeMarkerPosition = useCallback(async () => {
        try {
            if (trip.status !== 'ongoing') return
            const { payload } = await dispatch(getTripLocation(trip._id));
            if (payload) {
                const { latitude, longitude } = payload;
                const newPosition = { lat: latitude, lng: longitude };

                // Update the marker position without triggering re-renders
                if (markerInstance.current) {
                    markerInstance.current.setPosition(newPosition);
                }

                // Also center the map to the new marker position
                if (mapInstance.current) {
                    mapInstance.current.setCenter(newPosition);
                }
            }
        } catch (error) {
            console.error('Failed to fetch location:', error);
        }

        // Set a timeout to update the marker's position again
        timeoutRef.current = setTimeout(changeMarkerPosition, 5000);
    }, [dispatch, trip._id]);

    useEffect(() => {
        const initializeMap = () => {
            const mapOptions = {
                zoom: 14,
                center: initialPosition, // Use the initial position to center the map
            };

            // Initialize the map instance
            mapInstance.current = new window.google.maps.Map(googleMapRef.current, mapOptions);

            // Initialize the marker instance
            markerInstance.current = new window.google.maps.Marker({
                position: initialPosition, // Start the marker at the initial position
                map: mapInstance.current,
                icon: {
                    url: markerImag,
                    scaledSize: new window.google.maps.Size(40, 50), // Custom size for the marker
                },
            });

            // Start the position updating process
            changeMarkerPosition();
        };

        // Load Google Maps script if it is not already loaded
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDeU5D4QKzPBEy-GP4S9MpeiQ-goAxYjfU`;
            script.onload = initializeMap;
            document.body.appendChild(script);
        } else {
            initializeMap();
        }

        // Cleanup on component unmount
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [changeMarkerPosition]); // Only initialize the map once and rely on marker updates for position

    return (
        <div ref={googleMapRef} className="shadow border rounded-xl items-start justify-center h-[553px] bg-white" />
    );
};

export default LiveMap;
