import React from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { DatePicker } from 'antd';
import CustomFilter from '../../../components/customFilter/CustomFilter'


export default function Filters({ query, setQuery }) {
    const dispatch = useDispatch()
    const {businessAccounts} = useSelector((state) => state.trip.trip)    
    const {drivers} = useSelector((state) => state.trip.trip)    
    const {vehicles} = useSelector((state) => state.trip.trip)    
    const {types} = useSelector((state) => state.trip.trip)    
    const statusList = [
        { value: '', label: 'All' },
        { value: 'pending', label: 'Pending' },
        { value: 'acknowledge', label: 'Acknowledge' },
        { value: 'confirmed', label: 'Confirmed' },
        { value: 'enRoute', label: 'En Route' },
        { value: 'arrived', label: 'Arrived' },
        { value: 'pendingDriverAcceptance', label: 'Pending Approval' },
        { value: 'ongoing', label: 'Ongoing' },
        { value: 'completed', label: 'Completed' },
        { value: 'canceled', label: 'Canceled' },
    ]
    const businessAccountsList = [
        { value: '', label: 'All' },
       ...businessAccounts.map(({ id, name }) => ({ value: id, label: name }))
    ]
    const driversList = [
        { value: '', label: 'All' },
       ...drivers.map(({ id, firstName , lastName }) => ({ value: id, label: firstName + ' ' + lastName })) 
    ]
    const verhiclesList = [
        { value: '', label: 'All' },
       ...vehicles.map(({ id, licensePlateNumber  }) => ({ value: id, label: licensePlateNumber })) 
    ]
    const typesList = [
        { value: '', label: 'All' },
       ...types.map(({ id, make, model  }) => ({ value: id, label: `${make.en}/${model.en}` })) 
    ]
    const handleDateChange = (date, dateString) => {
        dispatch(setQuery({ ...query, date: dateString }));
    };

    const handleTimeChange = (time, timeString) => {
        dispatch(setQuery({ ...query, time: timeString }));
    };
    return (
        <div className='flex justify-center items-center'>
            <CustomFilter
                title='Status'
                name='status'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Business Account'
                name='businessAccountId'
                list={businessAccountsList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Driver'
                name='driverId'
                list={driversList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Assigned Vehicle'
                name='vehicleId'
                list={verhiclesList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Make /  Model'
                name='vehicleTypeId'
                list={typesList}
                query={query}
                setQuery={setQuery}
            />
              <div className='flex flex-col font-bold mr-4 ml-4 gap-y-2'>
                <label>Date:</label>
              <DatePicker showTime onChange={handleDateChange} />
            </div>

        </div>
    )
}
