import {
  ExportSvg,
  PrintReceiptIconSvg,
  SendNotificationsIconSvg,
  ShareReceiptIconSvg,
} from "../../assets/img/icons";
import { PlusOutlined ,ControlOutlined , FilePdfOutlined , TableOutlined  } from "@ant-design/icons";
import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import React from "react";
import "./styles.scss";

const LOGIN_ROUTE = "/login";
const RESET_PASSWORD_ROUTE = "/reset-password";
const FORGOT_PASSWORD_ROUTE = "/forgot-password";


const CustomButton = ({
  btnText = "Save",
  handleSubmit,
  disabled = false,
  className = ``,
  btnType = "primary",
  icon = undefined,
  btnSize = "middle",
}) => {

  const btnIcon = {
    Print: <PrintReceiptIconSvg />,
    Share: <ShareReceiptIconSvg />,
    Plus: <PlusOutlined className="text-[small]" />,
    Export: <ExportSvg />,
    Send: <SendNotificationsIconSvg />,
    filter : <ControlOutlined />,
    pdf :<FilePdfOutlined />,
    csv :<TableOutlined />,
  };
  const authPages = [
    LOGIN_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE,
  ].includes(window.location.pathname);
  const defaultClass = authPages ? `w-2/3 h-10` : `h-[2.3rem] min-w-fit w-40`;
  //const buttonText = getLocalizeText(`label.${btnText}`);
  return (
    <Button
      // type={"primary"}
      // htmlType={htmlType}
      className={` font-bold ${defaultClass} ${
        disabled ? `!text-[darkGray]` : `custom-${btnType}`
      }  ${className} `}
      onClick={handleSubmit}
      icon={icon ? btnIcon[icon] : undefined}
      disabled={disabled}
      size={btnSize}
    >
      {btnText}
    </Button>
  );
};

export default React.memo(CustomButton);
