import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const UrlPopup= ({urlPop , setUrlPopUp}) => {
  
  const handleOk = () => {
    setUrlPopUp({isOpen:false, url:''});
  };

  const handleCancel = () => {
    setUrlPopUp({isOpen:false, url:''});
  };
  const getFileName = (url) => {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1].split('-')[1];
    return fileName.replace(/\.(pdf|csv)$/, '').replace(/_/g, ' ');
  };
  return (
    <>
      <Modal
        // title="Uploaded Report Url"
        open={urlPop?.isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
          ]}
      > <h2 className='text-lg font-bold mb-8'>Uploaded File URL</h2>
       <a href={urlPop?.url} target="_blank" rel="noopener noreferrer">
          {urlPop?.url && getFileName(urlPop?.url)}
        </a>
      </Modal>
    </>
  );
};

export default UrlPopup;