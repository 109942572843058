import React, {  useEffect,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import AdminUsersManagementTable from "./table/AdminUsersManagementTable";
import { Drawer } from "antd";
import { getAdminUsers , setQuery , setAdminUser} from "./store/adminUserSlice";
import AdminUserForm from "./components/AdminUserForm";
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'

function AdminUsers() {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const IS_SUPERVISOR = true;
  const {adminUsers} = useSelector((state) => state.adminUser.adminUsers);
  const {adminUser} = useSelector((state) => state.adminUser.adminUsers);
  const {query} = useSelector((state) => state.adminUser.adminUsers);

  useEffect(() => {
    dispatch(getAdminUsers(query));
  }, [dispatch,query]);

 



  return (
    <div className="bg-layout h-full flex flex-col">
      <FilterListSection
        setQuery= {setQuery}
        leftComponent={<p className="font-bold text-lg mr-8">Admins</p>}
                btnProps={{
          btnText: `Admin Users`,
          // onClickHandler: handleToggleDrawer.bind({}, DEFAULT),
          onClickHandler: () => setIsDrawerOpen(true),
        }}
        searchProps={{
          searchKey: "Admin  Users",
          placeHolder:"Find account by Admin user Email or name" ,
          onTextChange:(e)=>dispatch(setQuery(e ? {...query,search:e ,  limit:10, page:1} : {...query, limit:10, page:1})),

        }}
        filterComponent={
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const { businessaAccountId,  driverId, vehicleId,status, dropOffLocation , pickupLocation , date  ,time , ...rest } = query
                dispatch(setQuery({...rest, status: ''}))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
          />
        }
      />
      <AdminLayoutContentWrapper>
        <div className="w-full flex justify-start"> 
        {isFilterOpen &&
            <Filters 
              query={query}
              setQuery={setQuery}
            />
          }
        </div>
        <AdminUsersManagementTable
          data={{ content: adminUsers }}
          setQuery={setQuery}
          query={query}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={{ field: "createdAt", order: "descend" }}
          activeRowId={adminUser?._id}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setAdminUser={setAdminUser}
          setIsDrawerOpen={setIsDrawerOpen}

        />
      </AdminLayoutContentWrapper>
   
       <Drawer
        className={`form-drawer`}
        // rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={'Add New Admin User'}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setAdminUser(null))
          setIsDrawerOpen(false)
        }}
        width='33%'
        destroyOnClose
      >
        <AdminUserForm
          adminUser={adminUser}
          setIsDrawerOpen={setIsDrawerOpen}
          setAdminUser={setAdminUser}
        />
      </Drawer>

    </div>
  );
}
export default AdminUsers;