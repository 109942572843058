export const isEmpty = (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "string" && !value.trim().length) ||
    (typeof value === "object" && !Object.keys(value).length);


export const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const isEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phoneNumber);
};



export const isRouteAllowed = (role , menuList , location ) => {
    if (location === '/') return true
    return menuList.filter(({ permission }) => permission?.includes(role)).map(({ route }) => route.split('/')[1]).includes(location.split('/')[1])
   
}