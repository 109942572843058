// import { genericSearch } from "@/core/utils/array-utils";
import { isEmpty } from "../../utils/functions";
import { useDispatch } from "react-redux";
import { Table as AntTable } from "antd";
// import { ColumnsType, TablePaginationConfig } from "antd/es/table";
// import { RowClassName } from "rc-table/lib/interface";
import {
  useMemo,
  useState,
} from "react";
import "./style.css";
import { useLocation } from "react-router-dom";
import { UseNavigateStateParams } from "../../utils/useNavigateServerParams";


export const CustomTable = ({
  className,
  elementsName = "",
  data,
  columns,
  pagination = false,
  rowClickHandler = undefined,
  scroll = false,
  scrollPosition = 20,
  tableLayout = "fixed",
  rowClassName = undefined,
  searchFilter = undefined,
  bodyCellComponent = undefined,
  onChangePageSort = undefined,
  paginationClass = undefined,
  totalRecords = undefined,
  expandable = undefined,
  defaultCurrentPage = undefined,
  activeRowId, // Accept drawerData as a prop
  query = {},
  setQuery = undefined,
}) => {
  const dispatch = useDispatch();
  const DEFAULT_PAGE_SIZE = 10;
  const location = useLocation();
  const [page, setCurrentPage] = useState(1);
  const currentPage = isEmpty(totalRecords)
    ? page
    : location.state?.currentPage ?? defaultCurrentPage ?? page;
  const { handleNavigateStateParams } = UseNavigateStateParams();

  const [resultData, setResultData] = useState([]);

  const pageParams = useMemo(() => {
    const dataLength = totalRecords ?? resultData?.length;
    if (dataLength === 0) {
      return {
        startingIndex: 0,
        endingIndex: 0,
      };
    }

    const pageSize =
      (pagination).pageSize ?? DEFAULT_PAGE_SIZE;
    return {
      startingIndex: dataLength > 0 ? (currentPage - 1) * pageSize + 1 : 0,
      endingIndex:
        dataLength >= (currentPage - 1) * DEFAULT_PAGE_SIZE + 1 &&
          dataLength <= currentPage * DEFAULT_PAGE_SIZE
          ? dataLength
          : currentPage * DEFAULT_PAGE_SIZE,
    };
  }, [currentPage, pagination.pageSize, resultData?.length, totalRecords]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
    if (!isEmpty(totalRecords) && isEmpty(defaultCurrentPage)) {
      handleNavigateStateParams({ currentPage: page });
    }
  };
  return (
    <>
      <AntTable
        rowClassName={(record, index) => {
          const shouldHighlightRow = activeRowId && record?.id === activeRowId;
          return shouldHighlightRow ? "highlighted-row" : "";
        }}
        className={`customTable ${className} `}
        dataSource={resultData.length || data}
        tableLayout={tableLayout}
        columns={columns}
        onChange={(pagination, filter, sorter, extra) => {
          dispatch(setQuery({...query, orderBy:sorter.field , orderDirection:sorter.order , page:pagination.current, limit:pagination.pageSize})); 
   

          if (onChangePageSort) {
            onChangePageSort(pagination, sorter);
            return;
          }

          if (
            extra.action === "sort" &&
            (sorter)?.order &&
            totalRecords
          ) {
            handleNavigateStateParams({
              orderBy: (sorter).order,
              orderColumn: (sorter)?.field,
            });
          }
        }}
        components={{
          body: {
            cell: bodyCellComponent,
          },
        }}
        scroll={
          scroll && {
            y: `calc(145vh - ${scrollPosition}em)`,
          }
        }
        pagination={
          pagination
            ? {
              position: ["bottomRight"],
              ...((pagination).position && { pagination }),
              current: currentPage,
              pageSize: query?.limit,
              showSizeChanger: true,
              onChange(page) {
                dispatch(setQuery({ ...query, page }));
                handleChangePage(page);
              },
              total: totalRecords,
              showLessItems: true,
              // showTitle: true,
              showTotal: (totalRecords, range) => {
                return (
                  <div>
                    {range[0]}-{range[1]} of {""}
                    {totalRecords}
                    
                  </div>
                );
              },
            }
            : false
        }
        expandable={expandable}
        onRow={(record, index) => {
          return {
            onClick: (event) => {
              rowClickHandler && rowClickHandler(record, index);
            },
          };
        }}
        rowKey={(record) =>
          window.location.pathname.includes("reconcile-agent")
            ? record?.description
            : record?.id ?? Math.random().toString(36).substring(7)
        }
      />
      {pagination && (
        <div
          className={` hidden absolute -top-[33px] right-[10px]   text-[#808080] ${paginationClass}`}
        >
          {`Showing ${pageParams?.startingIndex ?? 0}-${
            pageParams?.endingIndex ?? 0
          } of ${totalRecords ?? resultData?.length ?? 0} results`}
        </div>
      )}
    </>
  );
};
