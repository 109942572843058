import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import CustomPopconfirm from "../../../components/customPopconfirm/CustomPopconfirm";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateDriver } from '../store/driverSlice'
import StatusComponent from "../../../components/StatusComponent/StatusComponent";
const DriversManagementTable = ({
  data,
  handleEditVehicle,
  defaultSort,
  activeRowId,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
  setDriver
}) => {
  const dispatch = useDispatch();

  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };


  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      sorter: false,
      render: (text, driver) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${driver.firstName} ${driver.lastName}`)}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      render: (text, driver) => RenderColumnComponent(true)(<StatusComponent status={driver.status}/>),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: false,
      key: 'email',
      render: (text, driver) => RenderColumnComponent(true)(driver.email),
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      sorter: false,
      key: 'availability',
      render: (text, driver) => RenderColumnComponent(true)(<StatusComponent status={driver?.isAvailable ? 'available' : 'unAvailable'}/>),
    },
    {
      title: "Activation", //"Active/Inactive",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, driver) => {
        return (
          <CustomPopconfirm
            title={
              `Confirm ${driver?.isActive ? 'Deactivate' : 'Activate'} this driver?`}
            onConfirm={() => dispatch(updateDriver({data :{...driver , isActive: !driver?.isActive }, query}))}
            isChecked={driver?.isActive}
            autoState={true}
          />
        );
      },
    },
    {
      title: "Online", 
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (text, driver) => RenderColumnComponent(true)(<StatusComponent status={driver?.isOnline ? 'online' : 'offline'}/>),
    },
    {
      title: 'Contact No',
      dataIndex: 'mobileNumber',
      sorter: false,
      key: 'mobileNumber',
      render: (text, driver) => RenderColumnComponent(true)(driver.mobileNumber),
    },
    {
      title: 'Current Vehicle',
      dataIndex: 'currentVehicle',
      sorter: false,
      key: 'currentVehicle',
      render: (text, driver) => RenderColumnComponent(true)(driver?.vehicle?.licensePlateNumber || 'N/A'),
    },
    {
      title: "View/Edit", 
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, record) => {


        return (
          <>
            <MoreOutlined
              onClick={() => {
                setIsDrawerOpen(true)
                dispatch(setDriver(record))
              }}
              style={{ fontSize: "18px", marginRight: "8px" }}
            />
          </>
        );
      },
    },

  ];

  return (
    <CustomTable
      data={data?.content}
      elementsName='Drivers'
      query={query}
      setQuery={setQuery}
      columns={
        columns?.map((column) => ({
          ...column,
          ...((column).dataIndex !== "status" &&
            (column).sorter !== false && {
            ...columnSortProps((column).dataIndex),
          }),
        }))
      }
      scrollPosition={IS_SUPERVISOR ? 27 : 21}
      scroll={true}
      pagination={true}
      totalRecords={data?.totalRecords}
      activeRowId={activeRowId}
    />
  );
};

export default DriversManagementTable;
