import React from "react";
import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import './style.css';
import logo from '../../assets/logo.svg';
import background from '../../assets/sawaBackground.png'


function Home() {
  return (
    <div className="">
      <AdminLayoutContentWrapper>
        <img src={logo} alt="logo" className="logo"/>
        <img src={background} alt="background"  className="home-image"/>
      </AdminLayoutContentWrapper>
    </div>
  );
}
export default Home;