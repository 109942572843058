import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, notification, Space } from 'antd';
import moment from 'moment';
const close = () => {
 
};

const NotificationsComponent = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    openNotification
  })); 
  const [api, contextHolder] = notification.useNotification();
  const { data, isActive } = props.notificationData;



//   useEffect(() => {
//     if (isActive) {
//       openNotification();
//     }
//   }, [props.notificationData]);

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy()}>
          Close All
        </Button>
        <Button type="primary" size="small" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.open({
      message: (<p className='font-bold'>{data?.notification?.title}</p>) || 'new notification',
      description:  (
        <div>
          <p><span className='font-bold'>Business Account: </span>{data?.data?.businessAccount}</p>
          <p><span className='font-bold'>Start At: </span>{moment(data?.data?.startAt).format('DD-MM-YYYY, h:mm a')}</p>
          <p><span className='font-bold'>From: </span>{data?.data?.fromPoint}</p>
          <p><span className='font-bold'>To: </span>{data?.data?.toPoint}</p>
        </div>
      ) || 'Default description',    
      btn,
      key,
      onClose: close,
    });
  };

  return (
    <>
       
      {contextHolder}
      {/* <Button type="primary" onClick={openNotification}>
        Open the notification box
      </Button> */}
    </>
  );
});

export default NotificationsComponent;