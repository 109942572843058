import axios from 'axios';
import { Component } from 'react';
/* eslint-disable camelcase */

class JwtService extends Component {
  static _TOKEN;
  constructor() {
    super();
    this.setInterceptors();
  }

  async init() {
   this.handleAuthentication();
     // this.signInWithToken()
  }

  setInterceptors = () => {
    if (process.env.NODE_ENV !== 'production') {
      axios.defaults.baseURL = 'http://localhost:7070';
    } else {
      axios.defaults.baseURL = 'https://api.sawabusiness.com';
    }
  };

  handleAuthentication = () => {
     this._TOKEN = this.getAccessToken();
    if (!this._TOKEN) {
      this.setSession(null);
      return;
    }
    if (this.isAuthTokenValid(this._TOKEN)) {
      this.setSession(this._TOKEN);
    } else {
      this.setSession(null);
    }
  };

  signInWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('admin/auth/login', {
          email,
          password,
          firebaseToken: localStorage.getItem('firebaseToken'),
        })
        .then(response => {
          if (response.data.adminUser) {
            this.setSession(response.data.adminTokens.accessToken.token);
            resolve(response.data.adminUser);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  createUser = (userData) =>
    new Promise((resolve, reject) => {
      axios
        .post('/user/signup',
          userData
        )
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.token.accessToken);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/profile')
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            this.logout();
            reject(response.data);
          }
        })
        .catch(error => {
          resolve({error :error});
          this.logout();
        });
    });
  }

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    return true;
  };
  getAccessToken = () => window.localStorage.getItem('jwt_access_token');
}

const instance = new JwtService();

export default instance;
