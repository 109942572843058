import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    Tabs,
    Button,
    Form,
    Select,
    Input,
    Upload,
    DatePicker
} from 'antd';
import { createCorporateAccount, updateCorporateAccount } from '../store/CorporateAccountSlice';
import { toast } from 'react-hot-toast';
import { isEmail, isPhoneNumber } from '../../../utils/functions';
import { CameraOutlined, UploadOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import './style.css'
import moment from 'moment';



export default function CorporateAccountFrom({ corporateAccount, setIsDrawerOpen }) {
    const dispatch = useDispatch();
    const { query } = useSelector(state => state.corporateAccount.corporateAccount);
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [accountLogoUrl, setAccountLogoUrl] = React.useState('');
    useEffect(() => {
        if (corporateAccount) {
            console.log('corporateAccount', corporateAccount);  
            form.setFieldsValue({ ...corporateAccount, contactPersonPhoneNumber: corporateAccount.contactPersonPhoneNumber.replace('+974', '')});
            setAccountLogoUrl(corporateAccount?.logoUrl || '');
        }
    }, [dispatch, corporateAccount, form]);
    const saveCorporateAccount = (values) => {
        setIsLoading(true);
        if (corporateAccount) {
            dispatch(updateCorporateAccount({ data: { ...values, logoUrl:accountLogoUrl, _id: corporateAccount.id, contactPersonPhoneNumber: `+974${values.contactPersonPhoneNumber}`, accountNumber: `+974${values.contactPersonPhoneNumber}`, receptionNumber: `+974${values.receptionNumber}` }, query }))
                .then((data) => {
                    if (!data.payload?._id) {
                        toast.error('updating corporate account failed');
                        return;
                    }
                    toast.success('Corporate Account Updated')
                    setIsDrawerOpen(false);
                })
            return
        }
        dispatch(createCorporateAccount({ ...values, logoUrl: accountLogoUrl, contactPersonPhoneNumber: `+974${values.contactPersonPhoneNumber}`, }))
            .then((data) => {
                if (!data.payload || data?.payload?.error || data?.error) {
                    toast.error('creating corporate account failed');
                    return;

                }
                toast.success('Corporate Account Created');
                form.resetFields();
                setIsDrawerOpen(false);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const onFinishFailed = (e) => {
        toast.error('Submit failed!');
    };

    const removeContractUrl = () => {
        setAccountLogoUrl('');
    }


    const getName = (url) => {
        const regex = /[^/]+(?=\.[^.]+$)/;
        const result = url.match(regex);
        return result[0]?.length > 10 ? (result[0]?.substring(0, 10) + '...') : result[0];
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const customRequest = (name) => async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('admin/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            onSuccess();
            setAccountLogoUrl(response.data.fileUrl)
            toast.success('File uploaded successfully');
        } catch (error) {
            toast.error('File upload failed');
            console.log("upload error ==>", error);
            // onError(error);
        }
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    };


    const uploadAccoutLogo = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post('/admin/uploadDocument', formData);
            onSuccess(response.data);
            setAccountLogoUrl(response.data.fileUrl);
        } catch (error) {
            onError(error);
        }
    };

    const CorporateAccountDetails = () =>
        <Form
            form={form}

            labelAlign='left'
            className='w-full flex flex-col gap-y-0   '
            name="Info"
            colon={false}
            labelCol={{
                span: 12,
            }}

            wrapperCol={{
                span: 24,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinishFailed={onFinishFailed}
            onFinish={saveCorporateAccount}
        >
            <Form.Item
                label="_id"
                name='id'
                className='p-0 m-2'
                hidden
            >
                <Input />
            </Form.Item>

            {
                accountLogoUrl ?
                    (
                        <Form.Item initialValue={accountLogoUrl} className='mt-8 flex flex-col justify-center items-center w-full'>
                            <div className='pt-0 mt-0 doc-link-cont-single' >
                                <img
                                    src={accountLogoUrl}
                                    alt="Account Logo"
                                    className='w-full h-auto object-cover'
                                    style={{ maxWidth: '100%' , maxHeight: '75px' }}
                                />
                            </div>
                            <div className='flex justify-around'>
                                {getName(accountLogoUrl)}
                                <DeleteOutlined onClick={() => removeContractUrl()} />
                            </div>
                        </Form.Item>

                    ) : (
                        <Form.Item name={'accountLogoUrl'} initialValue={accountLogoUrl} className=' flex flex-col justify-center items-center mt-8' >
                            <Upload className='flex flex-col w-full  ' customRequest={customRequest()}  >
                                <Button className="ant-upload-doc flex flex-col w-full " style={{ width: '400px' }} icon={<UploadOutlined />}>Upload Contract</Button>
                            </Upload>
                        </Form.Item>
                    )
            }


     

            <Form.Item
                label="Corporate Name"
                name='name'
                className='p-0 m-2'

            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Contact Person Name"
                name='contactPersonName'
                className='p-0 m-2'
               

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Contact Person’s Phone Number"
                name='contactPersonPhoneNumber'
                className='p-0 m-2'
                rules={[{
                    validator: (_, value) => {
                        if (!/^[0-9]+$/.test(value)) {
                            return Promise.reject(new Error('must be at valid phone  Numbers only'));
                        }
                        return Promise.resolve();
                    },
                },
                ]}
            >
                <Input addonBefore={
                    <span className="text-sm font-medium">
                        +974
                    </span>
                } />
            </Form.Item>

            <Form.Item className='flex justify-end'>
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                    {corporateAccount ? 'Save' : 'Create Account'}
                </Button>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>



    return (
        <div className="flex flex-col">
            <p className="form-subHeader">Corporate Details</p>
            <div className="flex w-full flex-col px-8 pt-8">
                <div className="flex flex-col">
                
                    <CorporateAccountDetails />
                </div>
            </div>
        </div>

    )
}
