import React, { useEffect, useState } from "react";
import loginImage from "../../assets/img/login_header.png"
import { useNavigate, useLocation  } from "react-router-dom"; // Added useLocation here
import { useDispatch, useSelector } from 'react-redux';
import { submitOtp , setResetPasswordToken } from "../../auth/store/loginSlice"
import { validateEmail, isEmpty } from '../../helper'
import MetaTag from "../../components/metaTag";
import { Box, TextField, Button } from '@mui/material';
import PublicLayoutContentWrapper from "../../layout/publicLayout/PublicLayout";
import toast from "react-hot-toast";

const OTP = () => {
    const navigate = useNavigate();
    const {otpToken} = useSelector(({ auth }) => auth.login);
    const dispatch = useDispatch();
    const [passwordCheck, setPasswordCheck] = useState('');
    const user = useSelector(({ auth }) => auth.user.data);
    const [otp, setOtp] = useState(['', '', '', '']);
    useEffect(() => {
        if (user.id) {
            navigate("/");
        return;
        }
        if (!otpToken) {
            navigate("/forgot-password");
        }
    }, [dispatch, navigate, user, otpToken])

   
    const submitOtpHandler = () => {
        const otpString = otp.join('');
        if (otpString.length !== 4) {
            toast.error('Please enter valid OTP');
            return;
        }

        dispatch(submitOtp({ otp : otpString ,  otpToken})).then((res) => {
            if(!res || (res && res.payload && res.payload.error)){
                if(res?.payload?.error?.response?.data?.message === 'Invalid input' ){
                    toast.error('Invalid OTP');
                    return
                }
                toast.error('Something went wrong, Please try again later');
                return 
            }   
            if(res?.payload?.token){
                dispatch(setResetPasswordToken(res.payload.token));
                toast.success('OTP Accepted');
                navigate("/reset-password");
            }
         
        });
    }
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next field if a digit is entered
            if (value !== '' && index < 3) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                document.getElementById(`otp-${index - 1}`).focus();
            }
        }
    };
    return (
        <div className="">
            <MetaTag />
            <PublicLayoutContentWrapper title="Change Password" description="Please enter recived Password " className="flex flex-col items-center" headerImage={loginImage} >
                <div className="flex flex-col   w-full  mx-auto text-start">
                    <Box
                        component="form"
                        className="flex flex-col gap-y-4 justify-center w-full  mx-auto  "
                        // sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className="flex justify-between">

                       {otp.map((digit, index) => (
                <TextField
                    key={index}
                    id={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                    variant="outlined"
                    required
                    color="secondary"
                    // style={{ width: '20%' }}
                    sx={{
                        width: '20%',
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#274D91', // Change this to your desired color
                            },
                        },
                    }}
                />
            ))}
                        </div>
                        <Button
                            variant="contained"
                            className="mb-0"
                            sx={{ backgroundColor: '#24508d' }}
                            onClick={submitOtpHandler}
                        >
                            Submit OTP
                        </Button>
                    </Box>

                </div>
            </PublicLayoutContentWrapper>

        </div>
    );
};

export default OTP;
