import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import CustomPopconfirm from "../../../components/customPopconfirm/CustomPopconfirm";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import {  MoreOutlined, DollarOutlined , AppstoreOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {updateBussinessAccount} from '../store/businessAccountSlice'

const BusinessAccountsManagementTable = ({
  data,
  handleEditVehicle,
  defaultSort,
  activeRowId,
  handleToggleVehicleStatus,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
  setBusinessAccount
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth);
  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };


  const columns = [
    {
      title: 'Email', 
      dataIndex: 'email', 
      key: 'email',
      sorter: false,
      render: (text, account) => <div className="flex gap-x-2">{RenderColumnComponent(true)(account.email)}</div> ,
    },
    {
      title: 'Name', 
      dataIndex: 'name', 
      key: 'name',
      sorter: false,
      render: (text, account) => RenderColumnComponent(false)(account.name) ,
    },
    {
      title: 'Mobile Number', 
      dataIndex: 'mobileNumber', 
      key: 'mobileNumber',
      sorter: false,
      render: (text, account) => RenderColumnComponent(true)(account.mobileNumber) ,
    },
    {
      title: 'Activation', 
      dataIndex: 'status', 
      key: 'status',
      sorter: false,
      render: (_, account) => {
        return (
          <CustomPopconfirm
            title={
              `Confirm ${account?.status === 'inactive' ? 'Activate' : 'Dactivate'} this account?`}
            onConfirm={() => dispatch(updateBussinessAccount({data :{...account , status:account.status == 'active' ? 'inactive' : 'active' }, query}))}
            isChecked={account?.status === 'active'}
            autoState={true}
          />
        );
      },
    },
    {
      title: "View/Edit", //"Active/Inactive",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, record) => {
    
        return (
          <>
               {user.role === 'superAdmin' &&  <DollarOutlined 
                onClick={() => {
                  dispatch(setBusinessAccount(record))
                  navigate(`/manage-business-accounts/${record.id}`);
                }}
                style={{ fontSize: "18px", marginRight: "8px" }}
                />}
              <AppstoreOutlined
              onClick={() => {
                dispatch(setBusinessAccount(record))
                navigate(`/manage-business-accounts/dashboard/${record.id}`);
              }}
               style={{ fontSize: "18px", marginRight: "8px" }}
                />
            <MoreOutlined
            onClick={() => {
              dispatch(setBusinessAccount(record))
              setIsDrawerOpen(true)
            }}
             style={{ fontSize: "18px", marginRight: "8px" }}
              />
          </>
        );
      },
    },
  ];
 
  return (
      <CustomTable
        data={data?.content}
        elementsName='accounts'
        query={query}
        setQuery={setQuery}
        columns={
          columns?.map((column) => ({
            ...column,
            ...((column ).dataIndex !== "status" &&
            (column ).sorter !== false && {
              ...columnSortProps((column ).dataIndex),
            }),
          })) 
        }
        scrollPosition={IS_SUPERVISOR ? 27 : 21}
        scroll={true}
        pagination={true}
        totalRecords={data?.totalRecords} 
        activeRowId={activeRowId}
        />
  );
};

export default BusinessAccountsManagementTable;
