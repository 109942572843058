import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import "./style.css";
import { getTypes, getBusinessAccounts } from '../store/CorporateAccountSlice';
import { Button } from "antd";
import { setPricesPopup } from '../store/CorporateAccountSlice';
import PricePop from './components/PricePop';

export default function CorporateAccountPriceList() {
  const [isLoading, setIsisLoading] = useState(true);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { corporateAccountPricePage } = useSelector((state) => state.corporateAccount.corporateAccount);
  const { types } = useSelector((state) => state.corporateAccount.corporateAccount);

  const typesList = new Set((types || []).map(e => {
    if (e?.make?.en) {
      return e.make.en
    } else {
      return null
    }
  }))

  useEffect(() => {
    dispatch(getTypes());
    setIsisLoading(true);
    if (!isLoading && !corporateAccountPricePage) {
      setIsisLoading(false);

      navigate('/manage-corporate-accounts');
    }
    setIsisLoading(false);

  }, [accountId, corporateAccountPricePage, dispatch, isLoading, navigate]);

  if (isLoading) {
    return <div>isLoading...</div>;
  }

  if (!corporateAccountPricePage) {
    return null; // or a fallback UI while navigating
  }


  return (
    <div className='pricing-major'>

      <div className='pricing-main'>
        <p className='subpage-header'>{corporateAccountPricePage.name}<span>{'> '} Pricing list </span></p>
        {
          [...typesList].map((type) => {
            return (
              <React.Fragment key={type}>
                <p className='subpage-subHeader'>{type || 'N/A'}</p>
                <div className='type-major-container'>
                  {types.filter(e => e.make?.en === type).map((e) => {
                    return (
                      <Button className='price-element-container' type="primary" key={e.id}
                        onClick={() => dispatch(setPricesPopup({ data: e, isModalOpen: true }))}
                      >{e?.make?.en ? `${e?.make?.en} ${e?.model?.en}` : 'N/A'}
                      </Button>

                    )
                  })}
                </div>
              </React.Fragment>
            )
          })
        }

      </div>

      <PricePop accountId={accountId} corporateName={corporateAccountPricePage.name}/>
    </div>
  )
}
