import React from 'react'
import CustomFilter from '../../../components/customFilter/CustomFilter'


export default function Filters({ query, setQuery }) {
    const role = [
        { value: '', label: 'All' },
        { value: 'superUser', label: 'Super Admin' },
        { value: 'coordinator', label: 'Coordinator' },
    ]
    

    return (
        <div className='flex justify-center items-center'>
            <CustomFilter
                title='Role'
                name='role'
                list={role}
                query={query}
                setQuery={setQuery}
            />
  
     

        </div>
    )
}
