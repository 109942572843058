import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RenderColumnComponent } from '../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent';
import { Modal, Table, Button } from 'antd';
import moment from 'moment/moment';
import { getTripsDetails } from '../store/dashboardSlice';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Loader from '../../../components/loader';
import {  MoreOutlined, DollarOutlined , AppstoreOutlined} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import StatusComponent from '../../../components/StatusComponent/StatusComponent';

const DayTripsPop = ({ isModalOpen, tripsDate, setIsModalOpen , setTripsDate, query }) => {
    const [loading, setLoading] = useState(false);
    const dayDetailsTrips = useSelector(state => state.dashboard.dashboard.tripsDetailsTrips)
    const totalDayTripsResults = useSelector(state => state.dashboard.dashboard.totalDayTripsResults)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        if (isModalOpen) {
            dispatch(getTripsDetails({
                page: 1,
                limit: 10,
                orderBy: 'createdAt',
                orderDirection: 'descend',
                status: '',
                date: tripsDate,
                ...query
            })).then(() => {
                setLoading(false)
            })
        }
    }, [isModalOpen, tripsDate])

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const dataSource = dayDetailsTrips || [];

    const columns = [
        {
            title: 'Business Account',
            dataIndex: 'businessAccount.name',
            key: 'businessAccount',
            sorter: false,
            width: 150,
            render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(trip.businessAccount.name)}</div>,
        },
        {
            title: 'Requested Vehicle',
            dataIndex: 'vehicle',
            key: 'vehicle',
            sorter: false,
            width: 180,
            render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.vehicle?.vehicleType?.make?.en || 'N/A'}-${trip?.vehicle?.vehicleType?.model?.en || 'N/a'}`)}</div>,
        },
        {
            title: 'Pick Up Time',
            dataIndex: 'startAt',
            key: 'startAt',
            width: 180,
            render: (text, trip) => RenderColumnComponent(true)(moment(trip.startAt).format('YYYY-MM-DD HH:mm')),
        },
        {
            title: 'Assigned Vehicle',
            dataIndex: 'plateNumber',
            key: 'plateNumber',
            sorter: false,
            width: 180,

            render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.vehicle?.licensePlateNumber || 'N/a'}`)}</div>,
        },
        {
            title: 'Assigned Driver',
            dataIndex: 'driver',
            width: 150,

            key: 'driver',
            sorter: false,
            render: (text, trip) => RenderColumnComponent(true)(trip.driver ? `${trip?.driver?.firstName || 'N/A'} ${trip?.driver?.lastName || 'N/A'}` : '-----'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,

            key: 'status',
            sorter: false,
      render: (text, trip) => RenderColumnComponent(true)(<StatusComponent status={trip.status}/>) ,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: 100,

            key: 'price',
            sorter: false,
            render: (text, trip) => {
                const activePrice = trip?.discountedPrice ?? trip?.price;
                const modifiedPriceAmount = trip?.priceModification?.amount || 0;
                const totalPrice = (+activePrice + +modifiedPriceAmount) || '----';
                return RenderColumnComponent(true)(totalPrice);
            }        },
        {
            title: 'View',
            dataIndex: 'view',
            width: 100,

            key: 'view',
            sorter: false,
            render: (text, trip) => <>
                  <MoreOutlined
            onClick={() => {
                navigate(`/manage-trips?tripId=${trip._id}`);

            }}
             style={{ fontSize: "18px", marginRight: "8px" }}
             />

            </>,
        },

    ];


    const nextDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
      };
    const previousDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
      };

    return (
        <>
            <Modal className='cal-trip-popup' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                width={1200} footer={null}>
                {loading ? <Loader /> :
                    <div className='p-4 '>
                        <div className='cal-trip-pop-container flex items-center'>
                            <Button
                                style={{ border: 'none', background: 'none', boxShadow: 'none' }}

                                type="default"
                                icon={<LeftOutlined />}
                                onClick={() => {
                                    const newDate = previousDay(tripsDate);
                                    setTripsDate(newDate);
                                }}
                            />
                            <p>
                                <p className='cal-header-title'>{moment(tripsDate).format('DD MMMM yyyy')}</p>
                            </p>
                            <Button
                                type="default"
                                icon={<RightOutlined />}
                                style={{ border: 'none', background: 'none', boxShadow: 'none' }}

                                onClick={() => {
                                    const newDate = nextDay(tripsDate);
                                    setTripsDate(newDate);
                                }}
                            />

                        </div>
                        <div className='cal-popup-main p-4'>
                            <p className='mt-10 mb-6 font-bold text-xl'>Trips</p>
                            <div className="popup-table-container min-h-[300px]">
                                <Table dataSource={dataSource} columns={columns} pagination={false} />
                            </div>
                        </div>
                    </div>
                }

            </Modal>
        </>
    );
};
export default DayTripsPop;