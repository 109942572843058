import React from 'react'
import { Select } from 'antd';
const { Option } = Select;

export default function StatusDropdown({activeStatus, onChange, statusesList , data , statusName , style}) {




  return (
    <div className='custom-select-container'>

     <Select
      value={activeStatus}
     onChange={(e)=> {
         onChange( data, {...data, [statusName]: e})
     }}
      style={{ width:style?.width || 220 }}
      bordered={false}
      className="custom-select"


    >
      {statusesList.map(status => (
        <Option key={status.value} value={status.value}>
         <p

  style={{
    color: status?.color ||  'black',
    fontSize: 'var(--fontSize-16, 16px)',
    fontStyle: 'normal',
    fontWeight: 'var(--fontWeight-bold, 700)',
    lineHeight: 'var(--Typography-Line-Height-Body-sm, 20px)',
}}
>
  {status.label}
</p>
        </Option>
      ))}
    </Select>
    </div>

  )
}


