import React from 'react'

export default function StatusComponent({ status }) {

  //'available' : 'booked'

  const statusCompElement = {
    canceled: {
      color: '#C63333',
      text: 'Canceled',
      background:'#FFF8EF'
    },
    acknowledged: {
      color: '#4B5945',
      text: 'Acknowledged',
      background:'#B2C9AD'
    },
    ongoing: {
      color: '#0b6bb0',
      text: 'On Going',
      background:'#9ec8e6'
    },
    booked: {
      color: '#0b6bb0',
      text: 'Booked',
      background:'#9ec8e6'
    },
    goingToPickUp: {
      color: '#2d08d1',
      text: 'Going to Pick Up',
      background:'#ab9ee6'
    },
    arrived: {
      color: '#2FB64A',
      text: 'Arrived',
      background:'#EAF8ED'
    },
    inReview: {
      color: '#F6A244',
      text: 'In Review',
      background:'#FFF8EF'
    },
    pending: {
      color: '#F6A244',
      text: 'Pending',
      background:'#FFF8EF'
    },
    approved: {
      color: '#2FB64A',
      text: 'Approved',
      background: '#EAF8ED'
    },
    active: {
      color: '#2FB64A',
      text: 'Active',
      background: '#EAF8ED'
    },
    available: {
      color: '#2FB64A',
      text: 'Available',
      background: '#EAF8ED'
    },
    completed: {
      color: '#2FB64A',
      text: 'Completed',
      background: '#EAF8ED'
    },
    rejected: {
      color: '#C63333',
      text: 'Rejected',
      background: '#FAEFEF'
    },
    pendingDriverAcceptance: {
      color: '#274D91',
      text: 'Pending Acceptance',
      background: '#F5F5F6'
    },
    confirmed: {
      color: '#274D91',
      text: 'Confirmed',
      background: '#F5F5F6'
    },
    expired: {
      color: '#90939B',
      text: 'Expired',
    },

  unAvailable: {
      color: '#dc3545',
      text: 'Un Available',
      background: '#ffe6e6'
  }
 , online: {
  color: '#28a745',
  text: 'Online',
  background: '#e6ffe6'
},
offline: {
  color: '#dc3545',
  text: 'Offline',
  background: '#ffe6e6'
}


  }
  return (
    <div style={
      {
        color: (statusCompElement[status]?.color || 'black'),
        background: statusCompElement[status]?.background || 'transparent',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        padding: "5px 14.58px"

      }
    }>{statusCompElement[status]?.text || status}</div>
  )
}
