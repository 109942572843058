import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Input, Table, Dropdown, Menu, Space } from 'antd';
import { setPricesPopup, getPriceList, updatePriceList, getZones, setPriceslist, getBusinessAccounts } from '../../store/CorporateAccountSlice';
import Loader from '../../../../components/loader';
import { DownOutlined } from '@ant-design/icons';
import { defaultTripPrice } from '../../../../utils/constants';
import './style.css';

const PricePop = ({ accountId , corporateName }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const { businessAccounts } = useSelector((state) => state.corporateAccount.corporateAccount);
  const { pricesPopup } = useSelector((state) => state.corporateAccount.corporateAccount);
  const { pricesList } = useSelector((state) => state.corporateAccount.corporateAccount);
  const { zonesList } = useSelector((state) => state.corporateAccount.corporateAccount);
  const [dataSource, setDataSource] = useState([]);
  const { isModalOpen, data } = pricesPopup;

  useEffect(() => {
    if (isModalOpen) {
      setIsLoading(true);
      dispatch(getBusinessAccounts());
      dispatch(getZones({}));
      setIsLoading(false);
    }
  }, [isModalOpen, accountId, pricesPopup.data.id, dispatch]);

  const generateFormValues = (data) => {
    if (!data || data.length === 0) {
      return zonesList.reduce((acc, zoneFrom) => {
        const zoneFromId = zoneFrom.id;
        zonesList.forEach((zoneTo) => {
          const zoneToId = zoneTo.id;
          acc[`${zoneFromId}-${zoneToId}`] = defaultTripPrice;
        });
        return acc;
      }, {});
    }
    return data[0].zonePricing.reduce((acc, curr) => {
      acc[`${curr.from}-${curr.to}`] = curr.price;
      return acc;
    }, {});
  };

  useEffect(() => {
    if (isModalOpen) {
      const pricesData = prepareDataSource(pricesList);
      const dataList = generateFormValues(pricesList);
      form.setFieldsValue(dataList);
      setDataSource(pricesData);
    }
  }, [isModalOpen, zonesList, businessId, pricesList]);

  const generateDefaultPrices = () => {
    return zonesList.flatMap((zoneFrom) =>
      zonesList.map((zoneTo) => ({
        from: zoneFrom.id,
        to: zoneTo.id,
        price: defaultTripPrice,
        currency: 'QAR',
        id: `${zoneFrom.id}-${zoneTo.id}`,
      }))
    );
  };

  const handleOk = (values) => {
    const formattedZonePricing = Object.entries(values).map(([key, price]) => {
      const [from, to] = key.split('-');
      return {
        from,
        to,
        price: parseFloat(price) || 0,
        currency: 'QAR',
      };
    });

    dispatch(
      updatePriceList({
        corporateId: accountId,
        data: { vehicleTypeId: pricesPopup.data.id, zonePricing: formattedZonePricing, businessId },
      })
    ).then(() => {
      dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
      dispatch(setPriceslist([]));
      setBusinessId(null);
      form.resetFields();
    });
  };

  const showSaveConfirmation = (values) => {
    Modal.confirm({
      title: 'Confirm Save',
      content: 'Are you sure you want to save these changes?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => handleOk(values),
    });
  };

  const handleCancel = () => {
    dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
    setBusinessId(null);
    form.resetFields();
    dispatch(setPriceslist([]));
  };

  const renderColumns = () => {
    return zonesList.map((zoneTo) => ({
      title: zoneTo.name,
      dataIndex: zoneTo.id,
      key: zoneTo.id,
      render: (value, record) => {
        const fieldName = `${record.id}-${zoneTo.id}`;
        return (
          <Form.Item
            name={fieldName}
            initialValue={value || defaultTripPrice}
            style={{ marginBottom: 0 }}
          >
            <Input
              addonAfter="QAR"
              variant="borderless"
              style={{
                textAlign: 'center',
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minWidth: '100px',
              }}
            />
          </Form.Item>
        );
      },
    }));
  };

  const prepareDataSource = (data) => {
    const defaultPrices = generateDefaultPrices();
    let pricesData = null;
    if (data.length > 0) {
      pricesData = data[0].zonePricing.reduce((acc, curr) => {
        acc.push({ ...curr, id: `${curr.from}-${curr.to}` });
        return acc;
      }, []);
    } else {
      pricesData = defaultPrices;
    }
    const tableData = zonesList.map((zoneFrom) => ({
      key: zoneFrom.id,
      id: zoneFrom.id,
      name: zoneFrom.name,
      ...Object.fromEntries(
        zonesList.map((zoneTo) => [
          zoneTo.id,
          pricesData.find((price) => price.from === zoneFrom.id && price.to === zoneTo.id)?.price,
        ])
      ),
    }));
    return JSON.parse(JSON.stringify(tableData));
  };

  const items = businessAccounts.map((bAccount) => {
    return {
      key: bAccount.id,
      label: bAccount.name,
      value: bAccount.id,
    };
  });

  const handleMenuItemClick = async (item) => {
    await dispatch(
      getPriceList({
        corporateId: accountId,
        businessID: item.key,
        vehicleTypeId: pricesPopup.data.id,
      })
    );
    setBusinessId(item.key);
  };

  return (
    <Modal
      title={null}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      className="price-modal"
    >
      {isLoading || !zonesList ? (
        <Loader />
      ) : (
        <>
          <div className="w-full flex items-center justify-center gap-x-20 mb-8 ">
            <span className="text-xl font-bold">{`${data?.make?.en} ${data?.model?.en}`}</span>
            <span className="text-xl font-bold">{corporateName}</span>
            <Dropdown
              className="business-dropDown"
              menu={{
                items,
                selectable: true,
                selectedKeys: businessId ? [businessId] : [],
                onClick: handleMenuItemClick,
              }}
              trigger={['click']}
            >
              <Space className="text-lg font-bold">
                {businessId
                  ? items.find((item) => item.key === businessId).label
                  : 'Select Business'}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          <Form
            form={form}
            onFinish={(values) => showSaveConfirmation(values)}
          >
            <Table
              columns={[{ title: 'From/To', dataIndex: 'name', key: 'name', className: 'bg-[#f4f7fc]' }, ...renderColumns()]}
              dataSource={[...dataSource]}
              pagination={false}
              bordered
            />
            <div className="flex justify-end mt-4">
              <Button disabled={!businessId} type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default PricePop;
