import { LoadingOutlined } from '@ant-design/icons';
import {  Spin } from 'antd';

const Loader = () => {
    return (<div className='flex w-full justify-center h-screen items-center '>
           <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />} />

    </div>)
}

export default Loader
