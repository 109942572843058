import React from 'react'
import CustomFilter from '../../../components/customFilter/CustomFilter'

export default function Filters({ query, setQuery }) {

    const statusList = [

        { value: '', label: 'All' },
        { value: 'inReview', label: 'In review' },
        { value: 'rejected', label: 'rejected' },
        { value: 'approved', label: 'approved' },
    ]
    const avalialbleList = [
        { value: '', label: 'All' },
        { value: 'true', label: 'Avaliable' },
        { value: 'false', label: 'Not Avalilable' },

    ]

    return (
        <div className='flex'>
            <CustomFilter
                title='Status'
                name='status'
                list={statusList}
                query={query}
                setQuery={setQuery}
            />
            <CustomFilter
                title='Availability'
                name='availability'
                list={avalialbleList}
                query={query}
                setQuery={setQuery}
            />

        </div>
    )
}
