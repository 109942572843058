import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';

export const getVehicles = createAsyncThunk(
  'vehiclesApp/getVehicles',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle'
      , {
      params: {...query , page: query.page - 1}
      }

    );
    await dispatch(setTotalResults(response.data.totalVehicles));
    await dispatch(setVehicles(response.data.vehicles));
    return response.data.vehicles;
  }
);
export const getTypes = createAsyncThunk(
  'vehiclesApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type' );
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);
export const saveVehicle = createAsyncThunk(
  'vehiclesApp/saveVehicle',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('/vehicle', data
        
      );
      await dispatch(getVehicles( {
        page: 1,
        limit: 10,
        orderBy: 'createdAt',
        orderDirection: 'desc'
      }));
      return response.data;
      
    } catch (error) {
      console.log(error)
      return {error};
      
    }
  }
);
export const saveVehicleType = createAsyncThunk(
  'vehiclesApp/saveVehicleType',
  async (data, { dispatch }) => {
    const response = await axios.post('/vehicle/type', data
 
    );
    await dispatch(getTypes());
    return response.data;
  }
);
export const updateVehicle = createAsyncThunk(
  'vehiclesApp/updateVehicle',
  async ({vehicleId, data , query = {
    page: 1,
    limit: 10,
    orderBy: 'createdAt',
    orderDirection: 'desc'
  }}, { dispatch }) => {
    const response = await axios.put(`/vehicle/${vehicleId}`, data
    );
    await dispatch(getVehicles(query));
    return response.data;
  }
);
export const deleteVehicle = createAsyncThunk(
  'vehiclesApp/deleteVehicle',
  async ({vehicleId}, { dispatch }) => {
    const response = await axios.delete(`/vehicle/${vehicleId}`);
    await dispatch(getVehicles({
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'desc'
    }));
    return response.data;
  }
);
export const approveDriver = createAsyncThunk(
  'vehiclesApp/approveDriver',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/approveDriver', data
      //, {
      // params: {
      //   page: page,
      //   limit,
      //   userId,
      // },
      // }
    );
    await dispatch(getVehicles());
    return response.data;
  }
);
export const getCities = createAsyncThunk(
  'vehiclesApp/getCities',
  async (data, { dispatch }) => {
    const response = await axios.get('/public/cities/'
      //, {
      // params: {
      //   page: page,
      //   limit,
      //   userId,
      // },
      // }

    );
    await dispatch(setCities(response.data));
    return response.data;
  }
);


const vehicleSlice = createSlice({
  name: 'vehiclesApp',
  initialState: {
    totalResults: 0,
    vehicle: null,
    data: [],
    types : [], 
    vehicles: [],
    cities: [],
    query: {
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'desc'
    }
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setVehicle: {
      reducer: (state, action) => {

        state.vehicle = action.payload ? action.payload : 0;
      },
      prepare: vehicle => ({ payload: vehicle || null }),
    },
    setTypes: {
      reducer: (state, action) => {

        state.types = action.payload ? action.payload : 0;
      },
      prepare: types => ({ payload: types || [] }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'desc'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
    setCities: {
      reducer: (state, action) => {

        state.cities = action.payload ? action.payload : 0;
      },
      prepare: cities => ({ payload: cities || [] }),
    },
    setVehicles: {
      reducer: (state, action) => {
        state.vehicles = action.payload ? action.payload : 0;
      },
      prepare: vehicles => ({ payload: vehicles || [] }),
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setTotalResults,
  setVehicles,
  setCities,
  setQuery,
  setVehicle,
  setTypes,
} = vehicleSlice.actions;

export default vehicleSlice.reducer;

