import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getDrivers = createAsyncThunk(
  'driverApp/getDrivers',
  async (query, { dispatch }) => {
    const response = await axios.get('/admin/drivers'
      , {
      params: {...query, page: query.page - 1},
      }

    );
    await dispatch(setTotalResults(response.data.totalDrivers));
    await dispatch(setDrivers(response.data.drivers));
    return response.data.drivers;
  }
);
export const saveDriver = createAsyncThunk(
  'driverApp/saveDriver',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/createDriver', data
    );
    await dispatch(getDrivers(
      {
        page: 1,
        limit: 10,
        orderBy: 'createdAt',
        orderDirection: 'descend'
      }
    ));
    return response.data;
  }
);
export const updateDriver = createAsyncThunk(
  'driverApp/updateDriver',
  async ({data, query }, { dispatch }) => {
    try {
      const response = await axios.put(`/admin/updateDriver/${data.id}`, data
      );  
      await dispatch(getDrivers(query));      
      return response.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  }

);
export const approveDriver = createAsyncThunk(
  'driverApp/approveDriver',
  async ({query,driverId }, { dispatch }) => {
    const response = await axios.post('/admin/approveDriver', {driverId}
    );
    await dispatch(getDrivers(query));
    return response.data;
  }
);
export const getCities = createAsyncThunk(
  'driverApp/getCities',
  async (data, { dispatch }) => {
    const response = await axios.get('/public/cities/'

    );
    await dispatch(setCities(response.data));
    return response.data;
  }
);

const driverSlice = createSlice({
  name: 'driverApp',
  initialState: {
    totalResults: 0,
    driver: null,
    data: [],
    drivers: [],
    cities: [],
    query: {
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend'
    }
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setDriver: {
      reducer: (state, action) => {

        state.driver = action.payload ? action.payload : 0;
      },
      prepare: driver => ({ payload: driver || null }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
    setCities: {
      reducer: (state, action) => {

        state.cities = action.payload ? action.payload : 0;
      },
      prepare: cities => ({ payload: cities || [] }),
    },
    setDrivers: {
      reducer: (state, action) => {
        state.drivers = action.payload ? action.payload : 0;
      },
      prepare: drivers => ({ payload: drivers || '' }),
    },
  },


});

export const {
  setTotalResults,
  setDrivers,
  setCities,
  setQuery,
  setDriver,
} = driverSlice.actions;

export default driverSlice.reducer;

