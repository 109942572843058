import { debounce } from "../utils/functions";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useCallback, useImperativeHandle, useState } from "react";
import { useLocation } from "react-router";
import "./style.css";



const SearchComponent = ({
  search = "users",
  setTextRef,
  onTextChange,
  placeHolder,
  query,
  className,
}) => {
  const location = useLocation();
  const [searchText, setText] = useState(location.state?.searchText);


 
  useImperativeHandle(
    setTextRef,
    () => {
      return {
        setText,
      };
    },
    [],
  );
  const handleDelayedSearch = useCallback(
    debounce(async (value) => {
      onTextChange?.(value);
    //   else {
    //     dispatch(
    //       listingInputSearchActions.getListingInputSearchSlice({
    //         key: search,
    //         value,
    //       }),
    //     );
    //   }
    }, 400),
    [query],
  );

  return (
    <div className={`search-component-container w-full ${className}`}>
      <Input
        placeholder={placeHolder || 'Search'}
        allowClear
        variant="borderless"
        className="search-container flex-row-reverse "
        suffix={<SearchOutlined className="  text-primary-dark mr-4" />}
        value={searchText}
        onChange={(e) => {
          setText(e.target.value);
          handleDelayedSearch(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchComponent;
