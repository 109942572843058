import { Popconfirm, Switch } from "antd";
import { useEffect, useState } from "react";
import "./style.css";
const CustomPopconfirm = ({
  placement = "topRight",
  autoState = false,
  title = undefined,
  onConfirm = undefined,
  onCancel = undefined,
  open = false,
  overlayClassName = undefined,
  okText = "Yes",
  cancelText = "No",
  children = undefined,
  isChecked = false,
}) => {
  // const [isActive, setActive] = useState(false);
  const [showPopAlert, setShowPopAlert] = useState(false);

  const handleConfirm = () => {
    togglePopAlert();
    onConfirm?.();
    // setActive(true);
  };

  useEffect(() => {
    setShowPopAlert(open);
  }, [open]);

  const togglePopAlert = () => {
    setShowPopAlert(!showPopAlert);
  };

  const handleCancel = () => {
    onCancel?.();
    togglePopAlert();
  };
  okText ='Yes';
  cancelText = 'No';

  return (
    <Popconfirm
      placement={"topRight"}
      title={title}
      onConfirm={handleConfirm}
      overlayClassName={`custom-popupconfirm ${overlayClassName}`}
      onCancel={handleCancel}
      open={autoState == true ? undefined : showPopAlert}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ className: "custom-primary" }}
      cancelButtonProps={{ className: "custom-primary" }}
    >
      {children ? (
        children
      ) : (
        <Switch checked={isChecked} onChange={() => setShowPopAlert(true)} />
      )}
    </Popconfirm>
  );
};

export default CustomPopconfirm;
